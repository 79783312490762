// utils/renderNotes.tsx

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { Note, BookDetail, AuthorDetail } from '../types/types'; // Note 型をインポート
import OGPCard from '../utils/OGPCard';
import tenkyoIcon from '../assets/images/tenkyo_icon.png';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import { Button } from '@mui/material';

// // 既存の TenkyoDetails 型（Note 型で使用されているもの）
// interface TenkyoDetails {
//   TENKYO_ID: string;
//   Wikipedia_Link?: string;
//   // 他の必要なプロパティがあればここに追加
// }

// 新しい API レスポンス用の TenkyoInfo 型
interface TenkyoInfo {
  dynamodb_item: {
    LastUpdated: string;
    TENKYO_ID: string;
    Wikipedia_Link?: string;
  };
  ps_response: {
    TENKYO_ID: string;
    F_A: string;
    F_D: string;
  };
}

interface BookSimple {
  KANRI_ID: string;
  MainTitle: string;
  ContentDetailTitle: string;
  TitleOthers: string;
  ISBN?: string;
  Responsibility: string;
  PublishingDate: string;
}

// const fetchBookDetails = async (kanriId: string): Promise<BookSimple> => {
//   const response = await axios.get(`https://zejgdrpbj1.execute-api.us-east-1.amazonaws.com/v1/readlinker-book-handler-speed?kanri_id=${kanriId}&OperationType=fetch_PS_Book_Simple`);
//   return response.data;
// };

const TenkyoDetailsCard: React.FC<{ tenkyoInfo: TenkyoInfo }> = ({ tenkyoInfo }) => {
  const { dynamodb_item, ps_response } = tenkyoInfo;

  return (
    <Card sx={{ display: 'flex', mb: 2 }}>
      <CardMedia
        component="img"
        sx={{ width: 100, height: 100, objectFit: 'contain', ml: 1 }}
        image={tenkyoIcon}
        alt="著者アイコン"
      />
      <CardContent sx={{ flex: 1 }}>
      <Button
            href={`/author/${dynamodb_item.TENKYO_ID}`}
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              p: 0,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={(e) => e.stopPropagation()} 
          >

          <Typography component="div" variant="h6">
            {ps_response.F_A ? ps_response.F_A.replace(/,/g, '') : '不明'}
          </Typography>
        </Button>
        <Typography variant="body2" color="textSecondary" component="div">
          {ps_response.F_D}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div">
          <Link href={`https://id.ndl.go.jp/auth/ndlna/${dynamodb_item.TENKYO_ID}`} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()} >
            国立国会図書館典拠データベース
          </Link>
        </Typography>
        {dynamodb_item.Wikipedia_Link && (
          <Typography variant="body2" color="textSecondary" component="div">
            <Link href={dynamodb_item.Wikipedia_Link} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()} >
              Wikipedia
            </Link>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const TenkyoDetailsFetcher: React.FC<{ tenkyo_id: string }> = ({ tenkyo_id }) => {
  const [tenkyoInfo, setTenkyoInfo] = React.useState<TenkyoInfo | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  console.log('TenkyoDetailsFetcherが呼ばれた');

  React.useEffect(() => {
    const fetchTenkyoInfo = async () => {
      try {
        const response = await fetch(`https://d2c46f93hx1b7q.cloudfront.net/v1/${tenkyo_id}?quick_info=true`);
        if (!response.ok) {
          throw new Error('Failed to fetch tenkyo info');
        }
        const data = await response.json();
        setTenkyoInfo(data);
      } catch (err) {
        setError('典拠情報の取得に失敗しました');
        console.error('Error fetching tenkyo info:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchTenkyoInfo();
  }, [tenkyo_id]);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!tenkyoInfo) return null;

  return <TenkyoDetailsCard tenkyoInfo={tenkyoInfo} />;
};

// ReadLinker の書誌詳細ページカードのレンダリング
const ManifestationCard: React.FC<{ kanriId: string }> = ({ kanriId }) => {
  const [bookDetail, setBookDetail] = useState<BookDetail | null>(null);

  useEffect(() => {
    const fetchBookDetail = async () => {
      try {
        const response = await axios.post('https://53wglutnub.execute-api.us-east-1.amazonaws.com/default/v1', {
          Operation: 'Fetch_Simplebook_by_kanri_id',
          KANRI_ID: kanriId,
        });
        setBookDetail(response.data[0]);
      } catch (error) {
        console.error('Error fetching book detail:', error);
      }
    };

    fetchBookDetail();
  }, [kanriId]);

  if (!bookDetail) return null;

  return (
    <Card sx={{ display: 'flex', mb: 2 }}>
      <CardMedia
        component="img"
        sx={{ width: 150 }}
        image={generateCoverUrl(bookDetail)}
        alt={bookDetail.MainTitle}
      />
      <CardContent sx={{ flex: 1 }}>
        <Typography component="div" variant="h6">
          {bookDetail.MainTitle}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" component="div">
          {bookDetail.Responsibility}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div">
          {bookDetail.PublishingDate}
        </Typography>
        <Typography variant="body2" color="primary" component="div">
          <Link href={`https://readlinker.com/manifestation/${kanriId}`} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()} >
            書誌詳細ページへ
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
};

// ReadLinker の著者詳細ページカードのレンダリング
const AuthorCard: React.FC<{ tenkyoId: string }> = ({ tenkyoId }) => {
  const [authorDetail, setAuthorDetail] = useState<AuthorDetail | null>(null);

  useEffect(() => {
    const fetchAuthorDetail = async () => {
      try {
        const response = await axios.get('https://d3tige1x5pvkax.cloudfront.net/default/search_ndl_authors', {
          params: {
            query: tenkyoId,
            size: 1
          }
        });
        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          setAuthorDetail(response.data[0]);
        }
      } catch (error) {
        console.error('Error fetching author detail:', error);
      }
    };

    fetchAuthorDetail();
  }, [tenkyoId]);

  if (!authorDetail) return null;

  return (
    <Card sx={{ display: 'flex', mb: 2 }}>
      <CardMedia
        component="img"
        sx={{ width: 50, objectFit: 'contain', ml: 1 }}
        image={tenkyoIcon}
        alt={authorDetail.main_name}
      />
      <CardContent sx={{ flex: 1 }}>
        <Typography component="div" variant="h6">
          {authorDetail.main_name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div">
          {authorDetail.birth_death}
        </Typography>
        <Typography variant="body2" color="primary" component="div">
          <Link href={`https://readlinker.com/author/${tenkyoId}`} target="_blank" rel="noopener noreferrer">
            著者詳細ページへ
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
};

// YouTubeとTwitterの埋め込みを処理する関数
const renderContentWithEmbeds = (content: string) => {
  const patterns = [
    { regex: /https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})|https?:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})/g, type: 'youtube' },
    { regex: /https?:\/\/x\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/g, type: 'twitter' },
    { regex: /https?:\/\/readlinker\.com\/manifestation\/([a-zA-Z0-9_-]+)/g, type: 'manifestation' },
    { regex: /https?:\/\/readlinker\.com\/author\/([a-zA-Z0-9_-]+)/g, type: 'author' },
    { regex: /https?:\/\/[^\s]+/g, type: 'url' }
  ];

  return content.split('\n').map((line, lineIndex) => {
    let elements: React.ReactNode[] = [];
    let lastIndex = 0;

    const processMatch = (match: RegExpExecArray, pattern: { type: string }) => {
      const [fullMatch, ...groups] = match;
      const beforeMatch = line.slice(lastIndex, match.index);
      lastIndex = match.index + fullMatch.length;

      if (beforeMatch) {
        elements.push(<React.Fragment key={`${lineIndex}-${lastIndex}-text`}>{beforeMatch}</React.Fragment>);
      }

      switch (pattern.type) {
        case 'youtube':
          const videoId = groups[0] || groups[1];
          elements.push(
            <Box key={`${lineIndex}-${lastIndex}-youtube`} sx={{ my: 2 }}>
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          );
          break;
        case 'twitter':
          const tweetUrl = `https://twitter.com/${groups[0]}/status/${groups[2]}`;
          elements.push(
            <Box key={`${lineIndex}-${lastIndex}-twitter`} sx={{ my: 2 }}>
              <blockquote className="twitter-tweet">
                <a href={tweetUrl}></a>
              </blockquote>
            </Box>
          );
          break;
        case 'manifestation':
          const kanriId = groups[0];
          elements.push(
            <Box key={`${lineIndex}-${lastIndex}-manifestation`} sx={{ my: 2 }}>
              <ManifestationCard kanriId={kanriId} />
            </Box>
          );
          break;
        case 'author':
          const tenkyoId = groups[0];
          elements.push(
            <Box key={`${lineIndex}-${lastIndex}-author`} sx={{ my: 2 }}>
              <AuthorCard tenkyoId={tenkyoId} />
            </Box>
          );
          break;
        case 'url':
          if (!/youtube\.com|youtu\.be|x\.com|readlinker\.com/.test(fullMatch)) {
            elements.push(<OGPCard key={`${lineIndex}-${lastIndex}-url`} url={fullMatch} />);
          } else {
            elements.push(<Link key={`${lineIndex}-${lastIndex}-link`} href={fullMatch} target="_blank" rel="noopener noreferrer">{fullMatch}</Link>);
          }
          break;
      }
    };

    patterns.forEach(pattern => {
      let match;
      while ((match = pattern.regex.exec(line)) !== null) {
        processMatch(match, pattern);
      }
    });

    if (lastIndex < line.length) {
      elements.push(<React.Fragment key={`${lineIndex}-end`}>{line.slice(lastIndex)}</React.Fragment>);
    }

    return <React.Fragment key={`line-${lineIndex}`}>{elements}<br /></React.Fragment>;
  });
};

// 感想ノートと説明ノートをレンダリングする関数
const renderImpressionExplainNote = (note: Note) => {
  const borderColor = note.noteType === 'ImpressionNote' ? '#FFD600' : '#00796B';

  return (
    <Box key={note.noteId} sx={{ mb: 2, border: `2px solid ${borderColor}`, borderRadius: '8px', p: 2 }}>
      <Typography variant="body1" gutterBottom>
        {note.noteType === 'ImpressionNote' ? '感想ノート' : '説明ノート'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {note.createdAt}
      </Typography>
      <Typography variant="body2" component="div" sx={{ mt: 1 }}>
        {renderContentWithEmbeds(note.content)}
      </Typography>
    </Box>
  );
};

const getReferenceTypeText = (referenceType: string): string => {
  switch (referenceType) {
    case 'positive':
      return '肯定的に引用';
    case 'neutral':
      return '中立的に引用';
    case 'criticism':
      return '批判的に引用';
    case 'noselection':
    default:
      return '引用';
  }
};

// 情報ノートをレンダリングする関数
const renderInformationNote = (note: Note) => {
  const arrowColor = note.referenceType === 'positive' ? '#4FC3F7' : note.referenceType === 'neutral' ? '#66BB6A' : '#E64A19';

  return (
    <Box key={note.noteId} sx={{ mb: 2, border: '2px solid #1976D2', borderRadius: '8px', p: 2, display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flex: 1 }}>
        {note.referenceTargetKANRI_ID && <ManifestationCard kanriId={note.referenceTargetKANRI_ID} />}
        {note.referenceTargetTENKYO_ID && <TenkyoDetailsFetcher tenkyo_id={note.referenceTargetTENKYO_ID} />}
      </Box>
      <Box sx={{ mx: 2, fontSize: '48px', color: arrowColor }}>←</Box>
      <Box sx={{ flex: 1 }}>
        {note.referenceSourceKANRI_ID && <ManifestationCard kanriId={note.referenceSourceKANRI_ID} />}
      </Box>
    </Box>
  );
};

// ノートのコンテンツをレンダリングする関数
export const renderNoteContent = (note: Note) => {
  if (note.noteType === 'InformationNote') {
    return renderInformationNote(note);
  } else {
    return renderImpressionExplainNote(note);
  }
};

// HomePage.tsxでの使用を考慮してTwitterスクリプトのロードを追加
export const loadTwitterScript = () => {
  if (!window.twttr) {
    const script = document.createElement('script');
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.charset = "utf-8";
    document.head.appendChild(script);
  } else {
    window.twttr.widgets && window.twttr.widgets.load();
  }
};

export default renderNoteContent;
