// pages/SearchResultPage.tsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import { BookDetail, AuthorDetail, Note, UserDetail } from '../types/types';
import { parsePublishingDate } from '../utils/parsePublishingDate';
import { useAuth } from '../context/AuthContext';
import { renderNoteContent } from '../components/renderNotes';

const SearchResultPage: React.FC = () => {
  const { query } = useParams<{ query: string }>();
  const [searchResults, setSearchResults] = useState<BookDetail[]>([]);
  const [authorResults, setAuthorResults] = useState<AuthorDetail[]>([]);
  const [explainNoteResults, setExplainNoteResults] = useState<Note[]>([]);
  const [impressionNoteResults, setImpressionNoteResults] = useState<Note[]>([]);
  const [userResults, setUserResults] = useState<UserDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<'newest' | 'score'>('score'); // デフォルトを 'score' に設定
  const navigate = useNavigate();
  const { isLoggedIn, idToken } = useAuth();

  useEffect(() => {
    const fetchSearchResults = async () => {
      setLoading(true);
      try {
        if (tabIndex === 0) {
          // 「本」の検索
          const response = await axios.post('https://3p6ygsdgae.execute-api.us-east-1.amazonaws.com/flutter', {
            OperationType: 'search_CS_readlinker_word_kanri_id_with_score',
            search_word: query,
            size: '400',
          });

          const responseData = response.data;
          let searchResultsArray: BookDetail[] = [];

          if (Array.isArray(responseData)) {
            searchResultsArray = responseData;
          } else if (typeof responseData === 'object') {
            searchResultsArray = Object.values(responseData).map(item => {
              if (typeof item === 'string') {
                return JSON.parse(item) as BookDetail;
              }
              return item as BookDetail;
            });
          }

          // 並び替え
          searchResultsArray.sort((a, b) => {
            if (sortOrder === 'newest') {
              const dateA = parsePublishingDate(a.PublishingDate);
              const dateB = parsePublishingDate(b.PublishingDate);
              return (dateB?.getTime() ?? 0) - (dateA?.getTime() ?? 0);
            }
            return parseFloat(b.search_score) - parseFloat(a.search_score);
          });

          setSearchResults(searchResultsArray);

        } else if (tabIndex === 1) {
          // 「著者」の検索
          const response = await axios.get('https://d3tige1x5pvkax.cloudfront.net/default/search_ndl_authors', {
            params: {
              query,
              size: 100
            }
          });

          const responseData = response.data;
          let authorResultsArray: AuthorDetail[] = [];

          if (Array.isArray(responseData)) {
            authorResultsArray = responseData;
          } else if (typeof responseData === 'object') {
            authorResultsArray = Object.values(responseData).map(item => {
              if (typeof item === 'string') {
                return JSON.parse(item) as AuthorDetail;
              }
              return item as AuthorDetail;
            });
          }

          // _score で降順にソート
          authorResultsArray.sort((a, b) => b._score - a._score);

          setAuthorResults(authorResultsArray);

        } else if (tabIndex === 2) {
          // 「説明ノート」の検索
          const response = await axios.get('https://uy6802cwki.execute-api.us-east-1.amazonaws.com/default/searchExplainNote', {
            params: {
              query,
            },
            headers: {
              Authorization: `Bearer ${idToken}`,
            }
          });

          const responseData = response.data;
          let explainNoteResultsArray: Note[] = [];

          if (Array.isArray(responseData)) {
            explainNoteResultsArray = responseData;
          } else if (typeof responseData === 'object') {
            explainNoteResultsArray = Object.values(responseData).map(item => {
              if (typeof item === 'string') {
                return JSON.parse(item) as Note;
              }
              return item as Note;
            });
          }

          // ソート
          explainNoteResultsArray.sort((a, b) => {
            const dateA = new Date(a.editedAt || a.createdAt);
            const dateB = new Date(b.editedAt || b.createdAt);

            if (sortOrder === 'newest') {
              return dateB.getTime() - dateA.getTime();
            }
            return (b._score ?? 0) - (a._score ?? 0);
          });

          setExplainNoteResults(explainNoteResultsArray);

        } else if (tabIndex === 3) {
          // 「感想ノート」の検索
          const response = await axios.get('https://643t3ewhee.execute-api.us-east-1.amazonaws.com/default/searchImpressionNote', {
            params: {
              query,
            },
            headers: {
              Authorization: `Bearer ${idToken}`,
            }
          });

          const responseData = response.data;
          let impressionNoteResultsArray: Note[] = [];

          if (Array.isArray(responseData)) {
            impressionNoteResultsArray = responseData;
          } else if (typeof responseData === 'object') {
            impressionNoteResultsArray = Object.values(responseData).map(item => {
              if (typeof item === 'string') {
                return JSON.parse(item) as Note;
              }
              return item as Note;
            });
          }

          // ソート
          impressionNoteResultsArray.sort((a, b) => {
            const dateA = new Date(a.editedAt || a.createdAt);
            const dateB = new Date(b.editedAt || b.createdAt);

            if (sortOrder === 'newest') {
              return dateB.getTime() - dateA.getTime();
            }
            return (b._score ?? 0) - (a._score ?? 0);
          });

          setImpressionNoteResults(impressionNoteResultsArray);

        } else if (tabIndex === 4) {
          // 「アカウント」の検索
          if (!isLoggedIn || !idToken) {
            setUserResults([]); // ログインしていない場合は結果をクリア
            return;
          }

          const response = await axios.get('https://apxwslswr0.execute-api.us-east-1.amazonaws.com/default/searchUsers', {
            params: {
              query,
            },
            headers: {
              Authorization: `Bearer ${idToken}`,
            }
          });

          const responseData = response.data;
          let userResultsArray: UserDetail[] = [];

          if (Array.isArray(responseData)) {
            userResultsArray = responseData;
          } else if (typeof responseData === 'object') {
            userResultsArray = Object.values(responseData).map(item => {
              if (typeof item === 'string') {
                return JSON.parse(item) as UserDetail;
              }
              return item as UserDetail;
            });
          }

          // _score で降順にソート
          userResultsArray.sort((a, b) => b._score - a._score);

          setUserResults(userResultsArray);
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [query, tabIndex, sortOrder, idToken, isLoggedIn]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSortChange = (event: SelectChangeEvent<'newest' | 'score'>) => {
    setSortOrder(event.target.value as 'newest' | 'score');
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="本" />
        <Tab label="著者" />
        <Tab label="説明ノート" />
        <Tab label="感想ノート" />
        <Tab label="アカウント" />
      </Tabs>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ ml: 2 }}>
            検索中...
          </Typography>
        </Box>
      ) : tabIndex === 0 ? (
        searchResults.length === 0 ? (
          <Typography variant="h6" align="center">
            検索結果が0件です。
          </Typography>
        ) : (
          <>
            <Select
              value={sortOrder}
              onChange={handleSortChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ mb: 2 }}
            >
              <MenuItem value="score">ヒット順</MenuItem>
              <MenuItem value="newest">新しい順</MenuItem>
            </Select>
            <Grid container spacing={2}>
              {searchResults.map((result) => (
                <Grid item xs={12} sm={6} md={4} key={result.KANRI_ID}>
                  <Card
                    sx={{
                      boxShadow: 1,
                      transition: 'box-shadow 0.3s',
                      '&:hover': {
                        boxShadow: 6,
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={generateCoverUrl(result)}
                      alt={`Cover for ${result.MainTitle}`}
                      sx={{ objectFit: 'contain', height: '200px', width: '100%' }}
                      onClick={() => {
                        navigate(`/manifestation/${result.KANRI_ID}`);
                        window.scrollTo(0, 0);
                      }}
                    />
                    <Box sx={{ mt: 1, textAlign: 'center' }}>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {result.MainTitle}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {result.Responsibility}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {result.PublishingDate}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )
      ) : tabIndex === 1 ? (
        authorResults.length === 0 ? (
          <Typography variant="h6" align="center">
            検索結果が0件です。
          </Typography>
        ) : (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" align="left" gutterBottom>
              著者の検索結果
            </Typography>
            {authorResults.map((author) => (
              <Box
                key={author.tenkyo_id}
                sx={{ 
                  p: 2, 
                  borderBottom: '1px solid #ddd', 
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#f5f5f5' } 
                }}
                onClick={() => navigate(`/author/${author.tenkyo_id}`)}
              >
                <Typography variant="body1" component="div">
                  {author.main_name} {author.birth_death}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  別名: {author.alternative_names.slice(0, 3).join(' ')}
                </Typography>
              </Box>
            ))}
          </Box>
        )
      ) : tabIndex === 2 ? (
        explainNoteResults.length === 0 ? (
          <Typography variant="h6" align="center">
            検索結果が0件です。
          </Typography>
        ) : (
          <>
            <Select
              value={sortOrder}
              onChange={handleSortChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ mb: 2 }}
            >
              <MenuItem value="newest">新しい順</MenuItem>
              <MenuItem value="score">ヒット順</MenuItem>
            </Select>
            <Grid container spacing={2}>
              {explainNoteResults.map((note) => (
                <Grid item xs={12} key={note.noteId}>
                  <Card
                    sx={{
                      boxShadow: 1,
                      transition: 'box-shadow 0.3s',
                      '&:hover': {
                        boxShadow: 6,
                      },
                    }}
                    onClick={() => navigate(`/note/${note.noteId}`)}
                  >
                    <Box sx={{ p: 2 }}>
                      {renderNoteContent(note)}
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )
      ) : tabIndex === 3 ? (
        impressionNoteResults.length === 0 ? (
          <Typography variant="h6" align="center">
            検索結果が0件です。
          </Typography>
        ) : (
          <>
            <Select
              value={sortOrder}
              onChange={handleSortChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ mb: 2 }}
            >
              <MenuItem value="newest">新しい順</MenuItem>
              <MenuItem value="score">ヒット順</MenuItem>
            </Select>
            <Grid container spacing={2}>
              {impressionNoteResults.map((note) => (
                <Grid item xs={12} key={note.noteId}>
                  <Card
                    sx={{
                      boxShadow: 1,
                      transition: 'box-shadow 0.3s',
                      '&:hover': {
                        boxShadow: 6,
                      },
                    }}
                    onClick={() => navigate(`/note/${note.noteId}`)}
                  >
                    <Box sx={{ p: 2 }}>
                      {renderNoteContent(note)}
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )
      ) : tabIndex === 4 ? (
        !isLoggedIn ? (
          <Typography variant="h6" align="center">
            ユーザー検索にはログインが必要です。
          </Typography>
        ) : userResults.length === 0 ? (
          <Typography variant="h6" align="center">
            検索結果が0件です。
          </Typography>
        ) : (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" align="left" gutterBottom>
              ユーザーの検索結果
            </Typography>
            {userResults.map((user) => (
              <Box
                key={user.userSub}
                sx={{ 
                  p: 2, 
                  borderBottom: '1px solid #ddd', 
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#f5f5f5' } 
                }}
                onClick={() => navigate(`/${user.username}`)} // ユーザーのページに移動
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Avatar src={user.profileImage} sx={{ width: 48, height: 48, mr: 2 }} />
                  <Box>
                    <Typography variant="h6" component="div">
                      {user.preferred_name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      @{user.username}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body2" color="textPrimary">
                  {user.profileText}
                </Typography>
              </Box>
            ))}
          </Box>
        )
      ) : (
        <Typography variant="h6" align="center">
          このタブの検索機能はまだ実装されていません。
        </Typography>
      )}
    </Container>
  );
};

export default SearchResultPage;
