// // src/pages/FriendRequestsPage.tsx

// import React, { useState, useEffect } from 'react';
// import { getIdToken } from '../utils/getIdToken';
// import { getUserSub } from '../utils/getUserSub';
// import { getReceivedFriendRequests, handleFriendRequest } from '../utils/userFriendsHandle';
// import Container from '@mui/material/Container';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
// import Paper from '@mui/material/Paper';

// import { getUserInfoByUsersubCF } from '../utils/userProfileApi'; //新規追加

// interface FriendRequest {
//   requestId: string;
//   senderUserSub: string;
//   receiverUserSub: string;
//   createdAt: string;
// }

// const FriendRequestsPage: React.FC = () => {
//   const [loading, setLoading] = useState<boolean>(true);
//   const [requests, setRequests] = useState<FriendRequest[]>([]);
//   const [userSub, setUserSub] = useState<string | null>(null);
//   const idToken = getIdToken();

//   useEffect(() => {
//     const fetchRequests = async () => {
//       setLoading(true);
//       try {
//         const sub = await getUserSub();
//         setUserSub(sub);
        
//         if (sub && idToken) {
//           const response = await getReceivedFriendRequests(sub, idToken);
//           setRequests(response || []);
//         }
//       } catch (error) {
//         console.error('Error fetching friend requests:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchRequests();
//   }, [idToken]);

//   const handleAcceptRequest = async (requestId: string) => {
//     try {
//       await handleFriendRequest(requestId, 'accept', idToken!);
//       setRequests(prevRequests => prevRequests.filter(request => request.requestId !== requestId));
//     } catch (error) {
//       console.error('Error accepting friend request:', error);
//     }
//   };

//   const handleRejectRequest = async (requestId: string) => {
//     try {
//       await handleFriendRequest(requestId, 'reject', idToken!);
//       setRequests(prevRequests => prevRequests.filter(request => request.requestId !== requestId));
//     } catch (error) {
//       console.error('Error rejecting friend request:', error);
//     }
//   };

//   return (
//     <Container>
//       <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
//         友達リクエスト
//       </Typography>
//       {loading ? (
//         <CircularProgress />
//       ) : requests.length === 0 ? (
//         <Typography variant="body1" color="textSecondary">
//           友達リクエストはありません。
//         </Typography>
//       ) : (
//         requests.map(request => (
//           <Paper key={request.requestId} elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
//             <Typography variant="body1" component="div">
//               送信者: {request.senderUserSub}
//             </Typography>
//             <Typography variant="body2" color="textSecondary">
//               リクエスト日時: {new Date(request.createdAt).toLocaleString()}
//             </Typography>
//             <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
//               <Button
//                 variant="contained"
//                 color="success"
//                 onClick={() => handleAcceptRequest(request.requestId)}
//               >
//                 リクエストを承認
//               </Button>
//               <Button
//                 variant="contained"
//                 color="error"
//                 onClick={() => handleRejectRequest(request.requestId)}
//               >
//                 リクエストを拒否
//               </Button>
//             </Box>
//           </Paper>
//         ))
//       )}
//     </Container>
//   );
// };

// export default FriendRequestsPage;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIdToken } from '../utils/getIdToken';
import { getUserSub } from '../utils/getUserSub';
import { getReceivedFriendRequests, handleFriendRequest } from '../utils/userFriendsHandle';
import { getUserInfoByUsersubCF } from '../utils/userProfileApi';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';

interface FriendRequest {
  requestId: string;
  senderUserSub: string;
  receiverUserSub: string;
  createdAt: string;
}

interface UserData {
  username: string;
  preferred_name: string;
  profileImage: string;
}

interface EnhancedFriendRequest extends FriendRequest {
  senderInfo: UserData | null;
}

const FriendRequestsPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [requests, setRequests] = useState<EnhancedFriendRequest[]>([]);
  const [userSub, setUserSub] = useState<string | null>(null);
  const idToken = getIdToken();
  const navigate = useNavigate();

  useEffect(() => {
    // const fetchRequests = async () => {
    //   setLoading(true);
    //   try {
    //     const sub = await getUserSub();
    //     setUserSub(sub);
        
    //     if (sub && idToken) {
    //       const response = await getReceivedFriendRequests(sub, idToken);
    //       const enhancedRequests = await Promise.all(
    //         (response || []).map(async (request) => {
    //           const senderInfo = await getUserInfoByUsersubCF(request.senderUserSub);
    //           return { ...request, senderInfo };
    //         })
    //       );
    //       setRequests(enhancedRequests);
    //     }
    //   } catch (error) {
    //     console.error('Error fetching friend requests:', error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    const fetchRequests = async () => {
      setLoading(true);
      try {
        const sub = await getUserSub();
        setUserSub(sub);
        
        if (sub && idToken) {
          const response = await getReceivedFriendRequests(sub, idToken);
          const enhancedRequests = await Promise.all(
            (response || []).map(async (request) => {
              const senderInfo = await getUserInfoByUsersubCF(request.senderUserSub);
              return { ...request, senderInfo } as EnhancedFriendRequest;
            })
          );
          setRequests(enhancedRequests);
        }
      } catch (error) {
        console.error('Error fetching friend requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [idToken]);

  const handleAcceptRequest = async (requestId: string) => {
    try {
      await handleFriendRequest(requestId, 'accept', idToken!);
      setRequests(prevRequests => prevRequests.filter(request => request.requestId !== requestId));
    } catch (error) {
      console.error('Error accepting friend request:', error);
    }
  };

  const handleRejectRequest = async (requestId: string) => {
    try {
      await handleFriendRequest(requestId, 'reject', idToken!);
      setRequests(prevRequests => prevRequests.filter(request => request.requestId !== requestId));
    } catch (error) {
      console.error('Error rejecting friend request:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
        友達リクエスト
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : requests.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          友達リクエストはありません。
        </Typography>
      ) : (
        requests.map(request => (
          <Paper key={request.requestId} elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
            {request.senderInfo && (
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar
                  src={request.senderInfo.profileImage}
                  alt={request.senderInfo.username}
                  sx={{ width: 56, height: 56, cursor: 'pointer' }}
                  onClick={() => navigate(`/${request.senderInfo!.username}`)}
                />
                <Box ml={2}>
                  <Typography variant="h6" component="span" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                    {request.senderInfo.preferred_name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Link
                      component="button"
                      variant="body2"
                      color="textSecondary"
                      onClick={() => navigate(`/${request.senderInfo!.username}`)}
                    >
                      @{request.senderInfo.username}
                    </Link>
                  </Typography>
                </Box>
              </Box>
            )}
            <Typography variant="body2" color="textSecondary">
              リクエスト日時: {new Date(request.createdAt).toLocaleString()}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
              <Button
                variant="contained"
                color="success"
                onClick={() => handleAcceptRequest(request.requestId)}
              >
                リクエストを承認
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleRejectRequest(request.requestId)}
              >
                リクエストを拒否
              </Button>
            </Box>
          </Paper>
        ))
      )}
    </Container>
  );
};

export default FriendRequestsPage;