// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes,useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import BookDetailPage from './pages/BookDetailPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ServiceContactPage from './pages/ServiceContactPage';
import BookshelfPage from './pages/UserBookShelfPage';
import LogoutPage from './pages/LogoutPage';
import AboutStickerPage from './pages/AboutStickerPage';
import ManifestationDetailPage from './pages/ManifestationDetailPage';
import SearchResultPage from './pages/SearchResultPage';
import Header from './components/Header';
import Footer from './components/Footer';
import Box from '@mui/material/Box';
import { AuthProvider } from './context/AuthContext';
import ProfileSettings from './pages/ProfileSettings';
import AboutReadLinkerPage from './pages/AboutReadLinkerPage';
import UserPage from './pages/UserPage';
import FriendRequestsPage from './pages/FriendRequestsPage';
import NotePage from './pages/NotePage';
import AuthorsDetailPage from './pages/AuthorsDetailPage';
import OtherUsersBookshelfPage from './pages/OtherUsersBookShelfPage';
import FollowingPage from './pages/FollowingPage';
import FollowersPage from './pages/FollowersPage';
import FriendsListPage from './pages/FriendsListPage';
import { FavoritesProvider } from './context/FavoritesContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import FavoriteBooksPage from './pages/FavoriteBooksPage';
import BrowsingHistoryPage from './pages/BrowsingHistoryPage';
import { HelmetProvider } from 'react-helmet-async';
import ListManagementPage from './pages/ListManagementPage';
import ListPage from './pages/ListPage';

const AppContent: React.FC = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <>
      {!isLoginPage && <Header />}
      <Box sx={isLoginPage ? {} : { mt: 10 }}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/dp/:GlobalBookID" element={<BookDetailPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/service-contact" element={<ServiceContactPage />} />
          <Route path="/bookshelf" element={<BookshelfPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/about-sticker" element={<AboutStickerPage />} />
          <Route path="/manifestation/:KANRI_ID" element={<ManifestationDetailPage />} />
          <Route path="/search/:query" element={<SearchResultPage />} />
          <Route path="/profile" element={<ProfileSettings />} />
          <Route path="/about-readlinker" element={<AboutReadLinkerPage />} />
          <Route path="/friend-requests" element={<FriendRequestsPage />} />
          <Route path="/note/:noteID" element = {<NotePage />} />
          <Route path="/author/:tenkyo_id" element={<AuthorsDetailPage />} />
          <Route path="/bookshelf/:userNameOnURL" element={<OtherUsersBookshelfPage />} />
          <Route path="/favBooks" element={<FavoriteBooksPage />} />
          <Route path="/booksJourney" element={<BrowsingHistoryPage />} />
          <Route path="/mylist" element={<ListManagementPage />} />
          <Route path="list/:bookListID" element={<ListPage />} />
          <Route path="/:username/following" element={<FollowingPage />} />
          <Route path="/:username/follower" element={<FollowersPage />} />
          <Route path="/:username/friendsList" element={<FriendsListPage />} />
          <Route path="/:userNameOnURL" element={<UserPage />} />

        </Routes>
      </Box>
      {!isLoginPage && <Footer />}
    </>
  );
};

function App() {
  return (
    <AuthProvider>
       <FavoritesProvider>
       <HelmetProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<AppContent />} />
          </Routes>
        </Router>
        </HelmetProvider>
      </FavoritesProvider>
    </AuthProvider>
  );
}

export default App;