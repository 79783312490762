//BookDetailPage.stx

import React, { useEffect, useState, useCallback } from 'react';
import { useParams,Link } from 'react-router-dom';
import { fetch_lending_history_bookdetail_hondana_by_GlobalBookID } from '../utils/fetch_lending_history_bookdetail_hondana_by_GlobalBookID';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import { biblio_for_react_authorized_test } from '../utils/biblio-for-react-authorized';
import { getIdToken } from '../utils/getIdToken';
import { discardGlobalBook } from '../utils/discardGlobalBook';
import { Helmet } from 'react-helmet';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

import amazonImage from '../assets/images/amazon-button-image.png';
import calilImage from '../assets/images/calil-button-image.png';
import ndlImage from '../assets/images/ndl-button-image.png';

import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';

function getAuthorsString(bookDetail: any): string {
    const authors = [];
    for (let i = 1; i <= 10; i++) {
        const contributorKey = i < 10 ? `ContributorName_0${i}` : `ContributorName_${i}`;
        if (bookDetail[contributorKey] && bookDetail[contributorKey].trim() !== "") {
            authors.push(bookDetail[contributorKey]);
        }
    }
    return authors.join(', ');
}

const extractPublishers = (bookDetail: any) => {
    const publishers: string[] = [];
    for (let i = 1; i <= 3; i++) {
        const publisher = bookDetail[`Publisher_${i}`];
        if (publisher) {
            publishers.push(publisher);
        }
    }
    return publishers.join(', ');
}

function convertISBN13toISBN10(isbn: string): string {
    if (!isbn || isbn.length !== 13 || !isbn.startsWith("978")) {
        throw new Error('提供されたISBNはISBN-13形式（978で始まる）ではありません。');
    }
    let isbn9 = isbn.substring(3, 12);
    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += (10 - i) * parseInt(isbn9.charAt(i), 10);
    }
    let checkDigit: number | string = 11 - (sum % 11);
    checkDigit = checkDigit === 10 ? 'X' : checkDigit === 11 ? '0' : checkDigit.toString();
    return isbn9 + checkDigit;
}

// const createAmazonLink = (isbn: string, mainTitle: string): string => {
//     let amazonISBN = isbn;
//     if (isbn && isbn.length === 13 && isbn.startsWith("978")) {
//         try {
//             amazonISBN = convertISBN13toISBN10(isbn);
//         } catch (error) {
//             console.error((error as Error).message);
//         }
//     }
//     if (amazonISBN && amazonISBN.length === 10) {
//         return `http://www.amazon.co.jp/dp/${amazonISBN}`;
//     } else {
//         const encodedTitle = encodeURIComponent(mainTitle);
//         return `https://www.amazon.co.jp/s?k=${encodedTitle}&__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A`;
//     }
// };

// const createCalilLink = (isbn: string) => {
//     return `https://calil.jp/book/${isbn}`;
// };

// const createNDLLink = (kanriId: string) => {
//     return `https://iss.ndl.go.jp/books/R100000002-I${kanriId}-00`;
// };

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('ja-JP', { 
        timeZone: 'Asia/Tokyo',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).replace(/\//g, '-');
};


const BookDetailPage: React.FC = () => {
    const idToken = getIdToken(); 
    const userInfoString = localStorage.getItem("userInfo");
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
    const userSub = userInfo ? userInfo.sub : null;
    const { GlobalBookID } = useParams<{ GlobalBookID: string }>();
    const [bookDetail, setBookDetail] = useState<any | null>(null);
    const [bookDetailAuthedUser, setBookDetailAuthedUser] = useState<any | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [isOwnBook, setIsOwnBook] = useState(false);

    const [isDiscarding, setIsDiscarding] = useState(false);

    // isOwnBook = bookDetailAuthedUser?.UserLibrarySettings?.UserSub === userInfo?.sub;

    type LendingHistoryType = {
        LendingID: string;
        BorrowerUserSub: string | null;
        LentDate: string;
        ReturnDate: string;
        ActualReturnDate: string | null;
        Email: string | null;
        Name: string | null;
        NotificationFrequency: string | null;
    };


    const fetchData = useCallback(async () => {
        if (!GlobalBookID) return;
        
        setIsLoading(true);
        try {
            const data = await fetch_lending_history_bookdetail_hondana_by_GlobalBookID(GlobalBookID, userSub);
            if (data && data['Book_Simple']) {
                setBookDetail(data['Book_Simple']);
            }
            
            if (idToken) {
                const res = await biblio_for_react_authorized_test(GlobalBookID, idToken);
                if (res && res['Book_Simple']) {
                    setBookDetailAuthedUser(res);
                    setIsOwnBook(res.UserLibrarySettings?.UserSub === userSub);
                }
            }
        } catch (error) {
            console.error('Error fetching book details:', error);
        } finally {
            setIsLoading(false);
        }
    }, [GlobalBookID, idToken, userSub]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleDiscardBook = async () => {
        setOpenDialog(false);
        setIsDiscarding(true);
        if (idToken && GlobalBookID) {
          try {
            const result = await discardGlobalBook(GlobalBookID, idToken);
            if (result) {
              // 成功メッセージをセッションストレージに保存
              sessionStorage.setItem('discardMessage', '本の除籍が完了しました。');
            } else {
              // エラーメッセージをセッションストレージに保存
              sessionStorage.setItem('discardMessage', '除籍処理に失敗しました。もう一度お試しください。');
            }
          } catch (error) {
            console.error('Error discarding book:', error);
            // エラーメッセージをセッションストレージに保存
            sessionStorage.setItem('discardMessage', '除籍処理中にエラーが発生しました。もう一度お試しください。');
          } finally {
            // 常にページをリロード
            window.location.reload();
          }
        }
      };

    useEffect(() => {
        // ページロード時にメッセージをチェック
        const message = sessionStorage.getItem('discardMessage');
        if (message) {
            alert(message);
            sessionStorage.removeItem('discardMessage');
        }
    }, []);

    if (isLoading) {
        return (
            <Container maxWidth="md" sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!bookDetail) {
        return <Typography>書籍情報を取得できませんでした。</Typography>;
    }

    const translateAllowStatus = (status: string) => {
        return status === 'ALLOW' ? '可' : '不可';
    };

    const isBookRemoved = bookDetailAuthedUser?.GlobalBook?.RemovedDate !== null;

    const handleShare = async () => {
        try {
          if (navigator.share) {
            await navigator.share({
              title: bookDetail?.MainTitle,
              url: `https://readlinker.com/dp/${GlobalBookID}`
            });
          } else {
            navigator.clipboard.writeText(`https://readlinker.com/dp/${GlobalBookID}`);
            alert('個別資料URLをコピーしました');
          }
        } catch (error) {
          console.error('Error sharing:', error);
        }
      };
    
    
    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Helmet>
                <title>{bookDetail.MainTitle} - ReadLinker-本の詳細</title>
                <meta name="description" content={`${bookDetail.ContentDetailTitle}`} />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@ReadLinker_O" />
                <meta name="twitter:title" content={bookDetail.MainTitle} />
                <meta name="twitter:description" content={bookDetail.MainTitle} />
                <meta name="twitter:image" content={`${generateCoverUrl(bookDetail)}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${bookDetail.MainTitle}`} />
                <meta property="og:description" content="ReadLinker-本の詳細" />
                <meta property="og:url" content={`https://readlinker.com/dp/${GlobalBookID}`} />
                <meta property="og:image" content={`${generateCoverUrl(bookDetail)}`} />
            </Helmet>

            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Box sx={{ 
                        backgroundColor: bookDetailAuthedUser?.GlobalHondana?.ColorCode || 'transparent',
                        p: 2,
                        borderRadius: 2
                    }}>
                        <Card>
                            <CardMedia
                                component="img"
                                height="auto"
                                image={generateCoverUrl(bookDetail)}
                                alt={`Cover for ${bookDetail.MainTitle}`}
                            />
                        </Card>
                        {bookDetail.KANRI_ID && (
                            <Button
                            component={Link}
                            to={`/manifestation/${bookDetail.KANRI_ID}`}
                            variant="outlined"
                            fullWidth
                            sx={{ 
                                mt: 2, 
                                backgroundColor: 'white',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.light',
                                    color: 'white',
                                }
                            }}
                        >
                            書誌ページ
                        </Button>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant="h5" component="h1" gutterBottom>
                        {bookDetail.MainTitle}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {bookDetail.ContentDetailTitle}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        {bookDetail.TitleOthers}
                    </Typography>
                    <IconButton onClick={handleShare} sx={{ minWidth: 'auto', p: 1 }}>
                        <IosShareIcon sx={{ fontSize: 24 }} />
                    </IconButton>
                    <Typography variant="body2" color="textSecondary">
                        <strong>著者:</strong> {getAuthorsString(bookDetail)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        <strong>出版社:</strong> {extractPublishers(bookDetail)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        <strong>ISBN:</strong> {bookDetail.ISBN}
                    </Typography>
                    {bookDetailAuthedUser?.GlobalBook?.RegisteredDatetime && (
                        <Typography variant="body2" color="textSecondary">
                            <strong>登録日:</strong> {formatDate(bookDetailAuthedUser.GlobalBook.RegisteredDatetime)}
                        </Typography>
                    )}
                    
                    {bookDetailAuthedUser && bookDetailAuthedUser.GlobalHondana && (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                            <strong>本棚名:</strong> {bookDetailAuthedUser.GlobalHondana.HondanaName}
                        </Typography>
                    )}

                    {bookDetailAuthedUser && bookDetailAuthedUser.UserLibrarySettings && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body2" color="textSecondary">
                                <strong>ユーザーライブラリ設定</strong>
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                <strong>ライブラリ名:</strong> {bookDetailAuthedUser.UserLibrarySettings.LibraryName}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                <strong>セルフ貸出:</strong> {translateAllowStatus(bookDetailAuthedUser.UserLibrarySettings.AllowSelfLend)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                <strong>セルフ返却:</strong> {translateAllowStatus(bookDetailAuthedUser.UserLibrarySettings.AllowSelfReturn)}
                            </Typography>
                        </Box>
                    )}
                    
                    {/* <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            {bookDetail.ISBN && (
                                <Grid item>
                                    <Button
                                        href={createAmazonLink(bookDetail.ISBN, bookDetail.MainTitle)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ p: 0, minWidth: 'auto' }}
                                    >
                                        <img src={amazonImage} alt="Amazonで見る" style={{ width: 'auto', height: '40px' }} />
                                    </Button>
                                </Grid>
                            )}
                            {bookDetail.ISBN && (
                                <Grid item>
                                    <Button
                                        href={createCalilLink(bookDetail.ISBN)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ p: 0, minWidth: 'auto' }}
                                    >
                                        <img src={calilImage} alt="カーリルで見る" style={{ width: 'auto', height: '40px' }} />
                                    </Button>
                                </Grid>
                            )}
                            {bookDetail.KANRI_ID && (
                                <Grid item>
                                    <Button
                                        href={createNDLLink(bookDetail.KANRI_ID)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ p: 0, minWidth: 'auto' }}
                                    >
                                        <img src={ndlImage} alt="国立国会図書館で見る" style={{ width: 'auto', height: '40px' }} />
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Box> */}
                    
                    {isBookRemoved ? (
                        bookDetailAuthedUser?.GlobalBook?.RemovedDate ? (
                            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                                除籍日: {bookDetailAuthedUser.GlobalBook.RemovedDate}
                            </Typography>
                        ) : (
                            // 除籍日がない場合でも除籍ボタンを表示
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => setOpenDialog(true)}
                                    disabled={isDiscarding}
                                >
                                    {isDiscarding ? '処理中...' : '除籍する'}
                                </Button>
                            </Box>
                        )
                    ) : isOwnBook && (
                        <Box sx={{ mt: 2 }}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => setOpenDialog(true)}
                                disabled={isDiscarding}
                            >
                                {isDiscarding ? '処理中...' : '除籍する'}
                            </Button>
                        </Box>
                    )}

                    
                    {bookDetailAuthedUser && (
                        <Box mt={4}>
                            {bookDetailAuthedUser.LendingHistory && bookDetailAuthedUser.LendingHistory.length > 0 && (
                                <Card sx={{ mt: 4 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="h2">
                                            貸出履歴
                                        </Typography>
                                        {bookDetailAuthedUser.LendingHistory.map((lending: LendingHistoryType, index: number) => (
                                            <Box key={index} sx={{ mb: 2 }}>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>貸出ID:</strong> {lending.LendingID}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>貸出日:</strong> {lending.LentDate}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>返却予定日:</strong> {lending.ReturnDate}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary"></Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>実際の返却日:</strong> {lending.ActualReturnDate}
                                                </Typography>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider', my: 1 }} />
                                            </Box>
                                        ))}
                                    </CardContent>
                                </Card>
                            )}
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">確認</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        この本を除籍しますか？本棚一覧では表示されなくなります。
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} disabled={isDiscarding}>キャンセル</Button>
                    <Button onClick={handleDiscardBook} autoFocus disabled={isDiscarding}>
                        はい
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default BookDetailPage;