import React, { createContext, useContext, useReducer, useEffect,ReactNode } from 'react';
import { getFavoriteBooks } from '../utils/favoritesAndHistoryApi';
import { useAuth } from './AuthContext';

interface FavoriteBook {
    KANRI_ID: string;
    userSub: string;
    favAt?: string; // オプショナルに変更
  }
  

interface FavoritesState {
  favoriteBooks: FavoriteBook[];
  lastUpdated: number;
}


type FavoritesAction =
  | { type: 'SET_FAVORITES'; payload: FavoriteBook[] }
  | { type: 'ADD_FAVORITE'; payload: Omit<FavoriteBook, 'favAt'> }
  | { type: 'REMOVE_FAVORITE'; payload: string };


const initialState: FavoritesState = {
  favoriteBooks: [],
  lastUpdated: 0,
};

const FavoritesContext = createContext<{
  state: FavoritesState;
  dispatch: React.Dispatch<FavoritesAction>;
} | undefined>(undefined);

const favoritesReducer = (state: FavoritesState, action: FavoritesAction): FavoritesState => {
    switch (action.type) {
      case 'SET_FAVORITES':
        return { ...state, favoriteBooks: action.payload, lastUpdated: Date.now() };
      case 'ADD_FAVORITE':
        return {
          ...state,
          favoriteBooks: [...state.favoriteBooks, action.payload],
          lastUpdated: Date.now(),
        };
      case 'REMOVE_FAVORITE':
        return {
          ...state,
          favoriteBooks: state.favoriteBooks.filter((book) => book.KANRI_ID !== action.payload),
          lastUpdated: Date.now(),
        };
      default:
        return state;
    }
  };



interface FavoritesProviderProps {
    children: ReactNode;
  }
  
  export const FavoritesProvider: React.FC<FavoritesProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(favoritesReducer, initialState);
    const { userSub, idToken } = useAuth();

    console.log('userSub:', userSub);
    console.log('idToken:', idToken);
  
    useEffect(() => {
      const fetchFavorites = async () => {
        if (userSub && idToken) {
          try {
            const favorites = await getFavoriteBooks(userSub, idToken);
            dispatch({ type: 'SET_FAVORITES', payload: favorites });
          } catch (error) {
            console.error('Error fetching favorites:', error);
          }
        }
      };
  
      fetchFavorites();
    }, [userSub, idToken]);
  
    return (
      <FavoritesContext.Provider value={{ state, dispatch }}>
        {children}
      </FavoritesContext.Provider>
    );
  };

export const useFavorites = () => {
  const context = useContext(FavoritesContext);
  if (context === undefined) {
    throw new Error('useFavorites must be used within a FavoritesProvider');
  }
  return context;
};