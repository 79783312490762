//components/Header.tsx

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { userPool } from '../services/cognitoConfig';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import logo from '../assets/readlinker-logo.svg';
import logoSmall from '../assets/images/readLinker_mini_logo_128.png';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import { getUserProfileImage, getUserProfileImageByuserSubCF} from '../utils/userProfileApi';
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';

const Header: React.FC = () => {
    const { isLoggedIn, logout } = useAuth();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
    const [profileImage, setProfileImage] = useState<string | null>(null);
    const [username, setUsername] = useState<string | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!location.pathname.startsWith('/search')) {
            setSearchQuery(''); // 検索結果ページ以外で検索クエリをクリア
        }
    }, [location.pathname]);

    useEffect(() => {
        const fetchUserProfileData = async () => {
            if (isLoggedIn) {
                const cognitoUser: CognitoUser | null = userPool.getCurrentUser();
                if (cognitoUser) {
                    cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
                        if (err) {
                            console.error('Error getting session:', err);
                            return;
                        }
                        if (session) {
                            const idToken = session.getIdToken().getJwtToken();
                            const userSub = session.getIdToken().payload.sub;
                            const name = session.getIdToken().payload['cognito:username'];
                            setUsername(name);
                            // getUserProfileImage(userSub, idToken).then(response => {
                            //     if (response && response.profileImage) {
                            //         setProfileImage(response.profileImage);
                            //     }
                            getUserProfileImageByuserSubCF(userSub).then(response => {
                                if (response && response) {
                                    setProfileImage(response);
                                }
                            }).catch(error => {
                                console.error('Error fetching profile image:', error);
                            });
                        }
                    });
                }
            }
        };

        fetchUserProfileData();
    }, [isLoggedIn]);

    const handleLogout = () => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.signOut();
        }
        logout();
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleSearch = () => {
        if (searchQuery.trim() !== '') {
            navigate(`/search/${searchQuery}`);
            window.scrollTo(0, 0);
            setIsSearchActive(false);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleProfileClick = () => {
        if (username) {
            navigate(`/${username}`);
        }
        handleProfileMenuClose();
    };

return (
    <>
        <AppBar position="fixed" sx={{ backgroundColor: 'rgb(35,48,72)', zIndex: theme => theme.zIndex.drawer + 1 }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={isSmallScreen ? logoSmall : logo}
                        alt="ReadLinker Logo"
                        style={{ maxHeight: isSmallScreen ? '30px' : '40px', marginTop: '0px', marginBottom: '5px' }}
                    />
                </Link>
                <Box sx={{ flexGrow: 1, mx: 2, display: 'flex', justifyContent: 'center' }}>
                    <TextField
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyPress={handleKeyPress}
                        onFocus={() => setIsSearchActive(true)}
                        variant="outlined"
                        size="small"
                        placeholder="検索"
                        sx={{ backgroundColor: 'white', borderRadius: 1, width: '100%', maxWidth: '500px' }}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={handleSearch}>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {isLoggedIn && (
                        <>
                            {!isSmallScreen && (
                                <Button color="inherit" component={Link} to="/bookshelf">本棚</Button>
                            )}
                            {profileImage ? (
                                <img
                                    src={profileImage}
                                    alt="User Profile"
                                    style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: 'pointer', marginRight: '10px' }}
                                    onClick={handleProfileMenu}
                                />
                            ) : (
                                <p>Loading...</p>
                            )}
                            <Menu
                                anchorEl={menuAnchorEl}
                                open={Boolean(menuAnchorEl)}
                                onClose={handleProfileMenuClose}
                            >
                                <MenuItem onClick={handleProfileClick}>プロフィール</MenuItem>
                                <MenuItem onClick={handleProfileMenuClose} component={Link} to="/profile">プロフィール設定</MenuItem>
                                <MenuItem onClick={handleProfileMenuClose} component={Link} to="/friend-requests">友達リクエスト</MenuItem>
                                <MenuItem onClick={handleProfileMenuClose} component={Link} to="/favBooks">お気に入りの本</MenuItem>
                                <MenuItem onClick={handleProfileMenuClose} component={Link} to="/mylist">リスト</MenuItem>
                                <MenuItem onClick={handleProfileMenuClose} component={Link} to="/booksJourney">本の閲覧履歴</MenuItem>
                                <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
                            </Menu>
                        </>
                    )}
                    {!isLoggedIn && !isSmallScreen && (
                        <Button color="inherit" component={Link} to="/login">ログイン</Button>
                    )}
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenu}>
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose} component={Link} to="/bookshelf">本棚</MenuItem>
                        {!isLoggedIn && <MenuItem onClick={handleClose} component={Link} to="/login">ログイン</MenuItem>}
                        <MenuItem onClick={handleClose} component={Link} to="/about-readlinker">ReadLinkerについて</MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
        <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            open={isSearchActive}
            onClick={() => setIsSearchActive(false)}
        />
    </>
);
}

export default Header;