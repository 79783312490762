
import React from 'react';
import { Helmet } from 'react-helmet';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import iosAppQR from '../assets/images/readlinker_ios_app_download.png';
import androidAppQR from '../assets/images/readlinker_android_app_download.png';
import appStoreIcon from '../assets/images/appstore_us.svg';
import playStoreIcon from '../assets/images/playstore_us.png';

const AboutReadLinker: React.FC = () => {
  const commonImageStyle = { maxWidth: '50%', height: 'auto' };
  const iconStyle = { maxWidth: '100px', marginRight: '10px' };

  return (
    <>
      <Helmet>
        <title>ReadLinker - 書籍管理アプリ</title>
        <meta name="description" content="ReadLinkerは新しい書籍管理アプリです。" />
        <meta name="keywords" content="ReadLinker, 書籍管理, 本の貸し借り, 本棚管理, アプリ" />
      </Helmet>
      <Container maxWidth="md" sx={{ mt: 5 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          ReadLinkerとは
        </Typography>
        <Typography variant="body1" paragraph>
          ReadLinkerは「本からつながる人類知」をコンセプトに開発した書籍管理アプリです。
        </Typography>
        <Typography variant="body1" paragraph>
          本には、その著者がときに一生を費やして集め、整理し、人々へ伝えるために文章にしたテキストが詰まっています。私たち人類は、本を書き、読み、そして理解しようと努力して、その得られた知識を活用し、ときにそれを元に新しいアイデアを生み出すことで、今日の高度な文明社会を築きました。
        </Typography>
        <Typography variant="body1" paragraph>
          無数の本には無数の情報が載っています。この中には、真実に近い情報もあれば、真実からは程遠い情報も含まれるでしょう。本というメディアは、出典が明確です。すなわち、その情報のどこが間違っているのかを第三者が注釈をつけることで、後世の読者は、その本からより多くの知識と洞察を得ることが可能です。
        </Typography>
        <Typography variant="body1" paragraph>
          SNSが人々の生活に浸透し、いつでも簡単に意見を表明することができるようになった現在、私たちは高速で飛び交う真偽不明の情報に右往左往しています。政治的意見が近い人々が互いに情報を密にやりとりし、少し偏向したアイデアがさらに偏向し、強化されます。人々が置かれたこの環境は、実際に人々の思考様式を変えるに至り、熟議を難しくし、結果的に民主主義政治の実際の仕組みすら脅かしています。
        </Typography>
        <Typography variant="body1" paragraph>
          ReadLinkerは、本というメディアの特性を再評価し、「速いメディア」に対するアンチテーゼ、「遅いメディア」を目指して開発を行っています。本をより深く読み込むための情報、第三者からの注釈やフィードバック、同じ著者の他の著作に関する情報などの付加情報を提供していきます。これらの付加的な情報を元に、より客観的に、より深く、その本を読むことが可能になります。
        </Typography>
        <Typography variant="body1" paragraph>
          自分の持っている本を他人に貸したり、他人の本棚から新しい本を発見するのは、紙の本ならではの魅力です。紙の本は、電子書籍とは異なるチャンネルで、人々が本と出会う機会を作っています。ReadLinkerは、自分の本を快適なUIで管理するだけでなく、図書館のように自分の本棚を公開し、また実際に本を貸した際の記録も管理できる機能を持っています。
        </Typography>
        <Typography variant="body1" paragraph>
          今後、ReadLinkerは末長く開発を続け、人類の文明にとって、良きメディアとして活用されるようになることを目指します。
        </Typography>

        <Divider sx={{ my: 4 }} />

        <Box textAlign="center" mb={4}>
          <Typography variant="h6" component="p" gutterBottom>
            iOSアプリ
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center">
          <a href="https://apps.apple.com/jp/app/readlinker/id6477534373" target="_blank" rel="noopener noreferrer">
            <img src={appStoreIcon} alt="App Store Icon" style={iconStyle} />
          </a>
            <img src={iosAppQR} alt="ReadLinker iOS App Download" style={commonImageStyle} />
          </Box>
        </Box>

        <Divider sx={{ my: 4 }} />

        <Box textAlign="center">
          <Typography variant="h6" component="p" gutterBottom>
            Androidアプリ
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center">
          <a href="https://play.google.com/store/apps/details?id=jp.ada_llc.readlinker2" target="_blank" rel="noopener noreferrer">
            <img src={playStoreIcon} alt="Google Play Store Icon" style={iconStyle} />
          </a>
            <img src={androidAppQR} alt="ReadLinker Android App Download" style={commonImageStyle} />
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default AboutReadLinker;
