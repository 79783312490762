import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { getBooksInListDetail } from '../utils/listHandler';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import { getUserInfoByUsersubCF, UserData } from '../utils/userProfileApi';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface BookDetail {
  KANRI_ID: string;
  MainTitle: string;
  ISBN: string;
  Responsibility: string;
  PublishingDate: string;
  addAt: number; // 追加: 登録順用のプロパティ
}

interface ListData {
  listName: string;
  comments: string;
  books: BookDetail[];
  openLevel: string;
}

const ListPage: React.FC = () => {
    const { bookListID } = useParams<{ bookListID: string }>();
    const { idToken } = useAuth();
    const navigate = useNavigate();
    const [listData, setListData] = useState<ListData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const isMounted = useRef(true);
    const [getIdToken, setGetIdToken] = useState(false);
    const [userInfo, setUserInfo] = useState<UserData | null>(null);
    const [sortOrder, setSortOrder] = useState<string>('addAt'); // デフォルトは登録順

    const fetchListData = useCallback(async (token: string) => {
        if (!bookListID || !isMounted.current) return;

        setIsLoading(true);
        setError(null);

        try {
            const response = await getBooksInListDetail(bookListID, token);
            console.log('Raw response:', response);

            if (!isMounted.current) return;

            if (response && response.body) {
                const bodyData = JSON.parse(response.body);
                console.log('Parsed bodyData:', bodyData);

                if (bodyData.books) {
                    const parsedBooks = typeof bodyData.books === 'string' ? JSON.parse(bodyData.books) : bodyData.books;

                    if (Array.isArray(parsedBooks) && parsedBooks.length > 0) {
                        setListData({
                            listName: bodyData.listName || '無題のリスト',
                            comments: bodyData.comments || '',
                            books: parsedBooks,
                            openLevel: bodyData.openLevel
                        });

                        // Fetch user info based on userSub
                        const userData = await getUserInfoByUsersubCF(bodyData.userSub);
                        console.log('User data:', userData);
                        setUserInfo(userData);
                    } else {
                        throw new Error('書籍データが空です。');
                    }
                } else {
                    throw new Error('書籍データが見つかりません。');
                }
            } else {
                throw new Error('リストデータの取得に失敗しました。');
            }
        } catch (err) {
            console.error('Error fetching list data:', err);
            if (isMounted.current) {
                setError(err instanceof Error ? err.message : 'リストの読み込み中にエラーが発生しました。');
            }
        } finally {
            if (isMounted.current) {
                setIsLoading(false);
            }
        }
    }, [bookListID]);

    useEffect(() => {
        isMounted.current = true;

        const waitForIdToken = async () => {
            if (idToken) {
                setGetIdToken(true);
                fetchListData(idToken);
            } else {
                let attempts = 0;
                const interval = setInterval(() => {
                    if (idToken) {
                        clearInterval(interval);
                        setGetIdToken(true);
                        fetchListData(idToken);
                    }
                    attempts += 1;
                    if (attempts >= 30) { // 3 seconds, assuming 100ms interval
                        if (isLoading) {
                        clearInterval(interval);
                        setError('idTokenの取得に失敗しました。');
                        setIsLoading(false);
                    }
                    }
                }, 100);
            }
        };

        waitForIdToken();

        return () => {
            isMounted.current = false;
        };
    }, [fetchListData, idToken]);

    useEffect(() => {
        console.log('Current state:', { listData, isLoading, error, userInfo });
    }, [listData, isLoading, error, userInfo]);


    const handleBookClick = (KANRI_ID: string) => {
        window.open(`/manifestation/${KANRI_ID}`, '_blank');
    };
    

    const handleSortChange = (event: SelectChangeEvent<string>) => {
        setSortOrder(event.target.value as string);
    };

    useEffect(() => {
        if (listData) {
            const sortedBooks = [...listData.books].sort((a, b) => {
                if (sortOrder === 'addAt') {
                    return b.addAt - a.addAt;
                } else if (sortOrder === 'PublishingDate') {
                    return new Date(b.PublishingDate).getTime() - new Date(a.PublishingDate).getTime();
                }
                return 0;
            });
            setListData({ ...listData, books: sortedBooks });
        }
    }, [sortOrder, listData]);

    if (isLoading) {
        return (
            <Container maxWidth="md" sx={{ mt: 4, textAlign: 'center' }}>
                <CircularProgress />
                <Typography variant="body2" sx={{ mt: 2 }}>
                    データを読み込んでいます...
                </Typography>
            </Container>
        );
    }

    if (error && !getIdToken) {
        fetchListData(idToken || '');
        // return (
        //     <Container maxWidth="md" sx={{ mt: 4 }}>
        //         <Alert severity="error">{error}</Alert>
        //         <Button onClick={() => fetchListData(idToken || '')} sx={{ mt: 2 }}>
        //             ログアウトせず表示
        //         </Button>
        //     </Container>
        // );
    }

    if (!listData) {
        return (
            <Container maxWidth="md" sx={{ mt: 4 }}>
                <Alert severity="info">リストデータがありません。</Alert>
            </Container>
        );
    }

//     return (
//         <Container maxWidth="md" sx={{ mt: 4 }}>
//             {userInfo && (
//                 <Box display="flex" alignItems="center" mb={2}>
//                     <Avatar
//                         src={userInfo.profileImage}
//                         alt={userInfo.username}
//                         sx={{ width: 56, height: 56, cursor: 'pointer' }}
//                         onClick={() => navigate(`/${userInfo.username}`)}
//                     />
//                     <Box ml={2}>
//                         <Typography variant="h6" component="span" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
//                             {userInfo.preferred_name}
//                         </Typography>
//                         <Typography variant="body2" color="textSecondary">
//                             <Link
//                                 component="button"
//                                 variant="body2"
//                                 color="textSecondary"
//                                 onClick={() => navigate(`/${userInfo.username}`)}
//                             >
//                                 @{userInfo.username}
//                             </Link>
//                         </Typography>
//                     </Box>
//                 </Box>
//             )}
//             <Typography variant="h4" gutterBottom>{listData.listName}</Typography>
//             <Typography variant="body2" color="textSecondary" gutterBottom>
//                 公開設定: {
//                     listData.openLevel === 'PUBLIC' ? '公開' :
//                     listData.openLevel === 'URLSHARE' ? 'リンク共有' : 'プライベート'
//                 }
//             </Typography>
//             {listData.comments && (
//                 <Typography variant="body1" paragraph>{listData.comments}</Typography>
//             )}
//             <FormControl sx={{ mb: 2 }}>
//                 <InputLabel id="sort-label">並び替え</InputLabel>
//                 <Select
//                     labelId="sort-label"
//                     value={sortOrder}
//                     onChange={handleSortChange}
//                     label="並び替え"
//                 >
//                     <MenuItem value="addAt">登録順</MenuItem>
//                     <MenuItem value="PublishingDate">出版順</MenuItem>
//                 </Select>
//             </FormControl>
//             <Grid container spacing={2}>
//                 {listData.books.map((book) => (
//                     <Grid item xs={12} sm={6} md={4} lg={2} key={book.KANRI_ID}>
//                         <Card>
//                             <CardActionArea onClick={() => handleBookClick(book.KANRI_ID)}>
//                                 <CardMedia
//                                     component="img"
//                                     height="200"
//                                     image={generateCoverUrl(book)}
//                                     alt={book.MainTitle}
//                                     sx={{ objectFit: 'contain', p: 1 }}
//                                 />
//                                 <Box sx={{ p: 2 }}>
//                                     <Typography variant="subtitle1" noWrap>{book.MainTitle}</Typography>
//                                     <Typography variant="body2" color="text.secondary" noWrap>{book.Responsibility}</Typography>
//                                     <Typography variant="caption" display="block" noWrap>{book.PublishingDate}</Typography>
//                                 </Box>
//                             </CardActionArea>
//                         </Card>
//                     </Grid>
//                 ))}
//             </Grid>
//         </Container>
//     );
// };
return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
        {userInfo && (
            <Box display="flex" alignItems="center" mb={2}>
                <Avatar
                    src={userInfo.profileImage}
                    alt={userInfo.username}
                    sx={{ width: 56, height: 56, cursor: 'pointer' }}
                    onClick={() => navigate(`/${userInfo.username}`)}
                />
                <Box ml={2}>
                    <Typography variant="h6" component="span" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                        {userInfo.preferred_name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        <Link
                            component="button"
                            variant="body2"
                            color="textSecondary"
                            onClick={() => navigate(`/${userInfo.username}`)}
                        >
                            @{userInfo.username}
                        </Link>
                    </Typography>
                </Box>
            </Box>
        )}
        <Typography variant="h4" gutterBottom>{listData.listName}</Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
            公開設定: {
                listData.openLevel === 'PUBLIC' ? '公開' :
                listData.openLevel === 'URLSHARE' ? 'リンク共有' : 'プライベート'
            }
        </Typography>
        {listData.comments && (
            <Typography variant="body1" paragraph>{listData.comments}</Typography>
        )}
        <FormControl sx={{ mb: 2 }}>
            <InputLabel id="sort-label">並び替え</InputLabel>
            <Select
                labelId="sort-label"
                value={sortOrder}
                onChange={handleSortChange}
                label="並び替え"
            >
                <MenuItem value="addAt">登録順</MenuItem>
                <MenuItem value="PublishingDate">出版順</MenuItem>
            </Select>
        </FormControl>
        <Grid container spacing={2}>
            {listData.books.map((book) => (
                <Grid item xs={12} sm={6} md={4} lg={2} key={book.KANRI_ID}>
                    <Card>
                        <CardActionArea onClick={() => handleBookClick(book.KANRI_ID)}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={generateCoverUrl(book)}
                                alt={book.MainTitle}
                                sx={{ objectFit: 'contain', p: 1 }}
                            />
                        </CardActionArea>
                    </Card>
                    <Box sx={{ mt: 1, textAlign: 'left' }}>
                        <Typography variant="subtitle2" noWrap>{book.MainTitle}</Typography>
                        <Typography variant="body2" color="text.secondary" noWrap>{book.Responsibility}</Typography>
                        <Typography variant="caption" display="block" noWrap>{book.PublishingDate}</Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
    </Container>
);
};

export default ListPage;
