import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, CircularProgress } from '@mui/material';
import UserList from '../components/UserList';
import { get_following_details } from '../utils/followerFriendsInfo';
import { getUserSubFromUsernameCF } from '../utils/userProfileApi';
import { UserDetail } from '../types/types';
import { useAuth } from '../context/AuthContext';

interface FollowingResponse {
  following: UserDetail[];
  lastEvaluatedKey: string | null;
}

const FollowingPage: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const [following, setFollowing] = useState<UserDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { idToken } = useAuth();

  const fetchFollowing = useCallback(async (isInitialFetch: boolean = false) => {
    if (!username) return;

    setLoading(true);
    setError(null);
    try {
      const targetUserSub = await getUserSubFromUsernameCF(username);
      if (!targetUserSub) {
        setError('ユーザーが見つかりません。');
        return;
      }

      const response = await get_following_details(targetUserSub, idToken || undefined, isInitialFetch ? null : lastEvaluatedKey);
      console.log('API Response:', response); // デバッグ用ログ

      if (response && 'following' in response) {
        setFollowing(prev => isInitialFetch ? response.following : [...prev, ...response.following]);
        setLastEvaluatedKey(response.lastEvaluatedKey);
        setHasMore(!!response.lastEvaluatedKey);
      } else {
        console.error('Unexpected response structure:', response);
        setError('データの取得に失敗しました。');
      }
    } catch (error) {
      console.error('Failed to fetch following:', error);
      setError('フォロー中のユーザー情報の取得に失敗しました。');
    } finally {
      setLoading(false);
    }
  }, [username, idToken, lastEvaluatedKey]);

  useEffect(() => {
    fetchFollowing(true);
  }, [username]);

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      fetchFollowing();
    }
  };

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        {username}のフォロー中
      </Typography>
      {loading && following.length === 0 ? (
        <CircularProgress />
      ) : (
        <UserList users={following} onLoadMore={handleLoadMore} hasMore={hasMore} />
      )}
    </Container>
  );
};

export default FollowingPage;