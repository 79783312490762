// src/pages/AuthorsDetailPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import '../css/AuthorsDetailPage.css'; // CSSファイルをインポート

const fetchAuthorDetails = async (tenkyoId: string) => {
  const response = await axios.get(`https://d2c46f93hx1b7q.cloudfront.net/v1/${tenkyoId}`);
  console.log('fetchAuthorDetails: response:', response.data);
  return response.data;
};

const sortBooksByPublishingDate = (books: any[]) => {
  return books.sort((a, b) => {
    const dateA = a.PublishingDate ? parseInt(a.PublishingDate, 10) : 0;
    const dateB = b.PublishingDate ? parseInt(b.PublishingDate, 10) : 0;
    return dateB - dateA;
  });
};

// KANRI_IDでユニークな配列を作成する関数
const filterUniqueBooks = (books: any[]) => {
  const uniqueBooks = books.reduce((acc, book) => {
    if (!acc.find((item: any) => item.KANRI_ID === book.KANRI_ID)) {
      acc.push(book);
    }
    return acc;
  }, [] as any[]);
  return uniqueBooks;
};

const AuthorsDetailPage: React.FC = () => {
  const { tenkyo_id } = useParams<{ tenkyo_id: string }>();
  const [authorDetails, setAuthorDetails] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!tenkyo_id) {
      return;
    }
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await fetchAuthorDetails(tenkyo_id);
        setAuthorDetails(data);
      } catch (error) {
        setError('著者情報の取得に失敗しました。');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [tenkyo_id]);

  useEffect(() => {
    // ページのロード時にスクロール位置を先頭にリセット
    window.scrollTo(0, 0);
  });

  if (loading) return <CircularProgress />;
  if (error) return <Typography variant="body2" color="error">{error}</Typography>;
  console.log('authorDetails:', authorDetails);
  if (!authorDetails) return null;
  

  // デフォルト値を設定
  const { ps_response, dynamodb_item, wikipedia_summary = '' } = authorDetails || {};
  const { AuthorsInfo = {}, Books = [] } = ps_response || {};

  // 書籍を出版年でソートし、一意な書籍リストを取得
  const sortedBooks = sortBooksByPublishingDate(filterUniqueBooks(Books));

  // 著者が書いた本と関連する本に分ける
  const booksWrittenByAuthor = sortedBooks.filter((book) =>
    [book.ContributorID_01, book.ContributorID_02, book.ContributorID_03, book.ContributorID_04, book.ContributorID_05, book.ContributorID_06, book.ContributorID_07, book.ContributorID_08, book.ContributorID_09, book.ContributorID_10].includes(tenkyo_id)
  );

  const relatedBooks = sortedBooks.filter((book) =>
    ![book.ContributorID_01, book.ContributorID_02, book.ContributorID_03, book.ContributorID_04, book.ContributorID_05, book.ContributorID_06, book.ContributorID_07, book.ContributorID_08, book.ContributorID_09, book.ContributorID_10].includes(tenkyo_id)
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box>
        <Typography variant="h4" component="h1" gutterBottom>
          著者の詳細: {AuthorsInfo.F_A ? AuthorsInfo.F_A.replace(/,/g, '') : '不明'}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {AuthorsInfo.F_D ? `${AuthorsInfo.F_D.split('-')[0]} - ${AuthorsInfo.F_D.split('-')[1]}` : ''}
        </Typography>
        {/* dynamodb_item?.Wikipedia_Linkの存在をチェック */}
        {dynamodb_item?.Wikipedia_Link ? (
          <Typography variant="body2" sx={{ mt: 2 }}>
            Wikipedia: <a href={dynamodb_item.Wikipedia_Link} target="_blank" rel="noopener noreferrer">{dynamodb_item.Wikipedia_Link}</a>
          </Typography>
        ) : (
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            
          </Typography>
        )}
        {/* wikipedia_summaryが空でない場合に概要を表示 */}
        {wikipedia_summary ? (
          <Typography variant="body1" sx={{ mt: 2 }}>
            {wikipedia_summary}
            （Wikipediaより）
          </Typography>
        ) : (
          <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
            
          </Typography>
        )}
      </Box>

      {/* 著者が書いた書籍 */}
      {booksWrittenByAuthor.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            {AuthorsInfo.F_A ? AuthorsInfo.F_A.replace(/,/g, '') : '不明'} の本
          </Typography>
          <Grid container spacing={4}>
            {booksWrittenByAuthor.map((book: any) => (
              <Grid item xs={12} sm={6} md={3} key={book.KANRI_ID}>
                <Card
                  component={Link}
                  to={`/manifestation/${book.KANRI_ID}`}
                  className="card" // カスタムスタイルクラスを追加
                  sx={{
                    textDecoration: 'none',
                    borderRadius: 2,
                    overflow: 'hidden',
                    height: '100%', // カード全体の高さを親要素に合わせる
                  }}
                >
                  <Box className="card-img-wrapper">
                    <CardMedia
                      component="img"
                      image={generateCoverUrl(book)}
                      alt={book.MainTitle}
                      sx={{
                        marginTop: '10px',
                      }}
                    />
                  </Box>
                  <CardContent>
                    <Typography variant="body2" component="p" gutterBottom>
                      {book.MainTitle}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {book.Publisher_1} ({book.PublishingDate})
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {book.Responsibility}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {book.ClassMark}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* 著者が書いていない関連書籍 */}
      {relatedBooks.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            {AuthorsInfo.F_A ? AuthorsInfo.F_A.replace(/,/g, '') : '不明'} に関する本
          </Typography>
          <Grid container spacing={4}>
            {relatedBooks.map((book: any) => (
              <Grid item xs={12} sm={6} md={3} key={book.KANRI_ID}>
                <Card
                  component={Link}
                  to={`/manifestation/${book.KANRI_ID}`}
                  className="card" // カスタムスタイルクラスを追加
                  sx={{
                    textDecoration: 'none',
                    borderRadius: 2,
                    overflow: 'hidden',
                    height: '100%', // カード全体の高さを親要素に合わせる
                  }}
                >
                  <Box className="card-img-wrapper">
                    <CardMedia
                      component="img"
                      image={generateCoverUrl(book)}
                      alt={book.MainTitle}
                      sx={{
                        marginTop: '10px',
                      }}
                    />
                  </Box>
                  <CardContent>
                    <Typography variant="body2" component="p" gutterBottom>
                      <strong>{book.MainTitle}</strong>
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {book.Publisher_1} ({book.PublishingDate})
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {book.Responsibility}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {book.ClassMark}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Container>
  );
};

export default AuthorsDetailPage;
