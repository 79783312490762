
import { CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { userPool } from '../services/cognitoConfig';

// idTokenの期限切れをチェックする関数
export const isTokenExpired = (token: string) => {
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload.exp < Math.floor(Date.now() / 1000);
};

// idTokenを取得する関数
export const getIdToken = () => {
  const idToken = sessionStorage.getItem("idToken");
  if (idToken) {
    if (isTokenExpired(idToken)) {
      // idTokenが期限切れの場合
      const refreshToken = sessionStorage.getItem("refreshToken");
      if (refreshToken) {
        // リフレッシュトークンがある場合、新しいidTokenを取得
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
          cognitoUser.refreshSession(new CognitoRefreshToken({ RefreshToken: refreshToken }), (err, session) => {
            if (err) {
              console.error("リフレッシュトークンの使用に失敗しました", err);
            } else {
              const newIdToken = session.getIdToken().getJwtToken();
              sessionStorage.setItem("idToken", newIdToken); // 新しいidTokenをセッションに保存
              return newIdToken;
            }
          });
        }
      }
    } else {
      // idTokenが有効の場合
      return idToken;
    }
  }
  return null;
};
