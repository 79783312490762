

// import React, { useState, useEffect } from 'react';
// import { Card, CardMedia, CardContent, Typography, CircularProgress } from '@mui/material';
// import axios from 'axios';

// const OGPCard: React.FC<{ url: string }> = ({ url }) => {
//   const [metadata, setMetadata] = useState<any>(null);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchOGPData = async () => {
//       setLoading(true);
//       setError(null);

//       try {
//         const response = await axios.get(`https://z561znwcmb.execute-api.ap-northeast-1.amazonaws.com/default/?url=${encodeURIComponent(url)}`);
//         const data = response.data;
//         setMetadata(data);
//       } catch (err) {
//         setError('メタデータの取得に失敗しました。');
//         setMetadata({ ogUrl: url }); // OGP取得に失敗した場合、URLだけを設定する
//         console.error('Failed to fetch metadata:', err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchOGPData();
//   }, [url]);

//   if (loading) return <CircularProgress />;
  
//   // metadataがnullまたは未定義の場合はデフォルトの表示を行う
//   const ogImage = metadata?.ogImage ?? null;
//   const ogTitle = metadata?.ogTitle ?? 'No title available';
//   const ogDescription = metadata?.ogDescription ?? 'No description available';
//   const ogUrl = metadata?.ogUrl ?? url;

//   return (
//     <Card sx={{ mt: 2, maxWidth: 500 }}>
//       {ogImage && (
//         <CardMedia
//           component="img"
//           image={ogImage}
//           alt={ogTitle}
//           sx={{ height: 140, objectFit: 'contain' }}
//         />
//       )}
//       <CardContent>
//         <Typography
//           gutterBottom
//           variant="h6"
//           component="div"
//           sx={{
//             display: '-webkit-box',
//             WebkitBoxOrient: 'vertical',
//             WebkitLineClamp: 2,
//             overflow: 'hidden',
//             textOverflow: 'ellipsis',
//             maxHeight: '3em',
//           }}
//         >
//           {ogTitle}
//         </Typography>
//         <Typography
//           variant="body2"
//           color="textSecondary"
//           component="p"
//           sx={{
//             display: '-webkit-box',
//             WebkitBoxOrient: 'vertical',
//             WebkitLineClamp: 3,
//             overflow: 'hidden',
//             textOverflow: 'ellipsis',
//             maxHeight: '4.5em',
//           }}
//         >
//           {ogDescription}
//         </Typography>
//         <Typography variant="body2" color="primary" sx={{ mt: 1 }}>
//           <a href={ogUrl} target="_blank" rel="noopener noreferrer">
//             {ogUrl}
//           </a>
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };

// export default OGPCard;


import React, { useState, useEffect } from 'react';
import { Card, CardMedia, CardContent, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';

const OGPCard: React.FC<{ url: string }> = ({ url }) => {
  const [metadata, setMetadata] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOGPData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`https://z561znwcmb.execute-api.ap-northeast-1.amazonaws.com/default/?url=${encodeURIComponent(url)}`);
        const data = response.data;
        setMetadata(data);
      } catch (err) {
        setError('メタデータの取得に失敗しました。');
        setMetadata({ ogUrl: url }); // OGP取得に失敗した場合、URLだけを設定する
        console.error('Failed to fetch metadata:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchOGPData();
  }, [url]);

  if (loading) return <CircularProgress />;
  
  // metadataがnullまたは未定義の場合はデフォルトの表示を行う
  const ogImage = metadata?.ogImage ?? null;
  const ogTitle = metadata?.ogTitle ?? 'No title available';
  const ogDescription = metadata?.ogDescription ?? 'No description available';
  const ogUrl = metadata?.ogUrl ?? url;

  return (
    <Card sx={{ mt: 2, maxWidth: 500 }}>
      {ogImage && (
        <CardMedia
          component="img"
          image={ogImage}
          alt={ogTitle}
          sx={{ height: 140, objectFit: 'contain' }}
        />
      )}
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxHeight: '3em',
          }}
        >
          {ogTitle}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxHeight: '4.5em',
          }}
        >
          {ogDescription}
        </Typography>
        <Typography variant="body2" color="primary" sx={{ mt: 1 }}>
          <a
            href={ogUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()} // イベントの伝播を防ぐ
          >
            {ogUrl}
          </a>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default OGPCard;
