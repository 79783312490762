import axios from 'axios';

const API_ENDPOINT = 'https://kypc7382xh.execute-api.us-east-1.amazonaws.com/default/readlinker-fav-history-handler';

// idTokenをオプショナルに変更
async function callApi(operation: string, data: any, idToken?: string) {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  if (idToken) {
    headers['Authorization'] = `Bearer ${idToken}`;
  }

  try {
    const response = await axios.post(API_ENDPOINT, 
      {
        Operation: operation,
        ...data
      },
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(`API call failed: ${error}`);
    throw error;
  }
}

// 以下の関数を、idTokenが必須かオプショナルかに応じて修正

export async function getFavoriteBooks(userSub: string, idToken: string) {
  return callApi('getFavoriteBooks', { UserSub: userSub }, idToken);
}

export async function getFavoriteBooksDetail(userSub: string, idToken: string) {
  return callApi('getFavoriteBooksDetail', { UserSub: userSub }, idToken);
}

export async function addFavoriteBook(userSub: string, kanriId: string, idToken: string) {
  return callApi('addFavoriteBook', { UserSub: userSub, KANRI_ID: kanriId }, idToken);
}

export async function deleteFavoriteBook(userSub: string, kanriId: string, idToken: string) {
  return callApi('deleteFavoriteBook', { UserSub: userSub, KANRI_ID: kanriId }, idToken);
}

// ファボ数の取得はログイン不要なので、idTokenをオプショナルに
export async function getFavoriteBooksCount(kanriId: string, idToken?: string) {
  return callApi('getFavoriteBooksCount', { KANRI_ID: kanriId }, idToken);
}

export async function getFavoriteAuthors(userSub: string, idToken: string) {
    return callApi('getFavoriteAuthors', { UserSub: userSub }, idToken);
    }

export async function getFavoriteAuthorsDetail(userSub: string, idToken: string) {
    return callApi('getFavoriteAuthorsDetail', { UserSub: userSub }, idToken);
    }

export async function addFavoriteAuthor(userSub: string, tenkyoId: string, idToken: string) {
    return callApi('addFavoriteAuthor', { UserSub: userSub, TENKYO_ID: tenkyoId }, idToken);
    }

export async function deleteFavoriteAuthor(userSub: string, tenkyoId: string, idToken: string) {
    return callApi('deleteFavoriteAuthor', { UserSub: userSub, TENKYO_ID: tenkyoId }, idToken);
    }

export async function getFavoriteAuthorsCount(tenkyoId: string, idToken?: string) {
    return callApi('getFavoriteAuthorsCount', { TENKYO_ID: tenkyoId }, idToken);
    }

export async function getFavoriteNotes(userSub: string, idToken: string) {
    return callApi('getFavoriteNotes', { UserSub: userSub }, idToken);
    }

export async function getFavoriteNotesDetail(userSub: string, idToken: string) {
    return callApi('getFavoriteNotesDetail', { UserSub: userSub }, idToken);
    }

export async function addFavoriteNote(userSub: string, noteId: string, idToken: string) {
    return callApi('addFavoriteNote', { UserSub: userSub, noteId: noteId }, idToken);
    }

export async function deleteFavoriteNote(userSub: string, noteId: string, idToken: string) {
    return callApi('deleteFavoriteNote', { UserSub: userSub, noteId: noteId }, idToken);
    }

export async function getFavoriteNotesCount(noteId: string, idToken?: string) {
    return callApi('getFavoriteNotesCount', { noteId: noteId }, idToken);
    }

export async function getHistoryBooks(userSub: string, idToken: string) {
    return callApi('getHistoryBooks', { UserSub: userSub }, idToken);
    }

export async function getHistoryBooksDetail(userSub: string, idToken: string) {
    return callApi('getHistoryBooksDetail', { UserSub: userSub }, idToken);
    }

export async function addHistoryBook(userSub: string, kanriId: string, idToken: string) {
    return callApi('addHistoryBook', { UserSub: userSub, KANRI_ID: kanriId }, idToken);
    }

export async function deleteHistoryBook(userSub: string, visitedAt: number, idToken: string) {
    console.log('deleteHistoryBook: visitedAt:', visitedAt);
    return callApi('deleteHistoryBook', { UserSub: userSub, visitedAt: visitedAt }, idToken);
    }

