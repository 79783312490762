// //pages/FavoriteBooksPage.tsx

// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';
// import { useFavorites } from '../context/FavoritesContext';
// import { getFavoriteBooksDetail } from '../utils/favoritesAndHistoryApi';
// import { BookDetail } from '../types/types';
// import { generateCoverUrl } from '../utils/generateCoverUrl';
// import { Container, Grid, Card, CardMedia, Typography, Box, CircularProgress } from '@mui/material';

// const FavoriteBooksPage: React.FC = () => {
//   const [favoriteBooks, setFavoriteBooks] = useState<BookDetail[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);
//   const { userSub, idToken } = useAuth();
//   const { state } = useFavorites();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchFavoriteBooks = async () => {
//       if (userSub && idToken) {
//         try {
//           setLoading(true);
//           const response = await getFavoriteBooksDetail(userSub, idToken);
//           // APIレスポンスが文字列の場合はJSONとしてパース
//           const books = typeof response === 'string' ? JSON.parse(response) : response;
//           // booksが配列であることを確認
//           setFavoriteBooks(Array.isArray(books) ? books : []);
//         } catch (error) {
//           console.error('Error fetching favorite books:', error);
//           setError('お気に入りの本の取得に失敗しました。');
//         } finally {
//           setLoading(false);
//         }
//       }
//     };

//     fetchFavoriteBooks();
//   }, [userSub, idToken, state.lastUpdated]);

//   if (loading) {
//     return (
//       <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//         <CircularProgress />
//       </Container>
//     );
//   }

//   if (error) {
//     return (
//       <Container>
//         <Typography color="error">{error}</Typography>
//       </Container>
//     );
//   }

//   return (
//     <Container maxWidth="lg">
//       <Typography variant="h4" component="h1" gutterBottom>
//         お気に入りの本
//       </Typography>
//       {favoriteBooks.length === 0 ? (
//         <Typography>お気に入りの本がありません。</Typography>
//       ) : (
//         <Grid container spacing={3}>
//           {favoriteBooks.map((book) => (
//             <Grid item xs={6} sm={4} md={3} key={book.KANRI_ID}>
//               <Card
//                 onClick={() => navigate(`/manifestation/${book.KANRI_ID}`)}
//                 sx={{
//                   height: '100%',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   cursor: 'pointer',
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   image={generateCoverUrl(book)}
//                   alt={book.MainTitle}
//                   sx={{ height: 200, objectFit: 'contain' }}
//                 />
//                 <Box sx={{ p: 1, flexGrow: 1 }}>
//                   <Typography variant="subtitle1" component="div">
//                     {book.MainTitle}
//                   </Typography>
//                   <Typography variant="body2" color="text.secondary">
//                     {book.Responsibility}
//                   </Typography>
//                   <Typography variant="body2" color="text.secondary">
//                     {book.PublishingDate}
//                   </Typography>
//                 </Box>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       )}
//     </Container>
//   );
// };

// export default FavoriteBooksPage;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useFavorites } from '../context/FavoritesContext';
import { getFavoriteBooksDetail } from '../utils/favoritesAndHistoryApi';
import { BookDetail } from '../types/types';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import { Container, Grid, Card, CardMedia, Typography, Box, CircularProgress } from '@mui/material';

interface FavoriteBook extends BookDetail {
  favAt: string;
}

const FavoriteBooksPage: React.FC = () => {
  const [favoriteBooks, setFavoriteBooks] = useState<FavoriteBook[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { userSub, idToken } = useAuth();
  const { state } = useFavorites();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFavoriteBooks = async () => {
      if (userSub && idToken) {
        try {
          setLoading(true);
          const response = await getFavoriteBooksDetail(userSub, idToken);
          // APIレスポンスが文字列の場合はJSONとしてパース
          const books = typeof response === 'string' ? JSON.parse(response) : response;
          // booksが配列であることを確認
          console.log(books);
          const sortedBooks = Array.isArray(books) 
            ? books.sort((a, b) => Number(b.favAt) - Number(a.favAt))
            : [];
          setFavoriteBooks(sortedBooks);
        } catch (error) {
          console.error('Error fetching favorite books:', error);
          setError('お気に入りの本の取得に失敗しました。');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchFavoriteBooks();
  }, [userSub, idToken, state.lastUpdated]);

  const formatDate = (timestamp: string) => {
    console.log(timestamp);
    const date = new Date(Number(timestamp));
    return date.toLocaleString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        お気に入りの本
      </Typography>
      {favoriteBooks.length === 0 ? (
        <Typography>お気に入りの本がありません。</Typography>
      ) : (
        <Grid container spacing={3}>
          {favoriteBooks.map((book) => (
            <Grid item xs={6} sm={4} md={3} key={book.KANRI_ID}>
              <Card
                onClick={() => navigate(`/manifestation/${book.KANRI_ID}`)}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                }}
              >
                <CardMedia
                  component="img"
                  image={generateCoverUrl(book)}
                  alt={book.MainTitle}
                  sx={{ height: 200, objectFit: 'contain' }}
                />
                <Box sx={{ p: 1, flexGrow: 1 }}>
                  <Typography variant="subtitle1" component="div">
                    {book.MainTitle}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {book.Responsibility}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {book.PublishingDate}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    お気に入り追加: {formatDate(book.favAt)}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default FavoriteBooksPage;