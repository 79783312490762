// src/pages/AboutStickerPage.tsx
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import stickerInstructionImage from '../assets/images/sticker-instruction.png';

const AboutStickerPage: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        ReadLinkerシールの作成方法
      </Typography>
      <Typography variant="body1" paragraph>
        以下のステップに従ってシールを作成してください：
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText
            primary="PDFファイルをダウンロードします。"
            secondary="シールの作成が完了すると、登録されているメールアドレスへDLリンクが送付されます。PDFファイルは複数ページになる場合があります。"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText
            primary="印刷します。"
            secondary={
              <>
                シールの印刷用紙はエーワン製 A-one 31269 をお使いください。<br />
                <Link href="https://www.yodobashi.com/product/100000001000684729/" target="_blank" rel="noopener noreferrer">
                  ヨドバシカメラの販売サイト
                </Link>
                <br />
                <Link href="https://www.biccamera.com/bc/item/1374437/" target="_blank" rel="noopener noreferrer">
                  ビックカメラの販売サイト
                </Link>
                <br />
                <Link href="https://amzn.asia/d/5V4LaBa" target="_blank" rel="noopener noreferrer">
                  Amazonの販売サイト(販売数量を確認してください)
                </Link>
                <br />
                <Link href="https://www.a-one.co.jp/product/search/detail.php?id=31269" target="_blank" rel="noopener noreferrer">
                  エーワン公式サイト
                </Link>
                <br />
                家庭用プリンターでも印刷可能です。印刷する際には用紙サイズはA4、倍率を「100%」に設定してください。
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText
            primary="本に貼ります。"
            secondary="本の裏側などに貼っていただくのが使いやすいです。"
          />
        </ListItem>
      </List>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <img src={stickerInstructionImage} alt="シール作成指示" style={{ maxWidth: '100%', height: 'auto' }} />
      </Box>
    </Container>
  );
}

export default AboutStickerPage;
