// utils/getFilteredUserNotes.ts

import axios from 'axios';
import { Note } from '../types/types';  // Note 型をインポート

const API_URL = 'https://6gxj8t1as1.execute-api.us-east-1.amazonaws.com/default/GetFilteredUserNotes';
const API_URL_OPEN = 'https://6gxj8t1as1.execute-api.us-east-1.amazonaws.com/default/GetFilteredUserNotesOpen';

interface ApiResponse {
  notes: Note[];
}

export const getFilteredUserNotes = async (targetUserSub: string, idToken?: string): Promise<Note[] | null> => {
    console.log('getFilteredUserNotes called');
    console.log('targetUserSub:', targetUserSub);
    console.log('idToken:', idToken);
  try {
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };

    if (idToken) {
      headers['Authorization'] = `Bearer ${idToken}`;
      const response = await axios.get<ApiResponse>(API_URL, {
        headers,
        params: {
          targetUserSub,
        },
      });
      console.log('API response:', response.data);
      return response.data.notes;  // response.data.notes を直接返す

    } else {
      const response = await axios.get<ApiResponse>(API_URL_OPEN, {
        headers,
        params: {
          targetUserSub,
        },
      });
      console.log('API response:', response.data);
      return response.data.notes;  // response.data.notes を直接返す
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Failed to fetch filtered user notes:', error.response?.data || error.message);
    } else {
      console.error('Failed to fetch filtered user notes', error);
    }
    return null;
  }
};