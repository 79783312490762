import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { getHistoryBooksDetail, deleteHistoryBook } from '../utils/favoritesAndHistoryApi';
import { BookDetail } from '../types/types';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import { 
  Container, List, ListItem, ListItemText, Typography, Divider, 
  IconButton, Menu, MenuItem, ListItemSecondaryAction, Box, 
  Card, CardMedia
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface HistoryBook extends BookDetail {
  visitedAt: number;
}

const BrowsingHistoryPage: React.FC = () => {
  const [historyBooks, setHistoryBooks] = useState<HistoryBook[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedBook, setSelectedBook] = useState<HistoryBook | null>(null);
  const { userSub, idToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHistoryBooks = async () => {
      if (userSub && idToken) {
        try {
          const books = await getHistoryBooksDetail(userSub, idToken);
          setHistoryBooks(books);
        } catch (error) {
          console.error('Error fetching history books:', error);
        }
      }
    };

    fetchHistoryBooks();
  }, [userSub, idToken]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, book: HistoryBook) => {
    setAnchorEl(event.currentTarget);
    setSelectedBook(book);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedBook(null);
  };

  const handleDelete = async () => {
    if (selectedBook && userSub && idToken) {
      try {
        const res = await deleteHistoryBook(userSub, selectedBook.visitedAt, idToken);
        console.log('Delete history item response:', res);
        setHistoryBooks(historyBooks.filter(book => book.visitedAt !== selectedBook.visitedAt));
      } catch (error) {
        console.error('Error deleting history item:', error);
      }
    }
    handleMenuClose();
  };

  const groupBooksByDate = (books: HistoryBook[]) => {
    const grouped: { [date: string]: HistoryBook[] } = {};
    books.forEach(book => {
      const date = new Date(Number(book.visitedAt)).toLocaleDateString();
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(book);
    });
    return grouped;
  };

  const groupedBooks = groupBooksByDate(historyBooks);

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        閲覧履歴
      </Typography>
      {Object.entries(groupedBooks).map(([date, books]) => (
        <React.Fragment key={date}>
          <Typography variant="h6" component="h2" gutterBottom>
            {date}
          </Typography>
          <List>
            {books.map((book) => (
              <ListItem 
                key={`${book.KANRI_ID}-${book.visitedAt}`}
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                }}
              >
                <Box display="flex" alignItems="center" width="100%">
                  <Card sx={{ width: 35, height: 40, marginRight: 2, flexShrink: 0 }}>
                    <CardMedia
                      component="img"
                      height="40"
                      image={generateCoverUrl(book)}
                      alt={book.MainTitle}
                      sx={{ objectFit: 'contain' }}
                    />
                  </Card>
                  <a 
                    href={`/manifestation/${book.KANRI_ID}`} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    style={{ textDecoration: 'none', flex: 1, color: 'inherit' }}
                  >
                    <ListItemText
                      primary={book.MainTitle}
                      secondary={`${book.Responsibility} - ${book.PublishingDate}`}
                    />
                  </a>
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={(e) => handleMenuClick(e, book)}>
                      <MoreVertIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </Box>
              </ListItem>
            ))}
          </List>
          <Divider />
        </React.Fragment>
      ))}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDelete}>削除</MenuItem>
      </Menu>
    </Container>
  );
};

export default BrowsingHistoryPage;