// src/pages/PrivacyPolicyPage.tsx

import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div>
      <h1>Welcome to ReadLinker</h1>
    </div>
  );
}

export default PrivacyPolicy;
