// pages/UserBookShelfPage.tsx
import React, { useState, useEffect,useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import '../css/UserBookShelf.css';
import BookDetailDialog from '../components/BookDetailDialog';
import '../App.css';
import { colors } from '@mui/material';

import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';

const UserBookShelf: React.FC = () => {
    const [books, setBooks] = useState<any[]>([]);
    const [shelves, setShelves] = useState<any[]>([]);
    const [selectedShelf, setSelectedShelf] = useState<string>("all");
    const [sortCriteria, setSortCriteria] = useState<string>("newest");
    const [columns, setColumns] = useState<number>(4); // 表示列数を管理する状態
    const [filterText, setFilterText] = useState<string>(''); // フィルタリング用のテキスト
    const { user } = useAuth();
    const [selectedBookID, setSelectedBookID] = useState<string | null>(null);
    const navigate = useNavigate();
    // const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [activeBookCount, setActiveBookCount] = useState(0);
    
    useEffect(() => {
        if (user && user.sub) {
            fetchGlobalBooksLendinginfoByUsersub(user.sub)
                .then(data => setBooks(data));
        }
    }, [user]);

    useEffect(() => {
        if (user && user.sub) {
            getGlobalHondana(user.sub)
                .then(data => {
                    const newShelves = [{ HondanaName: "すべての本", GlobalHondanaID: "all", ColorCode: "#808080" }, ...data]; // デフォルトのグレー色を追加
                    newShelves.sort((a, b) => a.ShelfOrder - b.ShelfOrder);
                    setShelves(newShelves);
                    console.log("shelves: ", newShelves);
                });
        }
    }, [user]);

    const getGlobalHondana = async (userSub: string) => {
        const headers = {
            'Content-Type': 'application/json'
        };

        const body = {
            userSub: userSub,
            Operation: 'GetGlobalHondana'
        };

        try {
            const response = await fetch('https://53wglutnub.execute-api.us-east-1.amazonaws.com/default/v1', {
                method: 'POST',
                headers,
                body: JSON.stringify(body),
            });
            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    };

    const fetchGlobalBooksLendinginfoByUsersub = useCallback(async (userSub: string) => {
        setIsLoading(true); // データ取得開始時にローディングを開始
        const headers = {
            'Content-Type': 'application/json'
        };

        const body = {
            UserSub_LIST: [userSub],
            Operation: 'fetch_GlobalBooks_Lendinginfo_ByUsersub_2'
        };

        try {
            const response = await fetch('https://53wglutnub.execute-api.us-east-1.amazonaws.com/default/v1', {
                method: 'POST',
                headers,
                body: JSON.stringify(body),
            });
            console.log(response);
            const data = await response.json();
            console.log("Fetched data:", data);

            if (data.file_urls && data.file_urls.length > 0) {
                const allBooks = [];
                for (const url of data.file_urls) {
                    const fileResponse = await axios.get(url);
                    allBooks.push(...fileResponse.data);
                }
                return allBooks;
            } else {
                throw new Error('No file URLs returned from the server');
            }
        } catch (error) {
            console.error('Error fetching book data:', error);
            throw error;
        } finally {
            setIsLoading(false); // データ取得完了時にローディングを終了
        }
    }, []);

    // useEffect(() => {
    //     if (user && user.sub) {
    //         fetchGlobalBooksLendinginfoByUsersub(user.sub)
    //             .then(data => {
    //                 setBooks(data);
    //                 // 除籍されていない本の数を計算
    //                 const activeBooks = data.filter((book: any) => !book.RemovedDate);
    //                 setActiveBookCount(activeBooks.length);
    //                 console.log("Total books:", data.length);
    //                 console.log("Active books:", activeBooks.length);
    //             })
    //             .catch(error => console.error('Error setting books:', error));
    //     }
    // }, [user, fetchGlobalBooksLendinginfoByUsersub]);
    useEffect(() => {
        if (user && user.sub) {
            fetchGlobalBooksLendinginfoByUsersub(user.sub)
                .then(data => {
                    setBooks(data);
                    const activeBooks = data.filter((book: any) => !book.RemovedDate);
                    setActiveBookCount(activeBooks.length);
                })
                .catch(error => console.error('Error setting books:', error));
        }
    }, [user, fetchGlobalBooksLendinginfoByUsersub]);
    



    const handleBookClick = (kanriId: string) => {
        window.open(`/manifestation/${kanriId}`, '_blank');
    };

    const handleOpenDialog = (bookID: string) => {
        setSelectedBookID(bookID);
    };

    const handleCloseDialog = () => {
        setSelectedBookID(null);
    };

    const sortBooks = (books: any[]) => {
        switch (sortCriteria) {
            case 'newest':
                return books.sort((a, b) => new Date(b.RegisteredDatetime).getTime() - new Date(a.RegisteredDatetime).getTime());
            case 'oldest':
                return books.sort((a, b) => new Date(a.RegisteredDatetime).getTime() - new Date(b.RegisteredDatetime).getTime());
            case 'genre':
                return books.sort((a, b) => a.ClassMark.localeCompare(b.ClassMark));
            case 'pub-newest':
                return books.sort((a, b) => new Date(b.PublishingDate).getTime() - new Date(a.PublishingDate).getTime());
            case 'pub-oldest':
                return books.sort((a, b) => new Date(a.PublishingDate).getTime() - new Date(b.PublishingDate).getTime());
            default:
                return books;
        }
    };

    // 本棚ごとの冊数をカウントする
    const countBooksByShelf = (shelfID: string) => {
        return books.filter(book => 
            (shelfID === "all" || book.GlobalHondanaID === shelfID) && 
            !book.RemovedDate // 除籍済みの本はカウントしない
        ).length;
    };

    // 現在選択されている本棚のColorCodeを取得する
    const getSelectedShelfColor = () => {
        const selected = shelves.find(shelf => shelf.GlobalHondanaID === selectedShelf);
        return selected ? selected.ColorCode : "#808080"; // デフォルトはグレー
    };

    // カードの高さを設定する関数
    const getCardHeight = () => {
        switch (columns) {
            case 4:
                return '220px';
            case 8:
                return '140px';
            case 12:
                return '80px';
            case 13:
                return '90px';
            default:
                return '220px';
        }
    };

    // カードメディアの高さを設定する関数
    const getCardMediaHeight = () => {
        switch (columns) {
            case 4:
                return '200px';
            case 8:
                return '120px';
            case 12:
                return '60px';
            case 13:
                return '80px';
            default:
                return '200px';
        }
    };

    // 本をフィルタリングする関数
    const filterBooks = (book: any, filterText: string) => {
        const searchText = filterText.toLowerCase();
        const combinedText = [
            book.MainTitle,
            book.TitleOthers,
            book.Responsibility,
            book.ContributorName_01,
            book.ContributorName_02,
            book.ContributorName_03,
            book.ContributorName_04,
            book.ContributorName_05,
            book.ContributorName_06,
            book.ContributorName_07,
            book.ContributorName_08,
            book.ContributorName_09,
            book.ContributorName_10,
            book.Publisher_1,
            book.Publisher_2,
            book.Publisher_3
        ].join(' ').toLowerCase();

        return combinedText.includes(searchText);
    };

    const handleOpenIndividualBookDetail = (globalBookID: string) => {
        window.open(`/dp/${globalBookID}`, '_blank');
    };

    return (
    <Container>
      {isLoading ? (
        <Box sx={{ width: '100%', mt: 4 }}>
          <Typography variant="h6" align="center" gutterBottom>
            本棚を読み込んでいます
          </Typography>
          <LinearProgress />
        </Box>
      ) : user ? (
        <>
          <Typography variant="h4" gutterBottom>
            あなたの本棚（全 {activeBookCount} 冊）
          </Typography>
          <Box sx={{ mt: 2, mb: 2, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'center' }}>
            <FormControl variant="outlined" sx={{ minWidth: 150, mr: 2, mb: { xs: 2, sm: 0 } }}>
              <InputLabel id="sort-select-label">並び替え</InputLabel>
              <Select
                labelId="sort-select-label"
                id="sort-select"
                value={sortCriteria}
                onChange={(e) => setSortCriteria(e.target.value)}
                label="並び替え"
                sx={{ 
                  backgroundColor: 'transparent',
                  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
                  '& .MuiSelect-select': { paddingTop: '10px', paddingBottom: '10px' }
                }}
              >
                <MenuItem value="newest">登録(新しい順)</MenuItem>
                <MenuItem value="oldest">登録(古い順)</MenuItem>
                <MenuItem value="genre">ジャンル順</MenuItem>
                <MenuItem value="pub-newest">出版日(新しい順)</MenuItem>
                <MenuItem value="pub-oldest">出版日(古い順)</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" sx={{ minWidth: 150, mr: 2, mb: { xs: 2, sm: 0 } }}>
              <InputLabel id="columns-select-label">最大表示列</InputLabel>
              <Select
                labelId="columns-select-label"
                id="columns-select"
                value={columns}
                onChange={(e) => setColumns(parseInt(e.target.value.toString() || '4'))}
                label="表示列"
                sx={{ 
                  backgroundColor: 'transparent',
                  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
                  '& .MuiSelect-select': { paddingTop: '10px', paddingBottom: '10px' }
                }}
              >
                <MenuItem value={4}>4列</MenuItem>
                <MenuItem value={8}>8列</MenuItem>
                <MenuItem value={12}>12列</MenuItem>
                <MenuItem value={13}>12列(書影のみ)</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ flex: 1, position: 'relative' }}>
              <TextField
                variant="outlined"
                fullWidth
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="フィルター"
                InputProps={{
                  endAdornment: filterText && (
                    <IconButton onClick={() => setFilterText('')}>
                      <ClearIcon />
                    </IconButton>
                  ),
                  style: { paddingRight: filterText ? '32px' : undefined }
                }}
                sx={{ 
                  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
                  '& .MuiOutlinedInput-root': { 
                    paddingRight: '0px', 
                    '&.Mui-focused fieldset': { border: '1px solid' }
                  }
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: '0 0 25%', position: 'sticky', top: '20px', height: 'calc(100vh - 40px)', overflowY: 'auto' }}>
              <List component="nav">
                {shelves.map(shelf => (
                  <ListItem key={shelf.GlobalHondanaID} disablePadding>
                    <ListItemButton
                      onClick={() => setSelectedShelf(shelf.GlobalHondanaID)}
                      sx={{
                        backgroundColor: selectedShelf === shelf.GlobalHondanaID ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                        boxShadow: selectedShelf === shelf.GlobalHondanaID ? 4 : 0,
                        transition: '0.3s',
                      }}
                    >
                      <ListItemText 
                        primary={
                          <>
                            {shelf.HondanaName} 
                            <Typography component="span" variant="body2" color="textSecondary">
                              {` (${countBooksByShelf(shelf.GlobalHondanaID)}冊)`}
                            </Typography>
                          </>
                        } 
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
                        <Box 
                            sx={{ 
                                flex: '1 1 75%', 
                                overflowY: 'auto', 
                                height: 'calc(100vh - 20px)',
                                position: 'relative' // 位置を相対的に
                            }}
                        >
                            <Grid container spacing={2} columns={columns === 13 ? 12 : columns}> {/* 12列(書影のみ) を処理 */}
                                {sortBooks(books
                                    .filter(book => book != null)
                                    .filter(book => 
                                        (selectedShelf === "all" || book.GlobalHondanaID === selectedShelf || book.HondanaName === selectedShelf) && 
                                        !book.RemovedDate // 除籍済みの本を表示しない
                                    )
                                    .filter(book => 
                                        filterText === '' || filterBooks(book, filterText)
                                    )
                                ).map((book, index) => (
                                    <Grid item xs={6} sm={4} md={3} lg={2} xl={1} key={book.GlobalBookID}>
                                        <Box>
                                            <Card
                                                onClick={() => handleBookClick(book.KANRI_ID)}
                                                sx={{
                                                    transition: '0.3s',
                                                    boxShadow: columns === 13 ? 'none' : 1,
                                                    '&:hover': {
                                                        boxShadow: columns === 13 ? 'none' : 4,
                                                    },
                                                    overflow: 'hidden',
                                                    borderRadius: columns === 13 ? '2px' : '8px',
                                                    height: getCardHeight(), // カードの高さを動的に設定
                                                    cursor: 'pointer',
                                                    backgroundColor: columns === 13 ? 'transparent' : getSelectedShelfColor(), // カードの背景色を透明に設定
                                                    padding: columns === 13 ? '2px' : '10px', // カードの内側に余白を追加して背景色を表示
                                                    boxSizing: 'border-box' // パディングを含めた高さを考慮
                                                }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    image={generateCoverUrl(book)}
                                                    alt={`Cover for ${book.MainTitle}`}
                                                    sx={{ 
                                                        objectFit: 'contain', 
                                                        height: getCardMediaHeight(), // 書影の高さを動的に設定
                                                        width: '100%', 
                                                        backgroundColor: columns === 13 ? 'transparent' :  getSelectedShelfColor(), // 書影の背景色もカードと同じに
                                                        borderRadius: '4px' // 書影の角を少し丸める
                                                    }}
                                                />
                                            </Card>
                                            {/* 12列(書影のみ) 以外の場合のみタイトルと貸出状況を表示 */}
                                            {columns !== 13 && (
                                                <Box sx={{ mt: 1, textAlign: 'left', minHeight: '72px' }}>
                                                    <Typography 
                                                        variant="body2" 
                                                        color="textPrimary" 
                                                        component="p"
                                                        sx={{
                                                            display: '-webkit-box',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: 3,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            minHeight: '60px' // 4行分の高さを固定
                                                        }}
                                                    >
                                                        {book.MainTitle}
                                                    </Typography>
                                                    <Typography 
                                                        variant="caption" 
                                                        color="textSecondary" 
                                                        component="p"
                                                        sx={{
                                                            display: '-webkit-box',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: 1,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            minHeight: '16px' // 1行分の高さを固定
                                                        }}
                                                        >
                                                        {book.Responsibility}
                                                        </Typography>
                                                        <Typography 
                                                        variant="caption" 
                                                        color="textSecondary" 
                                                        component="p"
                                                        sx={{
                                                            display: '-webkit-box',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: 1,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            minHeight: '16px' // 1行分の高さを固定
                                                        }}
                                                        >
                                                        {book.PublishingDate}
                                                        </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        color="primary"
                                                        component="p"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenDialog(book.GlobalBookID);
                                                        }}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            color: book.LendingStatus
                                                            === "Lending" ? '#FB8C00' : '#64B5F6' 
                                                            }}
                                                        >
                                                        {book.LendingStatus === "Lending" ? '貸出中' : '貸出可'}
                                                    </Typography>
                                                    <Typography
                                                    variant="caption"
                                                    color="primary"
                                                    component="p"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleOpenIndividualBookDetail(book.GlobalBookID);
                                                    }}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        color: '#1976D2', // プライマリーカラーを使用
                                                        textDecoration: 'underline'
                                                    }}
                                                >
                                                    個別資料詳細
                                                </Typography>

                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </>
            ) : (
                <Box sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
                    <Typography variant="body1">本棚を表示するにはログインが必要です。</Typography>
                </Box>
            )}
            {selectedBookID && (
                <BookDetailDialog
                    open={Boolean(selectedBookID)}
                    onClose={handleCloseDialog}
                    globalBookID={selectedBookID}
                />
            )}
        </Container>
    );
};

export default UserBookShelf;

