// src/pages/RegisterPage.tsx

import React from 'react';

const RegisterPage: React.FC = () => {
    return (
        <div>
            <h1>Welcome to ReadLinker</h1>
        </div>
    );
}

export default RegisterPage;
