//utils/listHandler.ts

import axios from 'axios';

const API_ENDPOINT = 'https://oybjebb2td.execute-api.us-east-1.amazonaws.com/default/readlinker-list-handler';

// idTokenをオプショナルに変更
async function callGetApi(operation: string, params: any, idToken?: string) {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };
  
    if (idToken) {
      headers['Authorization'] = `Bearer ${idToken}`;
    }
  
    try {
      const response = await axios.get(API_ENDPOINT, {
        params: {
          OperationType: operation,
          ...params
        },
        headers
      });
      return response.data;
    } catch (error) {
      console.error(`API call failed: ${error}`);
      throw error;
    }
  }
  

async function callPostApi(operation: string, data: any, idToken?: string) {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };
  
    if (idToken) {
      headers['Authorization'] = `Bearer ${idToken}`;
    }
  
    try {
      const response = await axios.post(API_ENDPOINT, 
        {
          Operation: operation,
          ...data
        },
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error(`API call failed: ${error}`);
      throw error;
    }
  }



  export async function getBooksInList(bookListID: string, idToken?: string) {
    return callGetApi('getBooksInList', { bookListID }, idToken);
  }
  
  export async function getBooksInListDetail(bookListID: string, idToken: string) {
    return callGetApi('getBooksInListDetail', { bookListID }, idToken);
  }
  
  export async function getListOfBookList(idToken: string) {
    return callGetApi('getListOfBookList', {}, idToken);
  }
  
  export async function getBooksInList_ListOfBookList(idToken: string) {
    return callGetApi('getBooksInList_ListOfBookList', {}, idToken);
  }
  
  export async function getBooksInListDetail_ListOfBookList(idToken: string) {
    return callGetApi('getBooksInListDetail_ListOfBookList', {}, idToken);
  }



export async function addBooksInList(bookListID: string, KANRI_ID: string, idToken: string) {
    return callPostApi('addBooksInList', { bookListID: bookListID, KANRI_ID: KANRI_ID }, idToken);
    }

export async function deleteBooksInList(bookListID: string, KANRI_ID: string, idToken: string) {
    return callPostApi('deleteBooksInList', { bookListID: bookListID, KANRI_ID: KANRI_ID }, idToken);
    }

export async function moveBooksInList(bookListID: string, KANRI_ID: string, newBookListID: string, idToken: string) {
    return callPostApi('moveBooksInList', { bookListID: bookListID, KANRI_ID: KANRI_ID, newBookListID: newBookListID }, idToken);
    }

export async function addListOfBookList(listName: string, comments: string, openLevel: string, listOrder: number, idToken: string) {
    return callPostApi('addListOfBookList', { listName: listName, comments: comments, openLevel: openLevel, listOrder: listOrder }, idToken);
    }

export async function deleteListOfBookList(userSub: string, bookListID: string, idToken: string) {
    return callPostApi('deleteListOfBookList', { UserSub: userSub, bookListID: bookListID }, idToken);
    }

export async function updateListOfBookList(bookListID:string, listName: string, comments: string, openLevel: string, listOrder: number, idToken: string) {
    return callPostApi('updateListOfBookList', { bookListID:bookListID, listName: listName, comments: comments, openLevel: openLevel, listOrder: listOrder }, idToken);
    }
