// src/utils/noteHandler.ts

import axios from 'axios';

const API_URL = 'https://95seh68s4g.execute-api.us-east-1.amazonaws.com/default/notes';



interface NoteArgs {
    userSub?: string;
    content?: string;
    noteID?: string;
    parentNoteID?: string;
    referenceType?: string;
    referenceTargetKANRI_ID?: string;
    referenceTargetClassLevel?: string;
    referenceSourceKANRI_ID?: string;
    referenceSourceClassLevel?: string;
    referenceTargetTENKYO_ID?: string;
    additionalTENKYO_ID?: string;
    additionalTENKYO_type?: string;
    metaDescription?: string;
    noteOpenLevel?: string;
    allowReplyLevel?: string;
}

interface HandleNoteOperationArgs extends NoteArgs {
    Operation: 
    'CreateExplainNote' 
    | 'EditExplainNote' 
    | 'DeleteExplainNote' 
    | 'CreateImpressionNote' 
    | 'EditImpressionNote' 
    | 'DeleteImpressionNote'
    | 'CreateInformationNote'
    | 'EditInformationNote'
    | 'DeleteInformationNote'
    | 'GetNote'
    | 'GetNoteOpenEndpoint'
}

const removeNullValues = (obj: any) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

const CreateExplainNote = async (args: NoteArgs, idToken: string) => {
    console.log('CreateExplainNote');
    const data = removeNullValues({
        Operation: 'CreateExplainNote',
        ...args
    });

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch book detail:', error);
        return null;
    }
};

const EditExplainNote = async (args: NoteArgs, idToken: string) => {
    console.log('EditExplainNote');
    const data = removeNullValues({
        Operation: 'EditExplainNote',
        ...args
    });

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch book detail:', error);
        return null;
    }
}

const DeleteExplainNote = async (args: NoteArgs, idToken: string) => {
    console.log('DeleteExplainNote');
    const data = removeNullValues({
        Operation: 'DeleteExplainNote',
        ...args
    });

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch book detail:', error);
        return null;
    }
}

const CreateImpressionNote = async (args: NoteArgs, idToken: string) => {
    console.log('CreateImpressionNote');
    const data = removeNullValues({
        Operation: 'CreateImpressionNote',
        ...args
    });

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch book detail:', error);
        return null;
    }
}

const EditImpressionNote = async (args: NoteArgs, idToken: string) => {
    console.log('EditImpressionNote');
    const data = removeNullValues({
        Operation: 'EditImpressionNote',
        ...args
    });

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch book detail:', error);
        return null;
    }
}

const DeleteImpressionNote = async (args: NoteArgs, idToken: string) => {
    console.log('DeleteImpressionNote');
    const data = removeNullValues({
        Operation: 'DeleteImpressionNote',
        ...args
    });

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch book detail:', error);
        return null;
    }
}

const CreateInformationNote = async (args: NoteArgs, idToken: string) => {
    console.log('CreateInformationNote');
    const data = removeNullValues({
        Operation: 'CreateInformationNote',
        ...args
    });

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch book detail:', error);
        return null;
    }
}

const EditInformationNote = async (args: NoteArgs, idToken: string) => {
    console.log('EditInformationNote');
    const data = removeNullValues({
        Operation: 'EditInformationNote',
        ...args
    });

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch book detail:', error);
        return null;
    }
}

const DeleteInformationNote = async (args: NoteArgs, idToken: string) => {
    console.log('DeleteInformationNote');
    const data = removeNullValues({
        Operation: 'DeleteInformationNote',
        ...args
    });

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch book detail:', error);
        return null;
    }
}

// 新しい関数：ノートの詳細を取得する
const GetNote = async (args: NoteArgs, idToken?: string) => {
    const data = {
        Operation: 'GetNote',
        ...args
    };

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
    };

    try {
        const response = await axios.post(API_URL, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch note detail:', error);
        return null;
    }
};

const GetNoteOpenEndpoint = async (args: NoteArgs) => {
    const data = {
        Operation: 'GetNoteOpenEndpoint',
        ...args
    };

    console.log('GetNoteOpenEndpoint data:', data);

    const headers = {
        'Content-Type': 'application/json',
    };

    const API_URL_OPEN = ' https://95seh68s4g.execute-api.us-east-1.amazonaws.com/default/note-open'

    try {
        const response = await axios.post(API_URL_OPEN, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch note detail:', error);
        return null;
    }
}


const handleNoteOperation = async (args: HandleNoteOperationArgs, idToken: string) => {
    const { Operation, ...rest } = args;
    switch (Operation) {
        case 'CreateExplainNote':
            return await CreateExplainNote(rest, idToken);
        case 'EditExplainNote':
            return await EditExplainNote(rest, idToken);
        case 'DeleteExplainNote':
            return await DeleteExplainNote(rest, idToken);
        case 'CreateImpressionNote':
            return await CreateImpressionNote(rest, idToken);
        case 'EditImpressionNote':
            return await EditImpressionNote(rest, idToken);
        case 'DeleteImpressionNote':
            return await DeleteImpressionNote(rest, idToken);
        case 'CreateInformationNote':
            return await CreateInformationNote(rest, idToken);
        case 'EditInformationNote':
            return await EditInformationNote(rest, idToken);
        case 'DeleteInformationNote':
            return await DeleteInformationNote(rest, idToken);
        case 'GetNote':
            return await GetNote(rest, idToken);
        case 'GetNoteOpenEndpoint':
            return await GetNoteOpenEndpoint(rest);
        default:
            throw new Error(`Unknown Operation: ${Operation}`);
    }
};

export { 
    handleNoteOperation, 
    CreateExplainNote, 
    EditExplainNote, 
    DeleteExplainNote, 
    CreateImpressionNote, 
    EditImpressionNote, 
    DeleteImpressionNote ,
    CreateInformationNote,
    EditInformationNote,
    DeleteInformationNote,
    GetNote,
    GetNoteOpenEndpoint
};