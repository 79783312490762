// import React, { useEffect, useState } from 'react';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import Button from '@mui/material/Button';
// import Card from '@mui/material/Card';
// import CardMedia from '@mui/material/CardMedia';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import { fetch_lending_history_bookdetail_hondana_by_GlobalBookID } from '../utils/fetch_lending_history_bookdetail_hondana_by_GlobalBookID';
// import { generateCoverUrl } from '../utils/generateCoverUrl';
// import { biblio_for_react_authorized_test } from '../utils/biblio-for-react-authorized';
// import { getIdToken } from '../utils/getIdToken';

// interface BookDetailDialogProps {
//     open: boolean;
//     onClose: () => void;
//     globalBookID: string;
// }

// interface LendingHistoryType {
//     LendingID: string;
//     BorrowerUserSub: string | null;
//     LentDate: string;
//     ReturnDate: string;
//     ActualReturnDate: string | null;
//     Email: string | null;
//     Name: string | null;
//     NotificationFrequency: string | null;
// }

// const BookDetailDialog: React.FC<BookDetailDialogProps> = ({ open, onClose, globalBookID }) => {
//     const idToken = getIdToken();
//     const [bookDetail, setBookDetail] = useState<any | null>(null);
//     const [bookDetailAuthedUser, setBookDetailAuthedUser] = useState<any | null>(null);

//     useEffect(() => {
//         if (!globalBookID) return;
//         const fetchData = async () => {
//             if (idToken) {
//                 const data = await fetch_lending_history_bookdetail_hondana_by_GlobalBookID(globalBookID);
//                 if (data && data['Book_Simple']) {
//                     setBookDetail(data['Book_Simple']);
//                     const res = await biblio_for_react_authorized_test(globalBookID, idToken);
//                     if (res && res['Book_Simple']) {
//                         setBookDetailAuthedUser(res);
//                     }
//                 }
//             } else {
//                 const data = await fetch_lending_history_bookdetail_hondana_by_GlobalBookID(globalBookID);
//                 if (data && data['Book_Simple']) {
//                     setBookDetail(data['Book_Simple']);
//                 }
//                 const res = await biblio_for_react_authorized_test(globalBookID);
//                 if (res && res['Book_Simple']) {
//                     setBookDetailAuthedUser(res);
//                 }
//             }
//         };
//         fetchData();
//     }, [globalBookID, idToken]);

//     return (
//         <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
//             <DialogTitle>貸し出し状況</DialogTitle>
//             <DialogContent>
//                 {bookDetail && (
//                     <Grid container spacing={4}>
//                         <Grid item xs={12} md={4}>
//                             <Card>
//                                 <CardMedia
//                                     component="img"
//                                     height="auto"
//                                     image={generateCoverUrl(bookDetail)}
//                                     alt={`Cover for ${bookDetail.MainTitle}`}
//                                 />
//                             </Card>
//                         </Grid>
//                         <Grid item xs={12} md={8}>
//                             {bookDetailAuthedUser && (
//                                 <Box>
//                                     {bookDetailAuthedUser.LendingHistory && bookDetailAuthedUser.LendingHistory.length > 0 ? (
//                                         <Card>
//                                             <CardContent>
//                                                 <Typography variant="h6">貸出履歴</Typography>
//                                                 {bookDetailAuthedUser.LendingHistory.map((lending: LendingHistoryType, index: number) => (
//                                                     <Box key={index} sx={{ mb: 2 }}>
//                                                         <Typography variant="body2" color="textSecondary">
//                                                             <strong>貸出ID:</strong> {lending.LendingID}
//                                                         </Typography>
//                                                         <Typography variant="body2" color="textSecondary">
//                                                             <strong>貸出日:</strong> {lending.LentDate}
//                                                         </Typography>
//                                                         <Typography variant="body2" color="textSecondary">
//                                                             <strong>返却予定日:</strong> {lending.ReturnDate}
//                                                         </Typography>
//                                                         <Typography variant="body2" color="textSecondary">
//                                                             <strong>実際の返却日:</strong> {lending.ActualReturnDate}
//                                                         </Typography>
//                                                         <Box sx={{ borderBottom: 1, borderColor: 'divider', my: 1 }} />
//                                                     </Box>
//                                                 ))}
//                                             </CardContent>
//                                         </Card>
//                                     ) : (
//                                         <Typography variant="body2" color="textSecondary">
//                                             履歴がありません
//                                         </Typography>
//                                     )}
//                                 </Box>
//                             )}
//                         </Grid>
//                     </Grid>
//                 )}
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={onClose} color="primary">閉じる</Button>
//             </DialogActions>
//         </Dialog>
//     );
// };

// export default BookDetailDialog;




import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { fetch_lending_history_bookdetail_hondana_by_GlobalBookID } from '../utils/fetch_lending_history_bookdetail_hondana_by_GlobalBookID';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import { biblio_for_react_authorized_test } from '../utils/biblio-for-react-authorized';
import { getIdToken } from '../utils/getIdToken';

interface BookDetailDialogProps {
    open: boolean;
    onClose: () => void;
    globalBookID: string;
}

interface LendingHistoryType {
    LendingID: string;
    BorrowerUserSub: string | null;
    LentDate: string;
    ReturnDate: string;
    ActualReturnDate: string | null;
    Email: string | null;
    Name: string | null;
    NotificationFrequency: string | null;
}

const BookDetailDialog: React.FC<BookDetailDialogProps> = ({ open, onClose, globalBookID }) => {
    const idToken = getIdToken();
    const [bookDetail, setBookDetail] = useState<any | null>(null);
    const [bookDetailAuthedUser, setBookDetailAuthedUser] = useState<any | null>(null);

    useEffect(() => {
        if (!globalBookID) return;
        const fetchData = async () => {
            if (idToken) {
                const data = await fetch_lending_history_bookdetail_hondana_by_GlobalBookID(globalBookID);
                if (data && data['Book_Simple']) {
                    setBookDetail(data['Book_Simple']);
                    const res = await biblio_for_react_authorized_test(globalBookID, idToken);
                    if (res && res['Book_Simple']) {
                        const sortedLendingHistory = res.LendingHistory.sort((a: LendingHistoryType, b: LendingHistoryType) => {
                            return new Date(b.LentDate).getTime() - new Date(a.LentDate).getTime();
                        });
                        setBookDetailAuthedUser({ ...res, LendingHistory: sortedLendingHistory });
                    }
                }
            } else {
                const data = await fetch_lending_history_bookdetail_hondana_by_GlobalBookID(globalBookID);
                if (data && data['Book_Simple']) {
                    setBookDetail(data['Book_Simple']);
                }
                const res = await biblio_for_react_authorized_test(globalBookID);
                if (res && res['Book_Simple']) {
                    const sortedLendingHistory = res.LendingHistory.sort((a: LendingHistoryType, b: LendingHistoryType) => {
                        return new Date(b.LentDate).getTime() - new Date(a.LentDate).getTime();
                    });
                    setBookDetailAuthedUser({ ...res, LendingHistory: sortedLendingHistory });
                }
            }
        };
        fetchData();
    }, [globalBookID, idToken]);

    const getPublisher = (bookDetail: any) => {
        const publishers = [bookDetail.Publisher_1, bookDetail.Publisher_2, bookDetail.Publisher_3].filter(Boolean);
        return publishers.join(' ');
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>貸し出し状況</DialogTitle>
            <DialogContent>
                {bookDetail && bookDetailAuthedUser && (
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="auto"
                                    image={generateCoverUrl(bookDetail)}
                                    alt={`Cover for ${bookDetail.MainTitle}`}
                                    sx={{ width: 'auto', height: '300px', objectFit: 'contain' , padding: '15px', margin: '16px',}}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {/* <Card> */}
                                <CardContent>
                                    <Typography variant="h6">{bookDetail.MainTitle}</Typography>
                                    <Typography variant="body2" color="textSecondary">{getPublisher(bookDetail)}</Typography>
                                    <Typography variant="body2" color="textSecondary">{bookDetail.Responsibility}</Typography>
                                    <Typography variant="body2" color="textSecondary">　</Typography>
                                    <Typography variant="body2" color="textSecondary">図書館名: {bookDetailAuthedUser.UserLibrarySettings.LibraryName}</Typography>
                                    <Typography variant="body2" color="textSecondary">本棚名: {bookDetailAuthedUser.GlobalHondana.HondanaName}</Typography>
                                    <Typography variant="body2" color="textSecondary">セルフ貸出: {bookDetailAuthedUser.UserLibrarySettings.AllowSelfLend === "ALLOW" ? "可" : "不可"}</Typography>
                                </CardContent>
                            {/* </Card> */}
                            {bookDetailAuthedUser.LendingHistory && bookDetailAuthedUser.LendingHistory.length > 0 ? (
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6">貸出履歴</Typography>
                                        {bookDetailAuthedUser.LendingHistory.map((lending: LendingHistoryType, index: number) => (
                                            <Box key={index} sx={{ mb: 2 }}>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>貸出ID:</strong> {lending.LendingID}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>貸出日:</strong> {lending.LentDate}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>返却予定日:</strong> {lending.ReturnDate}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    <strong>実際の返却日:</strong> {lending.ActualReturnDate}
                                                </Typography>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider', my: 1 }} />
                                            </Box>
                                        ))}
                                    </CardContent>
                                </Card>
                            ) : (
                                <Typography variant="body2" color="textSecondary">
                                    貸出履歴がありません
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">閉じる</Button>
            </DialogActions>
        </Dialog>
    );
};

export default BookDetailDialog;
