// utils/userFriendsHandle.ts

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://p19y7kh7qa.execute-api.us-east-1.amazonaws.com/default/readlinker-user-friendslist-handler';

interface ApiResponse {
    followerUserSub?: string;
    followedUserSub?: string;
    createdAt?: string;
}

interface BlockApiResponse {
    blockerUserSub?: string;
    blockedUserSub?: string;
    createdAt?: string;
}

interface FriendRequestResponse {
    requestId: string;
    senderUserSub: string;
    receiverUserSub: string;
    status: string;
    createdAt: string;
}

// フォロー追加関数
export const addFollower = async (followerUserSub: string, followedUserSub: string, idToken?: string): Promise<ApiResponse | null> => {
    console.log('addFollower called');

    const data = {
        Operation: 'addFollower',
        followerUserSub: followerUserSub,
        followedUserSub: followedUserSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to add Friend:', error.response?.data || error.message);
        } else {
            console.error('Failed to add Friend', error);
        }
        return null;
    }
};

// フォロー削除関数
export const deleteFollower = async (followerUserSub: string, followedUserSub: string, idToken?: string): Promise<ApiResponse | null> => {
    console.log('deleteFollower called');

    const data = {
        Operation: 'deleteFollower',
        followerUserSub: followerUserSub,
        followedUserSub: followedUserSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to delete Friend:', error.response?.data || error.message);
        } else {
            console.error('Failed to delete Friend', error);
        }
        return null;
    }
};

// ユーザーがフォローしているユーザーを取得する関数
export const getUserFollowUsers = async (followerUserSub: string, idToken?: string): Promise<ApiResponse[] | null> => {
    console.log('getUserFollowUsers called');

    const data = {
        Operation: 'getUserFollowUsers',
        followerUserSub: followerUserSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to get follow users:', error.response?.data || error.message);
        } else {
            console.error('Failed to get follow users', error);
        }
        return null;
    }
};

// ユーザーをフォローしているユーザーを取得する関数
export const getUserFollowerUsers = async (followedUserSub: string, idToken?: string): Promise<ApiResponse[] | null> => {
    console.log('getUserFollowerUsers called');

    const data = {
        Operation: 'getUserFollowerUsers',
        followedUserSub: followedUserSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to get follower users:', error.response?.data || error.message);
        } else {
            console.error('Failed to get follower users', error);
        }
        return null;
    }
};

// ブロック関数
export const blockUser = async (blockerUserSub: string, blockedUserSub: string, idToken?: string): Promise<BlockApiResponse | null> => {
    console.log('blockUser called');

    const data = {
        Operation: 'blockUser',
        blockerUserSub: blockerUserSub,
        blockedUserSub: blockedUserSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to block User:', error.response?.data || error.message);
        } else {
            console.error('Failed to block User', error);
        }
        return null;
    }
};

// ブロック解除関数
export const unblockUser = async (blockerUserSub: string, blockedUserSub: string, idToken?: string): Promise<BlockApiResponse | null> => {
    console.log('unblockUser called');

    const data = {
        Operation: 'unblockUser',
        blockerUserSub: blockerUserSub,
        blockedUserSub: blockedUserSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to unblock User:', error.response?.data || error.message);
        } else {
            console.error('Failed to unblock User', error);
        }
        return null;
    }
};

export const checkBlockStatus = async (blockerUserSub: string, blockedUserSub: string, idToken?: string): Promise<boolean> => {
    console.log('checkBlockStatus called');

    const data = {
        Operation: 'checkBlockStatus',
        blockerUserSub: blockerUserSub,
        blockedUserSub: blockedUserSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('API response:', response.data);
        return response.data.isBlocked; // API のレスポンスに応じて修正
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to check block status:', error.response?.data || error.message);
        } else {
            console.error('Failed to check block status', error);
        }
        return false;
    }
};

// 友達リクエストを送る関数
export const sendFriendRequest = async (senderUserSub: string, receiverUserSub: string, idToken?: string): Promise<ApiResponse | null> => {
    console.log('sendFriendRequest called');

    const data = {
        Operation: 'sendFriendRequest',
        senderUserSub: senderUserSub,
        receiverUserSub: receiverUserSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to send Friend Request:', error.response?.data || error.message);
        } else {
            console.error('Failed to send Friend Request', error);
        }
        return null;
    }
};

// 自分宛て友達リクエストの一覧を取得する関数
export const getReceivedFriendRequests = async (receiverUserSub: string, idToken?: string): Promise<FriendRequestResponse[] | null> => {
    console.log('getReceivedFriendRequests called');

    const data = {
        Operation: 'getReceivedFriendRequests',
        receiverUserSub: receiverUserSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to get received Friend Requests:', error.response?.data || error.message);
        } else {
            console.error('Failed to get received Friend Requests', error);
        }
        return null;
    }
};

// 友達リクエストを承認または拒否する関数
export const handleFriendRequest = async (requestId: string, action: 'accept' | 'reject', idToken?: string): Promise<ApiResponse | null> => {
    console.log('handleFriendRequest called');

    const data = {
        Operation: 'handleFriendRequest',
        requestId: requestId,
        action: action,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to handle Friend Request:', error.response?.data || error.message);
        } else {
            console.error('Failed to handle Friend Request', error);
        }
        return null;
    }
};

// 友達関係を削除する関数
export const deleteFriend = async (user1: string, user2: string, idToken?: string): Promise<ApiResponse | null> => {
    console.log('deleteFriend called');

    const data = {
        Operation: 'deleteFriend',
        user1: user1,
        user2: user2,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to delete Friend:', error.response?.data || error.message);
        } else {
            console.error('Failed to delete Friend', error);
        }
        return null;
    }
};


// 友達リクエストを送付済みかどうか確認する
export const checkFriendRequestStatus = async (senderUserSub: string, receiverUserSub: string, idToken?: string): Promise<FriendRequestResponse | null> => {
    console.log('checkFriendRequestStatus called');

    const data = {
        Operation: 'checkFriendRequestStatus',
        senderUserSub: senderUserSub,
        receiverUserSub: receiverUserSub,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('checkFriendRequestStatus API response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to check Friend Request status:', error.response?.data || error.message);
        } else {
            console.error('Failed to check Friend Request status', error);
        }
        return null;
    }
};

// React utility function for checking friend status
export const checkFriendStatus = async (user1: string, user2: string, idToken?: string): Promise<boolean> => {
    console.log('checkFriendStatus called');

    const data = {
        Operation: 'checkFriendStatus',
        user1: user1,
        user2: user2,
    };

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('checkFriendStatus API response:', response.data);
        
        // レスポンスの isFriend フィールドを返す
        return response.data.isFriend;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to check Friend status:', error.response?.data || error.message);
        } else {
            console.error('Failed to check Friend status', error);
        }
        return false; // エラーが発生した場合は false を返す
    }
}
