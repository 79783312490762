//OtherUsersBookShelfPage.tsx

import React, { useState, useEffect, useCallback ,useMemo} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import BookDetailDialog from '../components/BookDetailDialog';
import axios from 'axios';

import { getUserSubFromUsernameOpenEndpoint } from '../utils/getUserSubFromUsername';

import LinearProgress from '@mui/material/LinearProgress';

interface Shelf {
    HondanaName: string;
    GlobalHondanaID: string;
    ColorCode: string;
    ShelfOrder: number;
}

interface Book {
    global_book: {
        GlobalBookID: string;
        KANRI_ID: string;
        GlobalHondanaID: string;
        BookOpenLevel: string;
        RegisteredDate: string;
        RemovedDate: string | null;
    };
    book_details: {
        MainTitle: string;
        ISBN: string;
        Responsibility: string;
        PublishingDate: string;
    };
    global_hondana: {
        GlobalHondanaID: string;  // この行を追加
        HondanaName: string;
        ColorCode: string;
        OpenLevel: string;  // この行を追加
        UserSub: string;  // この行を追加
        ShelfOrder: number;  // この行を追加
        Description: string | null;  // この行を追加
    };
    lending_status: string;
}


  const OtherUsersBookshelfPage: React.FC = () => {
    const { userNameOnURL } = useParams<{ userNameOnURL: string }>();
    const [books, setBooks] = useState<Book[]>([]);
    const [shelves, setShelves] = useState<Shelf[]>([]);
    const [selectedShelf, setSelectedShelf] = useState<string>("all");
    const [sortCriteria, setSortCriteria] = useState<string>("newest");
    const [filterText, setFilterText] = useState<string>('');
    const { idToken } = useAuth();
    const [selectedBookID, setSelectedBookID] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();


    // const fetchUserBookshelfData = useCallback(async () => {
    //     setIsLoading(true);
    //     setError(null);
    //     try {
    //         const targetUserSub = await getUserSubFromUsernameOpenEndpoint(userNameOnURL || '');
    //         if (!targetUserSub) {
    //             setError('Failed to fetch user information');
    //             return;
    //         }
    
    //         const apiEndpoint = 'https://zejgdrpbj1.execute-api.us-east-1.amazonaws.com/v1/readlinker-book-handler-speed';
    //         const headers: { [key: string]: string } = {};
    //         if (idToken) {
    //             headers['Authorization'] = `Bearer ${idToken}`;
    //         }
    
    //         const response = await axios.get(apiEndpoint, {
    //             headers: headers,
    //             params: {
    //                 OperationType: 'fetch_GlobalBooks_Lendinginfo_ByUsersub',
    //                 UserSub: targetUserSub
    //             }
    //         });
    
    //         if (response.status === 200) {
    //             const data = response.data;
    //             setBooks(data);
    //             createShelvesFromBooks(data);
    //         } else {
    //             setError('Failed to fetch bookshelf data');
    //         }
            
    //         //fetch_GlobalBooks_Lendinginfo_ByUsersub_2は、バッチ処理したjsonをS3にアップロードし、そのURLを返す関数。
    //         const response_2 = await axios.get(apiEndpoint, {
    //             headers: headers,
    //             params: {
    //                 OperationType: 'fetch_GlobalBooks_Lendinginfo_ByUsersub_2',
    //                 UserSub: targetUserSub
    //             }
    //         });
    //         if (response_2){
    //         console.log(response_2); //ここでの結果を貼ります
    //     }
    //     } catch (err) {
    //         setError('An error occurred while fetching the bookshelf data');
    //     } finally {
    //         setIsLoading(false);
    //     }
    // }, [userNameOnURL, idToken]);

    const [totalBooks, setTotalBooks] = useState<number>(0);

    const fetchUserBookshelfData = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const targetUserSub = await getUserSubFromUsernameOpenEndpoint(userNameOnURL || '');
            if (!targetUserSub) {
                setError('Failed to fetch user information');
                return;
            }
    
            const apiEndpoint = 'https://zejgdrpbj1.execute-api.us-east-1.amazonaws.com/v1/readlinker-book-handler-speed';
            const headers: { [key: string]: string } = {};
            if (idToken) {
                headers['Authorization'] = `Bearer ${idToken}`;
            }
    
            const response = await axios.get(apiEndpoint, {
                headers: headers,
                params: {
                    OperationType: 'fetch_GlobalBooks_Lendinginfo_ByUsersub_2',
                    UserSub: targetUserSub
                }
            });
    
            if (response.status === 200) {
                const data = response.data;
                setTotalBooks(data.total_books);
                
                // S3から各JSONファイルを取得して処理
                const allBooks: Book[] = [];
                for (const url of data.file_urls) {
                    const fileResponse = await axios.get(url);
                    allBooks.push(...fileResponse.data);
                }
                
                setBooks(allBooks);
                createShelvesFromBooks(allBooks);
            } else {
                setError('Failed to fetch bookshelf data');
            }
        } catch (err) {
            setError('An error occurred while fetching the bookshelf data');
        } finally {
            setIsLoading(false);
        }
    }, [userNameOnURL, idToken]);


    

    // 除籍済みでない本のみをフィルタリング
    const activeBooks = useMemo(() => {
        return books.filter(book => book.global_book.RemovedDate === null);
    }, [books]);

    // 本棚ごとの冊数を計算
    const shelfBookCounts = useMemo(() => {
        const counts: {[key: string]: number} = {};
        activeBooks.forEach(book => {
            const shelfId = book.global_book.GlobalHondanaID || 'unassigned';
            counts[shelfId] = (counts[shelfId] || 0) + 1;
        });
        counts['all'] = activeBooks.length;
        return counts;
    }, [activeBooks]);

    useEffect(() => {
        console.log('username:', userNameOnURL);
        console.log('idToken:', idToken);
        if (userNameOnURL) {
            fetchUserBookshelfData();
        }
    }, [userNameOnURL, idToken, fetchUserBookshelfData]);

    const createShelvesFromBooks = (books: Book[]) => {
        const shelvesMap = new Map<string, Shelf>();
    
        shelvesMap.set("all", {
            HondanaName: "すべての本",
            GlobalHondanaID: "all",
            ColorCode: "#808080",
            ShelfOrder: -1  // "すべての本" を最初に表示するため
        });
    

        books.forEach(book => {
            if (book.global_book.RemovedDate === null) {  // 除籍済みの本は無視
                const { GlobalHondanaID, HondanaName, ColorCode, ShelfOrder } = book.global_hondana;
                if (GlobalHondanaID && !shelvesMap.has(GlobalHondanaID)) {
                    shelvesMap.set(GlobalHondanaID, {
                        GlobalHondanaID,
                        HondanaName,
                        ColorCode: ColorCode || "#FFFFFF",
                        ShelfOrder: typeof ShelfOrder === 'number' ? ShelfOrder : 999
                    });
                }
            }
        });
    
        const newShelves = Array.from(shelvesMap.values()).sort((a, b) => {
            // "すべての本" を最初に
            if (a.GlobalHondanaID === "all") return -1;
            if (b.GlobalHondanaID === "all") return 1;
    
            // ShelfOrder が 0 の場合も含めて正しくソート
            return a.ShelfOrder - b.ShelfOrder;
        });
    
        setShelves(newShelves);
    };

    const handleBookClick = (kanriId: string) => {
        // navigate(`/manifestation/${kanriId}`);
        window.open(`/manifestation/${kanriId}`, '_blank');
    };

    const handleOpenDialog = (bookID: string) => {
        setSelectedBookID(bookID);
    };

    const handleCloseDialog = () => {
        setSelectedBookID(null);
    };

    const sortBooks = (books: Book[]) => {
        switch (sortCriteria) {
            case 'newest':
                return books.sort((a, b) => new Date(b.global_book.RegisteredDate).getTime() - new Date(a.global_book.RegisteredDate).getTime());
            case 'oldest':
                return books.sort((a, b) => new Date(a.global_book.RegisteredDate).getTime() - new Date(b.global_book.RegisteredDate).getTime());
            case 'pub-newest':
                return books.sort((a, b) => new Date(b.book_details.PublishingDate).getTime() - new Date(a.book_details.PublishingDate).getTime());
            case 'pub-oldest':
                return books.sort((a, b) => new Date(a.book_details.PublishingDate).getTime() - new Date(b.book_details.PublishingDate).getTime());
            default:
                return books;
        }
    };

    const filterBooks = (book: Book) => {
        const searchText = filterText.toLowerCase();
        const { MainTitle, Responsibility } = book.book_details;
        return MainTitle.toLowerCase().includes(searchText) || Responsibility.toLowerCase().includes(searchText);
    };

    // const filteredBooks = books
    //     .filter(book => selectedShelf === "all" || book.global_book.GlobalHondanaID === selectedShelf)
    //     .filter(filterBooks);

    // const sortedBooks = sortBooks(filteredBooks);
    const filteredBooks = useMemo(() => {
        return books
            .filter(book => selectedShelf === "all" || book.global_book.GlobalHondanaID === selectedShelf)
            .filter(filterBooks);
    }, [books, selectedShelf, filterText]);

    const sortedBooks = useMemo(() => {
        return sortBooks(filteredBooks);
    }, [filteredBooks, sortCriteria]);

    if (isLoading) return <Box sx={{ width: '100%', mt: 4 }}>
            <Typography variant="h6" align="center" gutterBottom>
            @{userNameOnURL}の本棚を読み込んでいます...
            </Typography>
            <LinearProgress />
        </Box>;
    if (error) return <Typography color="error">{error}</Typography>;


    const getSelectedShelfColor = () => {
        const selected = shelves.find(shelf => shelf.GlobalHondanaID === selectedShelf);
        return selected ? selected.ColorCode : "#808080"; // デフォルトはグレー
    };
    
    return (
        <Container maxWidth="lg">
            {/* <Typography variant="h4" gutterBottom>{userNameOnURL}の本棚</Typography> */}
            <Typography variant="h4" gutterBottom>{userNameOnURL}の本棚（全 {totalBooks} 冊）</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <FormControl variant="outlined" sx={{ minWidth: 150 }}>
                    <InputLabel>並び替え</InputLabel>
                    <Select value={sortCriteria} onChange={(e) => setSortCriteria(e.target.value as string)} label="並び替え">
                        <MenuItem value="newest">登録順(新しい順)</MenuItem>
                        <MenuItem value="oldest">登録順(古い順)</MenuItem>
                        <MenuItem value="pub-newest">出版日順(新しい順)</MenuItem>
                        <MenuItem value="pub-oldest">出版日順(古い順)</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="フィルター"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        endAdornment: filterText && (
                            <IconButton onClick={() => setFilterText('')}><ClearIcon /></IconButton>
                        ),
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '200px', mr: 2, flexShrink: 0 }}>
                    <List>
                        {shelves.map((shelf) => (
                            <ListItem key={shelf.GlobalHondanaID} disablePadding>
                                <ListItemButton
                                    selected={selectedShelf === shelf.GlobalHondanaID}
                                    onClick={() => setSelectedShelf(shelf.GlobalHondanaID)}
                                >
                                    <ListItemText 
                                        primary={
                                            <React.Fragment>
                                                {shelf.HondanaName}
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{ ml: 1 }}
                                                >
                                                    ({shelfBookCounts[shelf.GlobalHondanaID] || 0})
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        sx={{
                                            '& .MuiListItemText-primary': {
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                lineHeight: '1.2em',
                                                maxHeight: '2.4em'
                                            }
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Grid container spacing={2} columns={{ xs: 2, sm: 4, md: 6, lg: 6, xl: 6 }}>
                    {sortedBooks.map((book) => (
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={book.global_book.GlobalBookID}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <Card 
                                    onClick={() => handleBookClick(book.global_book.KANRI_ID)}
                                    sx={{ 
                                        height: '220px',  // カードの高さを調整
                                        display: 'flex', 
                                        flexDirection: 'column',
                                        backgroundColor: getSelectedShelfColor(),
                                        transition: '0.3s',
                                        '&:hover': {
                                            boxShadow: 10,
                                        },
                                        padding: '12px',
                                        marginBottom: '8px',  // カードの下にマージンを追加
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <CardMedia
                                            component="img"
                                            sx={{
                                                height: '180px',  // カードいっぱいに書影を表示
                                                objectFit: 'contain',
                                                backgroundColor: getSelectedShelfColor(),
                                                borderRadius: '4px',
                                            }}
                                            image={generateCoverUrl(book.book_details)}
                                            alt={book.book_details.MainTitle}
                                        />
                                    </Box>
                                </Card>
                                <Box sx={{ mt: 1 ,textAlign:'left' }}>
                                    <Typography variant="subtitle2" noWrap>{book.book_details.MainTitle}</Typography>
                                    <Typography variant="body2" color="text.secondary" noWrap>
                                        {book.book_details.Responsibility}
                                    </Typography>

                                    <Typography
                                        variant="caption"
                                        sx={{ 
                                        cursor: 'pointer', 
                                        color: book.lending_status === "Lending" ? '#FB8C00' : '#64B5F6' 
                                        }}
                                        onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenDialog(book.global_book.GlobalBookID);
                                        }}
                                    >
                                        {book.lending_status === "Lending" ? "貸出中" : "貸出可能"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {selectedBookID && (
                <BookDetailDialog
                    open={Boolean(selectedBookID)}
                    onClose={handleCloseDialog}
                    globalBookID={selectedBookID}
                />
            )}
        </Container>
    );
};

export default OtherUsersBookshelfPage;
