// pages/HomePage.tsx


import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from 'react-helmet';
import { handleNoteOperation } from '../utils/noteHandler';
import { useAuth } from '../context/AuthContext';
import { getTimeline } from '../utils/getTimeline';
import { Note } from '../types/types';
import { getUserInfoByUsersubCF, UserData } from '../utils/userProfileApi';
import { renderNoteContent, loadTwitterScript } from '../components/renderNotes';
import { formatToJST } from '../utils/formatDate';

const HomePage: React.FC = () => {
  const [noteType, setNoteType] = useState<string>('ExplainNote');
  const [noteContent, setNoteContent] = useState<string>('');
  const [noteOpenLevel, setNoteOpenLevel] = useState<string>('PUBLIC');
  const [allowReplyLevel, setAllowReplyLevel] = useState<string>('PUBLIC');
  const [timelineNotes, setTimelineNotes] = useState<Note[] | null>(null);
  const [userInfos, setUserInfos] = useState<Record<string, UserData>>({});

  const { isLoggedIn, user, userSub, idToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [loadingUserInfo, setLoadingUserInfo] = useState(false);
  const navigate = useNavigate();

  const fetchTimeline = useCallback(async () => {
    if (userSub && idToken) {
      try {
        setLoading(true);
        const notes = await getTimeline(userSub, idToken);
        if (!notes || notes.length === 0) {
          setTimelineNotes(null);
          return;
        }
  
        const sortedNotes = notes.sort((a, b) => {
          const dateA = new Date(a.editedAt || a.createdAt);
          const dateB = new Date(b.editedAt || b.createdAt);
          return dateB.getTime() - dateA.getTime();
        });
  
        setTimelineNotes(sortedNotes);
      } catch (error) {
        console.error('Failed to fetch timeline:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [userSub, idToken]);

  const fetchUserInfos = useCallback(async () => {
    if (timelineNotes) {
      setLoadingUserInfo(true);
      const uniqueUserSubs = Array.from(new Set(timelineNotes.map(note => note.userSub)));

      
      const userInfoPromises = uniqueUserSubs.map(async (userSub) => {
        const userInfo = await getUserInfoByUsersubCF(userSub);
        return { userSub, userInfo };
      });

      const userInfosArray = await Promise.all(userInfoPromises);

      const userInfosObject = userInfosArray.reduce((acc, item) => {
        if (item && item.userInfo) {
          acc[item.userSub] = item.userInfo;
        }
        return acc;
      }, {} as Record<string, UserData>);

      setUserInfos(userInfosObject);
      setLoadingUserInfo(false);
    }
  }, [timelineNotes]);

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate('/Login');
  //   } else if (userSub && idToken) {
  //     fetchTimeline();
  //   }
  // }, [isLoggedIn, userSub, idToken, fetchTimeline, navigate]);

  // useEffect(() => {
  //   if (timelineNotes) {
  //     fetchUserInfos();
  //     loadTwitterScript();
  //   }
  // }, [timelineNotes, fetchUserInfos]);

  useEffect(() => {
    if (isLoggedIn && userSub && idToken) {
      fetchTimeline();
    }
  }, [isLoggedIn, userSub, idToken, fetchTimeline]);

  useEffect(() => {
    if (timelineNotes) {
      fetchUserInfos();
      loadTwitterScript();
    }
  }, [timelineNotes, fetchUserInfos]);

  useEffect(() => {
    const checkAuthAndFetchData = async () => {
      setLoading(true);
      if (isLoggedIn && userSub && idToken) {
        await fetchTimeline();
      }
      setLoading(false);
    };

    checkAuthAndFetchData();
  }, [isLoggedIn, userSub, idToken, fetchTimeline]);

  // useEffect(() => {
  //   loadTwitterScript();
  // }, [timelineNotes]);

  const handleNoteTypeChange = (event: SelectChangeEvent<string>) => {
    const selectedNoteType = event.target.value as string;
    setNoteType(selectedNoteType);

    if (selectedNoteType === 'ExplainNote') {
      setNoteOpenLevel('PUBLIC');
      setAllowReplyLevel('PUBLIC');
    }
  };

  const handleNoteOpenLevelChange = (event: SelectChangeEvent<string>) => {
    if (noteType !== 'ExplainNote') {
      setNoteOpenLevel(event.target.value as string);
    }
  };

  const handleAllowReplyLevelChange = (event: SelectChangeEvent<string>) => {
    if (noteType !== 'ExplainNote') {
      setAllowReplyLevel(event.target.value as string);
    }
  };

  const isContentValid = (content: string): boolean => {
    return content.trim().length > 0;
  };

  const handleNoteSubmit = async () => {
    if (!userSub || !idToken) {
      console.error('ユーザーがログインしていません');
      return;
    }

    if (!isContentValid(noteContent)) {
      console.error('ノート内容が無効です');
      return;
    }

    const noteArgs = {
      userSub,
      content: noteContent,
      metaDescription: '',
      parentNoteID: '',
      noteOpenLevel,
      allowReplyLevel,
    };

    try {
      let result;
      switch (noteType) {
        case 'ExplainNote':
          result = await handleNoteOperation({ Operation: 'CreateExplainNote', ...noteArgs }, idToken);
          break;
        case 'ImpressionNote':
          result = await handleNoteOperation({ Operation: 'CreateImpressionNote', ...noteArgs }, idToken);
          break;
        default:
          throw new Error('未知のノートの種類です');
      }
      console.log(result);
    } catch (error) {
      console.error('ノートの投稿に失敗しました:', error);
    }

    setNoteType('ExplainNote');
    setNoteContent('');
    setNoteOpenLevel('PUBLIC');
    setAllowReplyLevel('PUBLIC');
  };

  return (
    <>
      <Helmet>
        <title>ReadLinker - 書籍管理アプリ</title>
        <meta name="description" content="ReadLinkerは新しい書籍管理アプリです。" />
        <meta name="keywords" content="ReadLinker, 書籍, 本の貸し借り, 本棚, 図書館" />
      </Helmet>

      <Container>
        {isLoggedIn && idToken && userSub ? (
          <>
            <Box component="form" sx={{ mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '600px', mx: 'auto' }}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <InputLabel>ノートの種類</InputLabel>
                <Select value={noteType} onChange={handleNoteTypeChange}>
                  <MenuItem value="ExplainNote">説明ノート</MenuItem>
                  <MenuItem value="ImpressionNote">感想ノート</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="ノート内容"
                multiline
                minRows={2}
                maxRows={20}
                fullWidth
                variant="outlined"
                value={noteContent}
                onChange={(e) => setNoteContent(e.target.value)}
                sx={{ mb: 1 }}
              />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
                <FormControl sx={{ width: '48%' }} disabled={noteType === 'ExplainNote'}>
                  <InputLabel>公開範囲</InputLabel>
                  <Select value={noteOpenLevel} onChange={handleNoteOpenLevelChange}>
                    <MenuItem value="PUBLIC">公開</MenuItem>
                    <MenuItem value="FRIENDS">限定公開</MenuItem>
                    <MenuItem value="PRIVATE">非公開</MenuItem>
                  </Select>
                </FormControl>

                <FormControl sx={{ width: '48%' }} disabled={noteType === 'ExplainNote'}>
                  <InputLabel>リプライの許可</InputLabel>
                  <Select value={allowReplyLevel} onChange={handleAllowReplyLevelChange}>
                    <MenuItem value="PUBLIC">全員</MenuItem>
                    <MenuItem value="FRIENDS">限定</MenuItem>
                    <MenuItem value="PRIVATE">自分のみ</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={handleNoteSubmit}
                sx={{ alignSelf: 'flex-end' }}
                disabled={!isContentValid(noteContent)}
              >
                投稿
              </Button>
            </Box>

            <Divider />

            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                  <CircularProgress />
                </Box>
              ) : timelineNotes && timelineNotes.length > 0 ? (
                <>
                  {timelineNotes.map((note) => (
                    <Paper 
                      key={note.noteId} 
                      elevation={2} 
                      sx={{ padding: 2, marginBottom: 2, cursor: 'pointer' }}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`/note/${note.noteId}`, '_blank');
                      }}
                    >
                      {userInfos[note.userSub] ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                          <Avatar
                            src={userInfos[note.userSub].profileImage}
                            alt="Profile Image"
                            sx={{ cursor: 'pointer', marginRight: 2, width: 40, height: 40 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/${userInfos[note.userSub]?.username}`);
                            }}
                          />
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="subtitle1">{userInfos[note.userSub].preferred_name}</Typography>
                            <Typography variant="body2" sx={{ color: 'grey' }}>
                              {formatToJST(note.createdAt)}
                            </Typography>
                            {/* <Typography variant="body2" sx={{ color: 'grey' }}>
                              更新日時: {note.editedAt ? formatToJST(note.editedAt) : '更新なし'}
                            </Typography> */}
                          </Box>
                        </Box>
                      ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                          <CircularProgress size={20} />
                          <Typography variant="body2" sx={{ marginLeft: 1 }}>ユーザー情報を読み込み中...</Typography>
                        </Box>
                      )}

                      <Typography variant="body1" component="div">
                        {renderNoteContent(note)}
                      </Typography>
                    </Paper>
                  ))}
                  {loadingUserInfo && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                      <CircularProgress size={20} />
                      <Typography variant="body2" sx={{ marginLeft: 1 }}>ユーザー情報を読み込み中...</Typography>
                    </Box>
                  )}
                </>
              ) : (
                <Typography>現在表示するノートがありません。</Typography>
              )}
            </Box>
          </>
        ) : (
          // <Typography variant="body1" sx={{ mt: 2 }}>
          //   ログインするとタイムラインが表示されます。
          // </Typography>
<Box 
  sx={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh', 
    textAlign: 'center', 
    backgroundColor: '#fff', // 背景は白
    padding: 3 
  }}
>
  <Box 
    sx={{ 
      backgroundColor: '#fff', // 背景色は白
      padding: 4, 
      borderRadius: 3, 
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // 軽い影をつける
      maxWidth: '600px' // 幅を制限して読みやすく
    }}
  >
    <Typography variant="h4" sx={{ mb: 2 }}>
      ReadLinkerへようこそ
    </Typography>
    <Typography variant="body1" sx={{ mb: 2 }}>
      ReadLinkerは、紙の本をこよなく愛する人のための本情報のプラットフォームです。
      内容、共著者など、本と本のつながりをもとに、あなたにピッタリな本に出会うことができます。
    </Typography>
    <Typography variant="body1" sx={{ mb: 2 }}>
      紙の本1冊1冊を明確に区別する本棚機能を備えています。スマホアプリ内で発行されるバーコードを自分の本に貼って、マイ図書館を作りましょう。
    </Typography>
    <Typography variant="body1" sx={{ mb: 4 }}>
      ログインすると、本棚の表示、ノートの執筆・閲覧、リスト機能などすべての機能をご利用いただけます。
    </Typography>

    {/* ログインボタンを追加 */}
    <Button 
      variant="contained" 
      color="primary" 
      size="large"
      sx={{ mt: 2, paddingX: 4 }}
      onClick={() => navigate('/login')}
    >
      ログイン
    </Button>
  </Box>
</Box>



        )}
      </Container>
    </>
  );
};

export default HomePage;