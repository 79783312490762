// utils/generateCoverUrl.ts

interface PartialBookDetail {
  MainTitle: string;
  ContentDetailTitle?: string;
  TitleOthers?: string;
  ISBN?: string;
}

export const generateCoverUrl = (book: PartialBookDetail): string => {
  const baseUrl = "https://d29nzmf96r2qm6.cloudfront.net/default/generate_ogp_http_param?title=";
  const title = `${book.MainTitle}${book.ContentDetailTitle || ''}${book.TitleOthers || ''}`;
  let imageUrl = `${baseUrl}${encodeURIComponent(title)}`;

  if (book.ISBN) {
    imageUrl += `&isbn=${book.ISBN}`;
  }

  return imageUrl;
};