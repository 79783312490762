//pages/NotePage;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import { GetNote, handleNoteOperation, GetNoteOpenEndpoint } from '../utils/noteHandler';
import { useAuth } from '../context/AuthContext';
import { Note } from '../types/types';
import { getUserInfoByUsersubCF, UserData } from '../utils/userProfileApi';
import { renderNoteContent, loadTwitterScript } from '../components/renderNotes';
import { formatToJST } from '../utils/formatDate';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';

const NotePage: React.FC = () => {
  const { noteID } = useParams<{ noteID: string }>();
  const navigate = useNavigate();
  const { isLoggedIn, userSub, idToken } = useAuth();
  const [note, setNote] = useState<Note | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);
  const [noteContent, setNoteContent] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const fetchNote = async () => {
    setLoading(true);
    try {
      let noteDetail: Note | null = null;
      if (noteID) {
        if (idToken) {
          const sanitizedUserSub = userSub ?? undefined;
          noteDetail = await GetNote({ userSub: sanitizedUserSub, noteID }, idToken);
        } else {
          noteDetail = await GetNoteOpenEndpoint({ noteID });
        }

        if (noteDetail) {
          setNote(noteDetail);
          setNoteContent(noteDetail.content);

          const userInfo = await getUserInfoByUsersubCF(noteDetail.userSub);
          setUserData(userInfo);
        } else {
          console.error('Note not found');
        }
      }
    } catch (error) {
      console.error('Failed to fetch note detail:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNote();
  }, [noteID, idToken, userSub]);

  useEffect(() => {
    loadTwitterScript();
  }, []);

  useEffect(() => {
    if (note?.content) {
      loadTwitterScript();
    }
  }, [note?.content]);

  const handleEditToggle = () => {
    setEditing((prev) => !prev);
  };

  const handleNoteSave = async () => {
    if (!userSub || !idToken || !noteID || !note) {
      console.error('Not authorized or missing noteID');
      return;
    }

    const operation = `Edit${note.noteType}` as 'EditExplainNote' | 'EditImpressionNote' | 'EditInformationNote';

    setSaving(true);
    try {
      await handleNoteOperation({
        Operation: operation,
        noteID,
        userSub,
        content: noteContent,
      }, idToken);

      await fetchNote();
      setEditing(false);
    } catch (error) {
      console.error('Failed to update note:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleNoteDelete = async () => {
    if (!userSub || !idToken || !noteID || !note) {
      console.error('Not authorized or missing noteID');
      return;
    }

    const operation = `Delete${note.noteType}` as 'DeleteExplainNote' | 'DeleteImpressionNote' | 'DeleteInformationNote';

    try {
      await handleNoteOperation({
        Operation: operation,
        noteID,
        userSub,
      }, idToken);

      navigate('/');
    } catch (error) {
      console.error('Failed to delete note:', error);
    }
  };

  const canEdit = () => {
    if (!note || !userSub) {
      return false;
    }
    if (note.noteType === 'InformationNote') {
      return true;
    }
    if ((note.noteType === 'ImpressionNote' || note.noteType === 'ExplainNote') && note.userSub === userSub) {
      return true;
    }
    return false;
  };

  const handleProfileClick = () => {
    if (userData?.username) {
      navigate(`/${userData.username}`);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

return (
    <Container>
      {loading ? (
        <CircularProgress />
      ) : note ? (
        <Box>
          <Typography variant="h4" component="div" gutterBottom>
            ノートの詳細
          </Typography>
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
            {userData && (
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {userData.profileImage && (
                    <Avatar
                      src={userData.profileImage}
                      alt="Profile Image"
                      sx={{ cursor: 'pointer', marginRight: 2, width: 56, height: 56 }}
                      onClick={handleProfileClick}
                    />
                  )}
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6">{userData.preferred_name || 'Unknown'}</Typography>
                    <Typography variant="body2" sx={{ color: 'grey' }}>
                      {formatToJST(note.createdAt)}
                    </Typography>
                    {/* <Typography variant="body2" sx={{ color: 'grey' }}>
                      更新日時: {note.editedAt ? formatToJST(note.editedAt) : '更新なし'}
                    </Typography> */}
                  </Box>
                </Box>
                {canEdit() && (
                  <IconButton onClick={handleMenuOpen}>
                    <MoreVertIcon />
                  </IconButton>
                )}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => { handleMenuClose(); handleEditToggle(); }}>
                    編集
                  </MenuItem>
                  <MenuItem onClick={() => { handleMenuClose(); handleNoteDelete(); }} sx={{ color: 'red' }}>
                    <DeleteIcon sx={{ marginRight: 1 }} />
                    削除
                  </MenuItem>
                </Menu>
              </Box>
            )}
            {editing ? (
              <>
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  value={noteContent}
                  onChange={(e) => setNoteContent(e.target.value)}
                  disabled={saving}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNoteSave}
                  sx={{ marginTop: 2 }}
                  disabled={saving}
                >
                  {saving ? '保存中...' : '保存'}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleEditToggle}
                  sx={{ marginTop: 2, marginLeft: 2 }}
                  disabled={saving}
                >
                  キャンセル
                </Button>
              </>
            ) : (
              <Typography variant="body1" component="div">
                {renderNoteContent(note)}
              </Typography>
            )}
          </Paper>
        </Box>
      ) : (
        <Typography variant="h6" component="div" color="error">
          ノートが見つかりませんでした。
        </Typography>
      )}
    </Container>
  );
};

export default NotePage;