export const discardGlobalBook = async (GlobalBookID: string, idToken: string): Promise<boolean> => {
  try {
    const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD形式で現在の日付を取得
    const response = await fetch(`https://zejgdrpbj1.execute-api.us-east-1.amazonaws.com/v1/readlinker-book-handler-speed`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify({
        Operation: 'DiscardGlobalBook',
        RemovedDate: currentDate,
        GlobalBookID: GlobalBookID
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('DiscardGlobalBook response:', data);
    
    // ステータスコードが200なら成功とみなす
    return response.status === 200;
  } catch (error) {
    console.error('Error discarding global book:', error);
    return false;
  }
};