import axios from 'axios';

const API_ENDPOINT = 'https://kypc7382xh.execute-api.us-east-1.amazonaws.com/default/readlinker-fav-history-handler';

export const addToHistory = async (userSub: string, kanriId: string, idToken: string): Promise<void> => {
  try {
    await axios.post(API_ENDPOINT, 
      {
        Operation: 'addHistoryBook',
        UserSub: userSub,
        KANRI_ID: kanriId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      }
    );
  } catch (error) {
    console.error('Error adding to history:', error);
    throw error;
  }
};

export const getHistory = async (userSub: string, idToken: string): Promise<any[]> => {
  try {
    const response = await axios.post(API_ENDPOINT,
      {
        Operation: 'getHistoryBooks',
        UserSub: userSub
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error getting history:', error);
    throw error;
  }
};

export const deleteFromHistory = async (userSub: string, visitedAt: string, idToken: string): Promise<void> => {
  try {
    await axios.post(API_ENDPOINT,
      {
        Operation: 'deleteHistoryBook',
        UserSub: userSub,
        visitedAt: visitedAt
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      }
    );
  } catch (error) {
    console.error('Error deleting from history:', error);
    throw error;
  }
};