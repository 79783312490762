// //pages/ProfileSettings.tsx


import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import { getUserProfileImage, getUserProfileText, getPreferredUsername, update_preferred_username, uploadImageToLambda, updateUserProfileWithoutImage,getUserProfileImageByuserSubCF } from '../utils/userProfileApi';
import { userPool } from '../services/cognitoConfig';
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';
import { Button, TextField, Box, Avatar, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ProfileSettings: React.FC = () => {
    const { isLoggedIn } = useAuth();
    const [profileImage, setProfileImage] = useState<File | null>(null);
    const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
    const [name, setPreferredName] = useState<string>('');
    const [bio, setBio] = useState<string>('');
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [isCropperOpen, setIsCropperOpen] = useState<boolean>(false);
    const cropperRef = useRef<HTMLImageElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfileData = async () => {
            if (isLoggedIn) {
                const cognitoUser: CognitoUser | null = userPool.getCurrentUser();
                if (cognitoUser) {
                    cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
                        if (err) {
                            console.error('Error getting session:', err);
                            return;
                        }
                        if (session) {
                            const idToken = session.getIdToken().getJwtToken();
                            const userSub = session.getIdToken().payload.sub;
                            // getUserProfileImage(userSub, idToken).then(response => {
                            //     if (response && response.profileImage) {
                            //         setProfileImageUrl(response.profileImage);
                            //     }
                            // }).catch(error => {
                            //     console.error('Error fetching profile image:', error);
                            // });
                            getUserProfileImageByuserSubCF(userSub).then(response => {
                                if (response && response) {
                                    setProfileImageUrl(response);
                                }
                            }).catch(error => {
                                console.error('Error fetching profile image:', error);
                            });




                            getUserProfileText(userSub, idToken).then(response => {
                                if (response && response.profileText) {
                                    setBio(response.profileText);
                                }
                            
                            }).catch(error => {
                                console.error('Error fetching profile text:', error);
                            });

                            getPreferredUsername(userSub, idToken).then(response => {
                                if (response && response.preferred_name) {
                                    setPreferredName(response.preferred_name);
                                }
                            }).catch(error => {
                                console.error('Error fetching preferred username:', error);
                            });
                        }
                    });
                }
            }
        };

        fetchProfileData();
    }, [isLoggedIn]);

    const handleProfileImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setProfileImage(file);
            setProfileImageUrl(URL.createObjectURL(file)); // ローカルプレビュー用にURLを設定
            setIsCropperOpen(true); // クロッパーダイアログを開く
        }
    };

    const handleCrop = () => {
        if (cropperRef.current) {
            const cropper = (cropperRef.current as any).cropper;
            const canvas = cropper.getCroppedCanvas();
            if (canvas) {
                setCroppedImage(canvas.toDataURL()); // クロップされた画像をBase64として保存
                setIsCropperOpen(false); // クロッパーダイアログを閉じる
            }
        }
    };

    // const handleSaveProfile = async () => {
    //     const cognitoUser: CognitoUser | null = userPool.getCurrentUser();
    //     if (cognitoUser) {
    //         cognitoUser.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
    //             if (err) {
    //                 console.error('Error getting session:', err);
    //                 return;
    //             }
    //             if (session) {
    //                 const idToken = session.getIdToken().getJwtToken();
    //                 const userSub = session.getIdToken().payload.sub;

    //                 // プロフィール画像のURLの初期値を設定
    //                 let profileImageUrlToUpdate = profileImageUrl;

    //                 // 画像が選択されていてクロップされた場合は、canvasから直接Blobを作成
    //                 if (croppedImage && cropperRef.current) {
    //                     const cropper = (cropperRef.current as any).cropper;
    //                     cropper.getCroppedCanvas().toBlob(async (blob: Blob | null) => {
    //                         if (blob) {
    //                             const file = new File([blob], 'cropped_image.jpg', { type: 'image/jpeg' });
    //                             profileImageUrlToUpdate = await uploadImageToLambda(file, userSub, idToken);
    //                             if (!profileImageUrlToUpdate) {
    //                                 console.error('Failed to upload profile image');
    //                                 return;
    //                             }
    //                             // プロフィール情報を更新
    //                             await updateUserProfileWithoutImage(userSub, bio, idToken);
    //                             await update_preferred_username(userSub, name, idToken);
    //                             navigate('/');
    //                         }
    //                     }, 'image/jpeg');
    //                 } else {
    //                     // 画像が選択されていない場合、既存の画像URLで更新
    //                     await updateUserProfileWithoutImage(userSub, bio, idToken);
    //                     await update_preferred_username(userSub, name, idToken);
    //                     navigate('/');
    //                 }
    //             }
    //         });
    //     }
    // };

    const handleSaveProfile = async () => {
        const cognitoUser: CognitoUser | null = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
                if (err) {
                    console.error('Error getting session:', err);
                    return;
                }
                if (session) {
                    const idToken = session.getIdToken().getJwtToken();
                    const userSub = session.getIdToken().payload.sub;
    
                    // プロフィール画像のURLの初期値を設定
                    let profileImageUrlToUpdate = profileImageUrl;
    
                    try {
                        // 画像が選択されていてクロップされた場合は、canvasから直接Blobを作成
                        if (croppedImage && cropperRef.current) {
                            const cropper = (cropperRef.current as any).cropper;
                            
                            // BlobをPromiseで取得
                            const blob: Blob | null = await new Promise((resolve) => {
                                cropper.getCroppedCanvas().toBlob((blob: Blob | null) => {
                                    resolve(blob);
                                }, 'image/jpeg');
                            });
    
                            if (blob) {
                                const file = new File([blob], 'cropped_image.jpg', { type: 'image/jpeg' });
                                profileImageUrlToUpdate = await uploadImageToLambda(file, userSub, idToken);
                                if (!profileImageUrlToUpdate) {
                                    console.error('Failed to upload profile image');
                                    return;
                                }
                            }
                        }
                        
                        // プロフィール情報を更新
                        await updateUserProfileWithoutImage(userSub, bio, idToken);
                        await update_preferred_username(userSub, name, idToken);
    
                        // すべての処理が完了した後にホーム画面へ戻る
                        navigate('/');
                    } catch (error) {
                        console.error('Error updating profile:', error);
                    }
                }
            });
        }
    };
    
    

    return (
        <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
            <h2>プロフィール設定</h2>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                    src={croppedImage || profileImageUrl || ''}
                    alt="User Profile"
                    sx={{ width: 80, height: 80 }}
                />
                <Button variant="contained" component="label" sx={{ ml: 2 }}>
                    画像を変更
                    <input type="file" hidden onChange={handleProfileImageChange} />
                </Button>
            </Box>
            <TextField
                label="ユーザー名"
                fullWidth
                variant="outlined"
                value={name}
                onChange={(e) => setPreferredName(e.target.value)}
                sx={{ mb: 2 }}
            />
            <TextField
                label="自己紹介"
                fullWidth
                variant="outlined"
                multiline
                rows={4}
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleSaveProfile}>
                完了
            </Button>

            <Dialog open={isCropperOpen} onClose={() => setIsCropperOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>画像を切り取る</DialogTitle>
                <DialogContent>
                    {profileImageUrl && (
                        <Cropper
                            src={profileImageUrl}
                            style={{ height: 400, width: '100%' }}
                            initialAspectRatio={1}
                            aspectRatio={1}
                            guides={false}
                            ref={cropperRef}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsCropperOpen(false)}>キャンセル</Button>
                    <Button onClick={handleCrop} color="primary">切り取る</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ProfileSettings;
