// pages/ManifestationDetailPage.tsx

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useAuth } from '../context/AuthContext';
import { handleNoteOperation } from '../utils/noteHandler';
import { parsePublishingDate } from '../utils/parsePublishingDate';
import { renderNoteContent, loadTwitterScript } from '../components/renderNotes';
import { Note, BookDetail, RelatedBook, AuthorDetail } from '../types/types';
import { generateCoverUrl } from '../utils/generateCoverUrl';
import amazonImage from '../assets/images/assocbtn_gray_amazon_new1.png';
import calilImage from '../assets/images/calil-button-image.png';
import ndlImage from '../assets/images/ndl-button-image.png';
import wikipediaLogo from '../assets/images/wikipedia_logo.png';
import googleSearchIcon from '../assets/images/google-search-icon.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useFavorites } from '../context/FavoritesContext';
import { getFavoriteBooksCount, addFavoriteBook, deleteFavoriteBook } from '../utils/favoritesAndHistoryApi';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import { addToHistory } from '../utils/historyUtils';

import { Helmet } from 'react-helmet-async';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Menu from '@mui/material/Menu';
import { getListOfBookList, addListOfBookList, addBooksInList } from '../utils/listHandler'; 

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Snackbar from '@mui/material/Snackbar';

const ManifestationDetailPage: React.FC = () => {
  const { KANRI_ID } = useParams<{ KANRI_ID: string }>();
  const [bookDetail, setBookDetail] = useState<BookDetail | null>(null);
  const [relatedBooks, setRelatedBooks] = useState<RelatedBook[]>([]);
  const [notes, setNotes] = useState<Note[]>([]); // Note型を使用
  const [wikipediaLinks, setWikipediaLinks] = useState<{ [key: string]: string | null }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [showNoteOptions] = useState<boolean>(false);
  const [enterPressCount, setEnterPressCount] = useState(0);
  const enterTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [infoNoteSiteBook_bookSearchResults, infoNoteSiteBook_setBookSearchResults] = useState<BookDetail[]>([]);
  const [infoNoteSiteBook_searchQuery, infoNoteSiteBook_setSearchQuery] = useState<string>('');
  const [infoNoteSiteBook_metaDescription, infoNoteSiteBook_setMetaDescription] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'newest' | 'score'>('score');
  const [infoNoteSiteBook_showSearch, infoNoteSiteBook_setShowSearch] = useState(false);
  const [infoNoteSiteBook_selectedBookId, infoNoteSiteBook_setSelectedBookId] = useState<string | null>(null);
  const [infoNoteSiteBook_sourceType, infoNoteSiteBook_setSourceType] = useState<'manifestation' | 'work' | 'expression' | 'item'>('manifestation');
  const [infoNoteSiteBook_citingType, infoNoteSiteBook_setCitingType] = useState<'manifestation' | 'work' | 'expression' | 'item'>('manifestation');
  const [infoNoteSiteBook_stance, infoNoteSiteBook_setStance] = useState<'positive' | 'neutral' | 'criticism' | 'noselection'>('noselection');
  const [infoNoteSiteTenkyo_tenkyoSearchResults, infoNoteSiteTenkyo_setTenkyoSearchResults] = useState<AuthorDetail[]>([]);
  const [infoNoteSiteTenkyo_searchQuery, infoNoteSiteTenkyo_setSearchQuery] = useState<string>('');
  const [infoNoteSiteTenkyo_selectedTenkyoId, infoNoteSiteTenkyo_setSelectedTenkyoId] = useState<string | null>(null);
  const [infoNoteSiteTenkyo_showSearch, infoNoteSiteTenkyo_setShowSearch] = useState(false);
  const [infoNoteSiteTenkyo_metaDescription, infoNoteSiteTenkyo_setMetaDescription] = useState<string>('');
  const [infoNoteSiteTenkyo_stance, infoNoteSiteTenkyo_setStance] = useState<'positive' | 'neutral' | 'criticism' | 'noselection'>('noselection');
  
  const [isRelatedBooksExpanded, setIsRelatedBooksExpanded] = useState<boolean>(true);
  const toggleRelatedBooks = () => {
    setIsRelatedBooksExpanded(!isRelatedBooksExpanded);
  };
  
  const navigate = useNavigate();
  const { idToken, userSub } = useAuth();

  const infoNoteSiteBook_handleSelectBook = (bookId: string) => {
    infoNoteSiteBook_setSelectedBookId(bookId);
  };

  const infoNoteSiteBook_handleCancel = () => {
    infoNoteSiteBook_setShowSearch(false);
    infoNoteSiteBook_setSearchQuery('');
    infoNoteSiteBook_setBookSearchResults([]);
    infoNoteSiteBook_setMetaDescription('');
    infoNoteSiteBook_setSourceType('manifestation');
    infoNoteSiteBook_setCitingType('manifestation');
    infoNoteSiteBook_setStance('noselection');
    infoNoteSiteBook_setSelectedBookId(null);
    setActiveNoteType(null);
  };

  const infoNoteSiteTenkyo_handleSelectTenkyo = (tenkyoId: string) => {
    infoNoteSiteTenkyo_setSelectedTenkyoId(tenkyoId);
  };

  const infoNoteSiteTenkyo_handleCancel = () => {
    infoNoteSiteTenkyo_setShowSearch(false);
    infoNoteSiteTenkyo_setSearchQuery('');
    infoNoteSiteTenkyo_setTenkyoSearchResults([]);
    infoNoteSiteTenkyo_setMetaDescription('');
    infoNoteSiteTenkyo_setStance('noselection');
    infoNoteSiteTenkyo_setSelectedTenkyoId(null);
    setActiveNoteType(null);
  };

  const [explainNoteContent, setExplainNoteContent] = useState(`https://readlinker.com/manifestation/${KANRI_ID}\n\n`);
  const [impressionNoteContent, setImpressionNoteContent] = useState(`https://readlinker.com/manifestation/${KANRI_ID}\n\n`);
  const [impressionNoteOpenLevel, setImpressionNoteOpenLevel] = useState('PUBLIC');
  const [impressionNoteAllowReplyLevel, setImpressionNoteAllowReplyLevel] = useState('PUBLIC');

  const [favoriteCount, setFavoriteCount] = useState<number>(0);
  const { state, dispatch } = useFavorites();
  const isFavorite = state.favoriteBooks.some(book => book.KANRI_ID === KANRI_ID);

  const [bookLists, setBookLists] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openCreateListDialog, setOpenCreateListDialog] = useState(false);
  const [newListName, setNewListName] = useState('気になる本');
  const [newListOpenLevel, setNewListOpenLevel] = useState('PUBLIC');
  const [isCreatingList, setIsCreatingList] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchFavoriteCount = async () => {
      if (KANRI_ID) {
        try {
          const favCount = await getFavoriteBooksCount(KANRI_ID);
          setFavoriteCount(favCount?.booksFavCount ? Number(favCount.booksFavCount) : 0);
        } catch (error) {
          console.error('Error fetching favorite count:', error);
        }
      }
    };

    fetchFavoriteCount();
  }, [KANRI_ID]);


  const handleFavoriteToggle = async () => {
    if (!userSub || !KANRI_ID || !idToken) return;

    try {
      if (isFavorite) {
        await deleteFavoriteBook(userSub, KANRI_ID, idToken);
        dispatch({ type: 'REMOVE_FAVORITE', payload: KANRI_ID });
        setFavoriteCount(prev => prev - 1);
      } else {
        await addFavoriteBook(userSub, KANRI_ID, idToken);
        dispatch({
          type: 'ADD_FAVORITE',
          payload: { KANRI_ID, userSub }
        });
        setFavoriteCount(prev => prev + 1);
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
      // エラー処理（例：エラーメッセージの表示）
    }
  };

  

  const groupBooksByMainBookAuthors = (books: RelatedBook[], mainBookAuthors: { id: string, name: string }[]) => {
    const groupedBooks: { [key: string]: { authorName: string, books: RelatedBook[] } } = {};

    mainBookAuthors.forEach(author => {
      groupedBooks[author.id] = { authorName: author.name, books: [] };
    });

    books.forEach(book => {
      mainBookAuthors.forEach(author => {
        if (
          book.ContributorID_01 === author.id ||
          book.ContributorID_02 === author.id ||
          book.ContributorID_03 === author.id ||
          book.ContributorID_04 === author.id ||
          book.ContributorID_05 === author.id ||
          book.ContributorID_06 === author.id ||
          book.ContributorID_07 === author.id ||
          book.ContributorID_08 === author.id ||
          book.ContributorID_09 === author.id ||
          book.ContributorID_10 === author.id
        ) {
          if (!groupedBooks[author.id].books.some(b => b.KANRI_ID === book.KANRI_ID || b.ISBN === book.ISBN)) {
            groupedBooks[author.id].books.push(book);
          }
        }
      });
    });

    Object.keys(groupedBooks).forEach(authorId => {
      groupedBooks[authorId].books.sort((a, b) => {
        const dateA = parsePublishingDate(a.PublishingDate);
        const dateB = parsePublishingDate(b.PublishingDate);
        if (dateA && dateB) {
          return dateB.getTime() - dateA.getTime();
        } else if (dateA) {
          return -1;
        } else if (dateB) {
          return 1;
        } else {
          return 0;
        }
      });
    });

    return groupedBooks;
  };

  const convertISBN13toISBN10 = (isbn: string): string => {
    if (!isbn || isbn.length !== 13 || !isbn.startsWith("978")) {
      throw new Error('提供されたISBNはISBN-13形式（978で始まる）ではありません。');
    }
    let isbn9 = isbn.substring(3, 12);
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += (10 - i) * parseInt(isbn9.charAt(i), 10);
    }
    let checkDigit: number | string = 11 - (sum % 11);
    checkDigit = checkDigit === 10 ? 'X' : checkDigit === 11 ? '0' : checkDigit.toString();
    return isbn9 + checkDigit;
  };

  const createAmazonLink = (isbn: string, mainTitle: string): string => {
    let amazonISBN = isbn;
    if (isbn && isbn.length === 13 && isbn.startsWith("978")) {
      try {
        amazonISBN = convertISBN13toISBN10(isbn);
      } catch (error) {
        console.error((error as Error).message);
      }
    }
    if (amazonISBN && amazonISBN.length === 10) {
      return `http://www.amazon.co.jp/dp/${amazonISBN}?&tag=readlinker202-22&linkCode=ogi`;
    } else {
      const encodedTitle = encodeURIComponent(mainTitle);
      return `https://www.amazon.co.jp/s?k=${encodedTitle}&__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A`;
    }
  };

  const createCalilLink = (isbn: string) => {
    return `https://calil.jp/book/${isbn}`;
  };

  const createNDLLink = (kanriId: string) => {
    return `https://ndlsearch.ndl.go.jp/books/R100000002-I${kanriId}`;
  };

  const fetchAuthorsWikipedia = async (tenkyoIDs: string[]): Promise<{ [key: string]: string | null }> => {
    const apiEndpoint = 'https://n3l8a26mj7.execute-api.us-east-1.amazonaws.com/default/readlinker-authors-info';
    const payload = {
      Operation: 'getAuthorsWikipediaByTenkyoIDs',
      TENKYO_IDs: tenkyoIDs,
    };

    try {
      const response: AxiosResponse<any> = await axios.post(apiEndpoint, payload, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        const data = response.data.reduce((acc: { [key: string]: string | null }, item: any) => {
          acc[item.TENKYO_ID!] = item.Wikipedia_Link;
          return acc;
        }, {} as { [key: string]: string | null });

        return data;
      } else {
        console.error('Error fetching Wikipedia links:', response.data);
        return {};
      }
    } catch (error) {
      console.error('Error fetching Wikipedia links:', error);
      return {};
    }
  };

  const mainBookAuthors = [
    { id: bookDetail?.ContributorID_01, name: bookDetail?.ContributorName_01 },
    { id: bookDetail?.ContributorID_02, name: bookDetail?.ContributorName_02 },
    { id: bookDetail?.ContributorID_03, name: bookDetail?.ContributorName_03 },
    { id: bookDetail?.ContributorID_04, name: bookDetail?.ContributorName_04 },
    { id: bookDetail?.ContributorID_05, name: bookDetail?.ContributorName_05 },
    { id: bookDetail?.ContributorID_06, name: bookDetail?.ContributorName_06 },
    { id: bookDetail?.ContributorID_07, name: bookDetail?.ContributorName_07 },
    { id: bookDetail?.ContributorID_08, name: bookDetail?.ContributorName_08 },
    { id: bookDetail?.ContributorID_09, name: bookDetail?.ContributorName_09 },
    { id: bookDetail?.ContributorID_10, name: bookDetail?.ContributorName_10 },
  ].filter(author => author.id && author.name) as { id: string, name: string }[];

  const groupedBooks = groupBooksByMainBookAuthors(relatedBooks, mainBookAuthors);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: bookDetail?.MainTitle,
          url: `https://readlinker.com/manifestation/${KANRI_ID}`
        });
      } else {
        navigator.clipboard.writeText(`https://readlinker.com/manifestation/${KANRI_ID}`);
        alert('URLをコピーしました');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  useEffect(() => {
    const fetchBookDetailAndAddToHistory = async () => {
      if (KANRI_ID) {
        try {
          // 本の詳細を取得する（認証状態に関わらず実行）
          const response = await axios.post('https://53wglutnub.execute-api.us-east-1.amazonaws.com/default/v1', {
            Operation: 'Fetch_Simplebook_by_kanri_id',
            KANRI_ID: KANRI_ID,
          });
          setBookDetail(response.data[0]);
  
          // ログイン状態の場合のみ閲覧履歴に追加
          if (userSub && idToken) {
            await addToHistory(userSub, KANRI_ID, idToken);
          }
        } catch (error) {
          console.error('Error fetching book detail or adding to history:', error);
        }
      }
    };
  
    const fetchRelatedBooks = async () => {
      if (KANRI_ID) {
        try {
          const response = await axios.post('https://53wglutnub.execute-api.us-east-1.amazonaws.com/default/v1', {
            Operation: 'fetchAuthorBooksInfo',
            KANRI_ID: KANRI_ID,
          });
          setRelatedBooks(response.data);
        } catch (error) {
          console.error('Error fetching related books:', error);
        } finally {
          setLoading(false);
        }
      }
    };
  
    const fetchNotes = async () => {
      if (KANRI_ID && idToken) {
        try {
          const apiEndpoint = `https://dcd5v8ukll.execute-api.us-east-1.amazonaws.com/default/?kanri_id=${KANRI_ID}`;
          const response = await axios.get(apiEndpoint, {
            headers: { 'Authorization': `Bearer ${idToken}` }
          });
          if (response.status === 200) {
            const sortedNotes = response.data.sort((a: Note, b: Note) => new Date(b.editedAt!).getTime() - new Date(a.editedAt!).getTime());
            setNotes(sortedNotes);
            console.log('Notes:', sortedNotes);
          }
        } catch (error) {
          console.error('Error fetching notes:', error);
        }
      }
    };
  
    fetchBookDetailAndAddToHistory();
    fetchRelatedBooks();
    fetchNotes(); // ノートの取得を追加（認証が必要な場合のみ実行される）
  }, [KANRI_ID, userSub, idToken]);

  useEffect(() => {
    const fetchWikipediaLinks = async () => {
      if (bookDetail) {
        const authorIds = [
          bookDetail.ContributorID_01,
          bookDetail.ContributorID_02,
          bookDetail.ContributorID_03,
          bookDetail.ContributorID_04,
          bookDetail.ContributorID_05,
          bookDetail.ContributorID_06,
          bookDetail.ContributorID_07,
          bookDetail.ContributorID_08,
          bookDetail.ContributorID_09,
          bookDetail.ContributorID_10,
        ].filter(id => id);
        const links = await fetchAuthorsWikipedia(authorIds);
        setWikipediaLinks(links);
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', `${bookDetail.MainTitle} by ${bookDetail.Responsibility}. Published by ${bookDetail.Publisher_1} on ${bookDetail.PublishingDate}.`);
        }
      }
    };

    fetchWikipediaLinks();
  }, [bookDetail]);

  const infoNoteSiteBook_handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    infoNoteSiteBook_setSearchQuery(event.target.value);
  };

  const infoNoteSiteBook_handleSearchQueryKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setEnterPressCount((prevCount) => prevCount + 1);
      if (enterPressCount === 0) {
        enterTimeoutRef.current = setTimeout(() => {
          setEnterPressCount(0);
        }, 500);
      } else if (enterPressCount === 1) {
        infoNoteSiteBook_fetchSearchResults(infoNoteSiteBook_searchQuery);
        if (enterTimeoutRef.current) {
          clearTimeout(enterTimeoutRef.current);
          setEnterPressCount(0);
        }
      }
    }
  };

  const infoNoteSiteBook_fetchSearchResults = async (query: string) => {
    setLoading(true);
    try {
      const response = await axios.post('https://3p6ygsdgae.execute-api.us-east-1.amazonaws.com/flutter', {
        OperationType: 'search_CS_readlinker_word_kanri_id_with_score',
        search_word: query,
        size: '50',
      });

      const responseData = response.data;
      let searchResultsArray: BookDetail[] = [];

      if (Array.isArray(responseData)) {
        searchResultsArray = responseData;
      } else if (typeof responseData === 'object') {
        searchResultsArray = Object.values(responseData).map(item => {
          if (typeof item === 'string') {
            return JSON.parse(item) as BookDetail;
          }
          return item as BookDetail;
        });
      }

      searchResultsArray.sort((a, b) => {
        if (sortOrder === 'newest') {
          const dateA = parsePublishingDate(a.PublishingDate);
          const dateB = parsePublishingDate(b.PublishingDate);
          return (dateB?.getTime() ?? 0) - (dateA?.getTime() ?? 0);
        }
        return parseFloat(b.search_score) - parseFloat(a.search_score);
      });

      infoNoteSiteBook_setBookSearchResults(searchResultsArray);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  const infoNoteSiteBook_handleNoteSubmit = async () => {
    if (!idToken) {
      console.error('ユーザーがログインしていません');
      return;
    }

    if (!infoNoteSiteBook_selectedBookId) {
      console.error('選択された本がありません');
      return;
    }

    if (!userSub) {
      console.error('ユーザーの識別子がありません');
      return;
    }

    const infoNoteSiteBook_referenceType = infoNoteSiteBook_stance;

    const noteArgs = {
      userSub,
      metaDescription: infoNoteSiteBook_metaDescription,
      referenceType: infoNoteSiteBook_referenceType,
      referenceTargetKANRI_ID: infoNoteSiteBook_selectedBookId,
      referenceTargetClassLevel: infoNoteSiteBook_sourceType,
      referenceSourceKANRI_ID: KANRI_ID,
      referenceSourceClassLevel: infoNoteSiteBook_citingType
    };

    try {
      const result = await handleNoteOperation({ Operation: 'CreateInformationNote', ...noteArgs }, idToken);
      if (result) {
        console.log('情報ノートが作成されました:', result);
        infoNoteSiteBook_handleCancel();
      } else {
        console.error('情報ノートの作成に失敗しました');
      }
    } catch (error) {
      console.error('情報ノートの作成中にエラーが発生しました:', error);
    }
  };

  const infoNoteSiteTenkyo_handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    infoNoteSiteTenkyo_setSearchQuery(event.target.value);
  };

  const infoNoteSiteTenkyo_handleSearchQueryKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setEnterPressCount((prevCount) => prevCount + 1);
      if (enterPressCount === 0) {
        enterTimeoutRef.current = setTimeout(() => {
          setEnterPressCount(0);
        }, 500);
      } else if (enterPressCount === 1) {
        infoNoteSiteTenkyo_fetchSearchResults(infoNoteSiteTenkyo_searchQuery);
        if (enterTimeoutRef.current) {
          clearTimeout(enterTimeoutRef.current);
          setEnterPressCount(0);
        }
      }
    }
  };

  const infoNoteSiteTenkyo_fetchSearchResults = async (query: string) => {
    setLoading(true);
    try {
      const response = await axios.get('https://d3tige1x5pvkax.cloudfront.net/default/search_ndl_authors', {
        params: {
          query,
          size: 100,
        }
      });

      const responseData = response.data;
      let authorResultsArray: AuthorDetail[] = [];

      if (Array.isArray(responseData)) {
        authorResultsArray = responseData;
      } else if (typeof responseData === 'object') {
        authorResultsArray = Object.values(responseData).map(item => {
          if (typeof item === 'string') {
            return JSON.parse(item) as AuthorDetail;
          }
          return item as AuthorDetail;
        });
      }

      authorResultsArray.sort((a, b) => b._score - a._score);

      infoNoteSiteTenkyo_setTenkyoSearchResults(authorResultsArray);

    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  const infoNoteSiteTenkyo_handleNoteSubmit = async () => {
    if (!idToken) {
      console.error('ユーザーがログインしていません');
      return;
    }

    if (!infoNoteSiteTenkyo_selectedTenkyoId) {
      console.error('選択された著者・団体がありません');
      return;
    }

    if (!userSub) {
      console.error('ユーザーの識別子がありません');
      return;
    }

    const infoNoteSiteTenkyo_referenceType = infoNoteSiteTenkyo_stance;

    const noteArgs = {
      userSub,
      metaDescription: infoNoteSiteTenkyo_metaDescription,
      referenceType: infoNoteSiteTenkyo_referenceType,
      referenceTargetTENKYO_ID: infoNoteSiteTenkyo_selectedTenkyoId,
      referenceSourceKANRI_ID: KANRI_ID
    };

    try {
      const result = await handleNoteOperation({ Operation: 'CreateInformationNote', ...noteArgs }, idToken);
      if (result) {
        console.log('情報ノートが作成されました:', result);
        infoNoteSiteTenkyo_handleCancel();
        setActiveNoteType(null);
      } else {
        console.error('情報ノートの作成に失敗しました');
      }
    } catch (error) {
      console.error('情報ノートの作成中にエラーが発生しました:', error);
    }
  };

  useEffect(() => {
    loadTwitterScript(); // Twitterスクリプトの読み込み
  }, [notes]);

  const handleExplainNoteSubmit = async () => {
    if (!idToken || !userSub) {
      console.error('ユーザーがログインしていません');
      return;
    }

    const noteArgs = {
      userSub,
      content: explainNoteContent,
      metaDescription: '',
      parentNoteID: '',
      noteOpenLevel: 'PUBLIC',
      allowReplyLevel: 'PUBLIC',
      referenceTargetKANRI_ID: KANRI_ID,
    };

    try {
      const result = await handleNoteOperation({ Operation: 'CreateExplainNote', ...noteArgs }, idToken);
      if (result) {
        console.log('説明ノートが作成されました:', result);
        // setExplainNoteContent('');
        setExplainNoteContent(`https://readlinker.com/manifestation/${KANRI_ID}\n\n`);
        setActiveNoteType(null);
        // ノートリストを更新するロジックをここに追加
      } else {
        console.error('説明ノートの作成に失敗しました');
      }
    } catch (error) {
      console.error('説明ノートの作成中にエラーが発生しました:', error);
    }
  };

  const handleImpressionNoteSubmit = async () => {
    if (!idToken || !userSub) {
      console.error('ユーザーがログインしていません');
      return;
    }

    const noteArgs = {
      userSub,
      content: impressionNoteContent,
      metaDescription: '',
      parentNoteID: '',
      noteOpenLevel: impressionNoteOpenLevel,
      allowReplyLevel: impressionNoteAllowReplyLevel,
      referenceTargetKANRI_ID: KANRI_ID,
    };

    try {
      const result = await handleNoteOperation({ Operation: 'CreateImpressionNote', ...noteArgs }, idToken);
      if (result) {
        console.log('感想ノートが作成されました:', result);
        // setImpressionNoteContent('');
        setImpressionNoteContent(`https://readlinker.com/manifestation/${KANRI_ID}\n\n`);
        setImpressionNoteOpenLevel('PUBLIC');
        setImpressionNoteAllowReplyLevel('PUBLIC');
        setActiveNoteType(null);
        // ノートリストを更新するロジックをここに追加
      } else {
        console.error('感想ノートの作成に失敗しました');
      }
    } catch (error) {
      console.error('感想ノートの作成中にエラーが発生しました:', error);
    }
  };

  const [activeNoteType, setActiveNoteType] = useState<'info' | 'explain' | 'impression' | null>(null);

  const handleNoteTypeToggle = (type: 'info' | 'explain' | 'impression') => {
    if (activeNoteType === type) {
      setActiveNoteType(null);
    } else {
      setActiveNoteType(type);
    }
  };

  const getButtonStyle = (noteType: 'info' | 'explain' | 'impression') => {
    const isDisabled = activeNoteType !== null && activeNoteType !== noteType;
  
    let backgroundColor;
    let color;
  
    switch (noteType) {
      case 'info':
        backgroundColor = isDisabled ? 'lightgrey' : 'primary.main';
        color = isDisabled ? 'text.secondary' : 'white';
        break;
      case 'explain':
        backgroundColor = isDisabled ? 'lightgrey' : 'green';
        color = isDisabled ? 'text.secondary' : 'white';
        break;
      case 'impression':
        backgroundColor = isDisabled ? 'lightgrey' : 'orange';
        color = isDisabled ? 'text.secondary' : 'white';
        break;
    }
  
    return {
      backgroundColor,
      color,
      '&:hover': {
        backgroundColor: isDisabled ? 'lightgrey' : backgroundColor,
      },
      '&.Mui-disabled': {
        backgroundColor: 'lightgrey',
        color: 'text.secondary',
      },
    };
  };


  const fetchBookLists = async () => {
    if (idToken) {
      try {
        const lists = await getListOfBookList(idToken);
        setBookLists(lists.sort((a: any, b: any) => a.listOrder - b.listOrder));
      } catch (error) {
        console.error('Error fetching book lists:', error);
      }
    }
  };

  useEffect(() => {
    fetchBookLists();
  }, [idToken]);

  const handleListButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleListClose = () => {
    setAnchorEl(null);
  };

  const handleAddToList = async (listId: string) => {
    // ここにリストに追加するロジックを実装
    if (bookDetail && idToken) {
      console.log(`Adding to list: ${listId}`);
      try {
        const response = await addBooksInList(listId, bookDetail.KANRI_ID, idToken);
        console.log('Add to list response:', response);
        if (response.statusCode === 200) {
          setSnackbarMessage('リストへ追加しました');
          setSnackbarOpen(true);
        } else if (response.statusCode === 409) {
          setSnackbarMessage('リストに既に入っていたので先頭に移動しました');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage('エラーが発生しました');
          setSnackbarOpen(true);
        }
        
        handleListClose();
      } catch (error) {
        console.error('Error adding book to list:', error);
        setSnackbarMessage('エラーが発生しました');
        setSnackbarOpen(true);
      }
    }
  };
  

  const handleCreateNewList = () => {
    handleListClose(); // メニューを閉じる
    setOpenCreateListDialog(true); // 新しいリスト作成ダイアログを開く
  };

  const handleCloseCreateListDialog = () => {
    setOpenCreateListDialog(false);
    setNewListName('気になる本');
    setNewListOpenLevel('PUBLIC');
  };

  const handleCreateList = async () => {
    if (!idToken || !userSub) return;

    setIsCreatingList(true);
    const newListOrder = bookLists.length > 0 ? Math.max(...bookLists.map(list => list.listOrder)) + 1 : 1;

    try {
      await addListOfBookList(newListName, '', newListOpenLevel, newListOrder, idToken);
      await fetchBookLists(); // リスト一覧を更新
      handleCloseCreateListDialog();
    } catch (error) {
      console.error('Error creating new list:', error);
    } finally {
      setIsCreatingList(false);
    }
  };

  // スナックバーのクローズハンドラー
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {bookDetail && (
            <Box sx={{ mb: 4, position: 'relative' }}>
              <Helmet>
                <title>{`ReadLinker | ${bookDetail.MainTitle}`}</title>
                <meta name="description" content={`${bookDetail.MainTitle} by ${bookDetail.Responsibility}. Published by ${bookDetail.Publisher_1} on ${bookDetail.PublishingDate}.`} />
                <meta property="og:title" content={`ReadLinker | ${bookDetail.MainTitle}`} />
                <meta property="og:description" content={`${bookDetail.MainTitle} by ${bookDetail.Responsibility}. Published by ${bookDetail.Publisher_1} on ${bookDetail.PublishingDate}.`} />
                <meta property="og:type" content="book" />
                <meta property="og:url" content={`https://readlinker.com/manifestation/${KANRI_ID}`} />
                <meta property="og:image" content={generateCoverUrl(bookDetail)} />
                <meta property="book:author" content={bookDetail.Responsibility} />
                <meta property="book:isbn" content={bookDetail.ISBN} />
                <meta property="book:release_date" content={bookDetail.PublishingDate} />
              </Helmet>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardMedia
                      component="img"
                      image={generateCoverUrl(bookDetail)}
                      alt={`Cover for ${bookDetail.MainTitle}`}
                      sx={{ objectFit: 'contain', height: '300px', width: '100%' ,padding: '10px'}}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box sx={{ position: 'absolute', top: 100, right: 40, zIndex: 1 }}>
                    <IconButton onClick={handleShare} sx={{ minWidth: 'auto', p: 1 }}>
                      <IosShareIcon sx={{ fontSize: 24 }} />
                    </IconButton>
                  </Box>
                  <Typography variant="h5" component="h1" gutterBottom>
                    {bookDetail.MainTitle}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {bookDetail.ContentDetailTitle}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {bookDetail.TitleOthers}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>著者:</strong> {bookDetail.Responsibility}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>出版社:</strong> {bookDetail.Publisher_1}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>出版日:</strong> {bookDetail.PublishingDate}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>ISBN:</strong> {bookDetail.ISBN}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                      {bookDetail.ISBN && (
                        <Grid item>
                          <Button
                            href={createAmazonLink(bookDetail.ISBN, bookDetail.MainTitle)}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ p: 0, minWidth: 'auto' }}
                          >
                            <img src={amazonImage} alt="Amazonで見る" style={{ width: 'auto', height: '40px' }} />
                          </Button>
                        </Grid>
                      )}
                      {bookDetail.ISBN && (
                        <Grid item>
                          <Button
                            href={createCalilLink(bookDetail.ISBN)}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ p: 0, minWidth: 'auto' }}
                          >
                            <img src={calilImage} alt="カーリルで見る" style={{ width: 'auto', height: '40px' }} />
                          </Button>
                        </Grid>
                      )}
                      {bookDetail.KANRI_ID && (
                        <Grid item>
                          <Button
                            href={createNDLLink(bookDetail.KANRI_ID)}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ p: 0, minWidth: 'auto' }}
                          >
                            <img src={ndlImage} alt="国立国会図書館で見る" style={{ width: 'auto', height: '40px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <IconButton 
                      onClick={handleFavoriteToggle} 
                      color="primary"
                      sx={{ 
                        color: isFavorite ? 'gold' : 'action.disabled',
                        '&:hover': {
                          color: isFavorite ? 'gold' : 'action.active',
                        },
                      }}
                    >
                      {isFavorite ? <StarIcon /> : <StarBorderIcon />}
                    </IconButton>
                    <Typography variant="body2" color="textSecondary">
                      {favoriteCount} お気に入り
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={handleListButtonClick}
                      sx={{ ml: 2 }}
                    >
                      リストに追加
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleListClose}
                    >
                      {bookLists.map((list) => (
                        <MenuItem key={list.bookListID} onClick={() => handleAddToList(list.bookListID)}>
                          {list.listName}
                        </MenuItem>
                      ))}
                      <MenuItem onClick={handleCreateNewList}>
                        ＋リストを新規作成
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ my: 4 }}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => handleNoteTypeToggle('info')}
                disabled={activeNoteType !== null && activeNoteType !== 'info'}
                sx={getButtonStyle('info')}
              >
                情報ノートを作成
              </Button>
              <Button
                variant="contained"
                onClick={() => handleNoteTypeToggle('explain')}
                disabled={activeNoteType !== null && activeNoteType !== 'explain'}
                sx={getButtonStyle('explain')}
              >
                説明ノートを作成
              </Button>
              <Button
                variant="contained"
                onClick={() => handleNoteTypeToggle('impression')}
                disabled={activeNoteType !== null && activeNoteType !== 'impression'}
                sx={getButtonStyle('impression')}
              >
                感想ノートを作成
              </Button>
            </Stack>

            <Collapse in={activeNoteType === 'info'}>
              <Box sx={{ mt: 2 }}>
                <Stack spacing={2} direction="column">
                  <Button variant="outlined" onClick={() => infoNoteSiteBook_setShowSearch(true)}>
                    この本が引用している本を登録する
                  </Button>

                  {infoNoteSiteBook_showSearch && (
                    <Box>
                      <TextField
                        label="引用本を検索"
                        fullWidth
                        variant="outlined"
                        value={infoNoteSiteBook_searchQuery}
                        onChange={infoNoteSiteBook_handleSearchQueryChange}
                        onKeyDown={infoNoteSiteBook_handleSearchQueryKeyDown}
                        InputProps={{
                          endAdornment: (
                            <>
                              <IconButton onClick={() => infoNoteSiteBook_setSearchQuery('')}>
                                <CloseIcon />
                              </IconButton>
                              <IconButton onClick={() => infoNoteSiteBook_fetchSearchResults(infoNoteSiteBook_searchQuery)}>
                                <SearchIcon />
                              </IconButton>
                            </>
                          ),
                        }}
                        sx={{ mb: 2 }}
                      />
                      <Box sx={{ maxHeight: '300px', overflow: 'auto', mb: 2 }}>
                        <List>
                          {infoNoteSiteBook_bookSearchResults.map((book) => (
                            <ListItem key={book.KANRI_ID} sx={{ display: 'flex', alignItems: 'center' }}>
                              <Checkbox
                                edge="start"
                                checked={infoNoteSiteBook_selectedBookId === book.KANRI_ID}
                                tabIndex={-1}
                                disableRipple
                                onChange={() => infoNoteSiteBook_handleSelectBook(book.KANRI_ID)}
                              />
                              <CardMedia
                                component="img"
                                image={generateCoverUrl(book)}
                                alt={`Cover for ${book.MainTitle}`}
                                sx={{ width: 50, height: 70, objectFit: 'contain', mr: 2 }}
                              />
                              <ListItemText primary={book.MainTitle} secondary={book.Responsibility + ' ' + book.PublishingDate} />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <TextField
                        label="追加情報"
                        fullWidth
                        variant="outlined"
                        value={infoNoteSiteBook_metaDescription}
                        onChange={(e) => infoNoteSiteBook_setMetaDescription(e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <Box sx={{ mb: 2 }}>
                        <FormLabel component="legend">引用先の本の実体タイプ</FormLabel>
                        <RadioGroup
                          row
                          value={infoNoteSiteBook_sourceType}
                          onChange={(e) => infoNoteSiteBook_setSourceType(e.target.value as 'manifestation' | 'work' | 'expression' | 'item')}
                        >
                          <FormControlLabel value="work" control={<Radio />} label="著作" disabled sx={{ color: 'grey' }} />
                          <FormControlLabel value="expression" control={<Radio />} label="表現系" disabled sx={{ color: 'grey' }} />
                          <FormControlLabel value="manifestation" control={<Radio />} label="体現系" />
                          <FormControlLabel value="item" control={<Radio />} label="個別資料" disabled sx={{ color: 'grey' }} />
                        </RadioGroup>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <FormLabel component="legend">引用する本の実体タイプ</FormLabel>
                        <RadioGroup
                          row
                          value={infoNoteSiteBook_citingType}
                          onChange={(e) => infoNoteSiteBook_setCitingType(e.target.value as 'manifestation' | 'work' | 'expression' | 'item')}
                        >
                          <FormControlLabel value="work" control={<Radio />} label="著作" disabled sx={{ color: 'grey' }} />
                          <FormControlLabel value="expression" control={<Radio />} label="表現系" disabled sx={{ color: 'grey' }} />
                          <FormControlLabel value="manifestation" control={<Radio />} label="体現系" />
                          <FormControlLabel value="item" control={<Radio />} label="個別資料" disabled sx={{ color: 'grey' }} />
                        </RadioGroup>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <FormLabel component="legend">引用部分に関する、この本での評価</FormLabel>
                        <RadioGroup
                          row
                          value={infoNoteSiteBook_stance}
                          onChange={(e) => infoNoteSiteBook_setStance(e.target.value as 'positive' | 'neutral' | 'criticism' | 'noselection')}
                        >
                          <FormControlLabel value="positive" control={<Radio />} label="肯定" />
                          <FormControlLabel value="neutral" control={<Radio />} label="中立" />
                          <FormControlLabel value="criticism" control={<Radio />} label="批判" />
                          <FormControlLabel value="noselection" control={<Radio />} label="選択しない" />
                        </RadioGroup>
                      </Box>
                      <Button variant="outlined" onClick={infoNoteSiteBook_handleCancel} sx={{ mr: 2 }}>
                        キャンセル
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={infoNoteSiteBook_handleNoteSubmit}
                        disabled={!infoNoteSiteBook_selectedBookId}
                      >
                        登録
                      </Button>
                    </Box>
                  )}

                  <Button variant="outlined" onClick={() => infoNoteSiteTenkyo_setShowSearch(true)}>
                    この本が紹介している人物・団体を登録する
                  </Button>

                  {infoNoteSiteTenkyo_showSearch && (
                    <Box>
                      <TextField
                        label="人物・団体を検索"
                        fullWidth
                        variant="outlined"
                        value={infoNoteSiteTenkyo_searchQuery}
                        onChange={infoNoteSiteTenkyo_handleSearchQueryChange}
                        onKeyDown={infoNoteSiteTenkyo_handleSearchQueryKeyDown}
                        InputProps={{
                          endAdornment: (
                            <>
                              <IconButton onClick={() => infoNoteSiteTenkyo_setSearchQuery('')}>
                                <CloseIcon />
                              </IconButton>
                              <IconButton onClick={() => infoNoteSiteTenkyo_fetchSearchResults(infoNoteSiteTenkyo_searchQuery)}>
                                <SearchIcon />
                              </IconButton>
                            </>
                          ),
                        }}
                        sx={{ mb: 2 }}
                      />
                      <Box sx={{ maxHeight: '300px', overflow: 'auto', mb: 2 }}>
                        <List>
                          {infoNoteSiteTenkyo_tenkyoSearchResults.map((author) => (
                            <ListItem key={author.tenkyo_id} sx={{ display: 'flex', alignItems: 'center' }}>
                              <Checkbox
                                edge="start"
                                checked={infoNoteSiteTenkyo_selectedTenkyoId === author.tenkyo_id}
                                tabIndex={-1}
                                disableRipple
                                onChange={() => infoNoteSiteTenkyo_handleSelectTenkyo(author.tenkyo_id)}
                              />
                              <ListItemText primary={author.main_name} secondary={author.birth_death} />
                              <ListItemText>
                                <a href={`https://id.ndl.go.jp/auth/ndlna/${author.tenkyo_id}`} target="_blank" rel="noopener noreferrer">
                                  https://id.ndl.go.jp/auth/ndlna/{author.tenkyo_id}
                                </a>
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <TextField
                        label="追加情報"
                        fullWidth
                        variant="outlined"
                        value={infoNoteSiteTenkyo_metaDescription}
                        onChange={(e) => infoNoteSiteTenkyo_setMetaDescription(e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <Box sx={{ mb: 2 }}>
                        <FormLabel component="legend">人物・団体に関する、この本での評価</FormLabel>
                        <RadioGroup
                          row
                          value={infoNoteSiteTenkyo_stance}
                          onChange={(e) => infoNoteSiteTenkyo_setStance(e.target.value as 'positive' | 'neutral' | 'criticism' | 'noselection')}
                        >
                          <FormControlLabel value="positive" control={<Radio />} label="肯定" />
                          <FormControlLabel value="neutral" control={<Radio />} label="中立" />
                          <FormControlLabel value="criticism" control={<Radio />} label="批判" />
                          <FormControlLabel value="noselection" control={<Radio />} label="選択しない" />
                        </RadioGroup>
                      </Box>
                      <Button variant="outlined" onClick={infoNoteSiteTenkyo_handleCancel} sx={{ mr: 2 }}>
                        キャンセル
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={infoNoteSiteTenkyo_handleNoteSubmit}
                        disabled={!infoNoteSiteTenkyo_selectedTenkyoId}
                      >
                        登録
                      </Button>
                    </Box>
                  )}
                </Stack>
              </Box>
            </Collapse>

            <Collapse in={activeNoteType === 'explain'}>
              <Box sx={{ mt: 2 }}>
                <TextField
                  label="説明ノート内容"
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  value={explainNoteContent}
                  onChange={(e) => setExplainNoteContent(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleExplainNoteSubmit}
                  disabled={!explainNoteContent.trim()}
                >
                  説明ノートを投稿
                </Button>
              </Box>
            </Collapse>

            <Collapse in={activeNoteType === 'impression'}>
              <Box sx={{ mt: 2 }}>
                <TextField
                  label="感想ノート内容"
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  value={impressionNoteContent}
                  onChange={(e) => setImpressionNoteContent(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>公開範囲</InputLabel>
                  <Select
                    value={impressionNoteOpenLevel}
                    onChange={(e: SelectChangeEvent) => setImpressionNoteOpenLevel(e.target.value as string)}
                  >
                    <MenuItem value="PUBLIC">公開</MenuItem>
                    <MenuItem value="FRIENDS">限定公開</MenuItem>
                    <MenuItem value="PRIVATE">非公開</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>リプライの許可</InputLabel>
                  <Select
                    value={impressionNoteAllowReplyLevel}
                    onChange={(e: SelectChangeEvent) => setImpressionNoteAllowReplyLevel(e.target.value as string)}
                  >
                    <MenuItem value="PUBLIC">全員</MenuItem>
                    <MenuItem value="FRIENDS">限定</MenuItem>
                    <MenuItem value="PRIVATE">自分のみ</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleImpressionNoteSubmit}
                  disabled={!impressionNoteContent.trim()}
                >
                  感想ノートを投稿
                </Button>
              </Box>
            </Collapse>
          </Box>

          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h5" component="h2" gutterBottom>
                関連書籍
              </Typography>
              <IconButton onClick={toggleRelatedBooks}>
                {isRelatedBooksExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
            <Collapse in={isRelatedBooksExpanded}>
              {Object.keys(groupedBooks).map(authorId => (
                <Box key={authorId} sx={{ mb: 4 }}>
                  <Button
                    href={`/author/${authorId}`}
                    sx={{
                      textDecoration: 'none',
                      color: 'inherit',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      p: 0,
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      {groupedBooks[authorId].authorName ? groupedBooks[authorId].authorName.replace(/,/g, '') : ''}
                    </Typography>
                    {wikipediaLinks[authorId] && (
                      <Button
                        href={wikipediaLinks[authorId]!}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ p: 0, minWidth: 'auto', ml: 1 }}
                      >
                        <img src={wikipediaLogo} alt="Wikipedia" style={{ width: 'auto', height: '24px' }} />
                      </Button>
                    )}
                    <Button
                      href={`https://www.google.com/search?q=${encodeURIComponent(groupedBooks[authorId].authorName.replace(/,/g, ''))}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ p: 0, minWidth: 'auto', ml: 1 }}
                    >
                      <img src={googleSearchIcon} alt="Google Search" style={{ width: 'auto', height: '24px' }} />
                    </Button>
                  </Button>
                  <Grid container spacing={2}>
                    {groupedBooks[authorId].books.map(book => (
                      <Grid item xs={6} sm={4} md={3} key={book.GlobalBookID}>
                        <Box>
                          <Card
                            onClick={() => {
                              navigate(`/manifestation/${book.KANRI_ID}`);
                              window.scrollTo(0, 0);
                            }}
                            sx={{
                              transition: '0.3s',
                              boxShadow: 1,
                              '&:hover': {
                                boxShadow: 4,
                              },
                              overflow: 'hidden',
                              borderRadius: '8px',
                              height: 'auto',
                              cursor: 'pointer',
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={generateCoverUrl(book)}
                              alt={`Cover for ${book.MainTitle}`}
                              sx={{ objectFit: 'contain', height: '200px', width: '100%' ,padding: '10px'}}
                            />
                          </Card>
                          <Box sx={{ mt: 1, textAlign: 'left' }}>
                            <Typography variant="body2" color="textPrimary" component="p">
                              {book.MainTitle}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              {book.PublishingDate}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </Collapse>
          </Box>
          <Box>
            <Typography variant="h5" component="h2" gutterBottom>
              関連ノート
            </Typography>
            {notes.map(note => (
              <React.Fragment key={note.noteId}>
                {renderNoteContent(note)}
              </React.Fragment>
            ))}
          </Box>
        </>
      )}
      <Dialog open={openCreateListDialog} onClose={handleCloseCreateListDialog}>
        <DialogTitle>新しいリストを作成</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="リスト名"
            type="text"
            fullWidth
            variant="outlined"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="open-level-label">公開範囲</InputLabel>
            <Select
              labelId="open-level-label"
              id="open-level"
              value={newListOpenLevel}
              label="公開範囲"
              onChange={(e) => setNewListOpenLevel(e.target.value)}
            >
              <MenuItem value="PUBLIC">公開</MenuItem>
              <MenuItem value="URLSHARE">シェア</MenuItem>
              <MenuItem value="PRIVATE">非公開</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateListDialog} disabled={isCreatingList}>キャンセル</Button>
          <Button onClick={handleCreateList} disabled={isCreatingList || !newListName.trim()}>
            {isCreatingList ? '作成中...' : '作成'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Container>
  );
}

export default ManifestationDetailPage;