export const parsePublishingDate = (publishingDate: string): Date | null => {
    const eraPatterns: { [key: string]: number } = {
      '明治': 1868,
      '大正': 1912,
      '昭和': 1926,
      '平成': 1989,
      '令和': 2019,
      '明': 1868,
      '大': 1912,
      '昭': 1926,
      '平': 1989,
      '令': 2019,
    };

    const regexPatterns = [
      /^(\d{4})$/, // 年
      /^(\d{4})\.(\d{1,2})$/, // 年.月
      /^(\d{4})\.(\d{1,2})\.(\d{1,2})$/, // 年.月.日
      /^(\d{4})年(\d{1,2})月$/, // 年年.月月
      /^([明治大正昭和平成令和明大昭平令])(\d{1,2})年$/, // 年号
      /^([明治大正昭和平成令和明大昭平令])(\d{1,2})年(\d{1,2})月$/, // 年号年.月
      /^([明治大正昭和平成令和明大昭平令])(\d{1,2})年(\d{1,2})月(\d{1,2})日$/, // 年号年.月.日
    ];

    for (let pattern of regexPatterns) {
      const match = publishingDate.match(pattern);
      if (match) {
        if (pattern === regexPatterns[0]) {
          return new Date(Number(match[1]), 0, 1);
        } else if (pattern === regexPatterns[1]) {
          return new Date(Number(match[1]), Number(match[2]) - 1, 1);
        } else if (pattern === regexPatterns[2]) {
          return new Date(Number(match[1]), Number(match[2]) - 1, Number(match[3]));
        } else if (pattern === regexPatterns[3]) {
          return new Date(Number(match[1]), Number(match[2]) - 1, 1);
        } else if (pattern === regexPatterns[4]) {
          const eraYear = eraPatterns[match[1]]; // インデックス型キャスト
          return new Date(eraYear + Number(match[2]) - 1, 0, 1);
        } else if (pattern === regexPatterns[5]) {
          const eraYear = eraPatterns[match[1]]; // インデックス型キャスト
          return new Date(eraYear + Number(match[2]) - 1, Number(match[3]) - 1, 1);
        } else if (pattern === regexPatterns[6]) {
          const eraYear = eraPatterns[match[1]]; // インデックス型キャスト
          return new Date(eraYear + Number(match[2]) - 1, Number(match[3]) - 1, Number(match[4]));
        }
      }
    }
    return null;
  };