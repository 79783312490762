// src/pages/ServiceContactPage.tsx

import React from 'react';

const ServiceContact: React.FC = () => {
  return (
    <div>
      <h1>Welcome to ReadLinker</h1>
    </div>
  );
}

export default ServiceContact;
