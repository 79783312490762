import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Button, Typography } from '@mui/material';
import { UserDetail } from '../types/types';

interface UserListProps {
  users: UserDetail[];
  onLoadMore: () => void;
  hasMore: boolean;
}

const UserList: React.FC<UserListProps> = ({ users, onLoadMore, hasMore }) => {
  return (
    <>
      <List>
        {users.map((user) => (
          <ListItem key={user.userSub} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={user.preferred_name} src={user.profileImage} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Link to={`/${user.username}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {user.preferred_name}
                </Link>
              }
              secondary={
                <>
                  <Typography component="span" variant="body2" color="text.primary">
                    @{user.username}
                  </Typography>
                  {" — "}{user.profileText}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
      {hasMore && (
        <Button onClick={onLoadMore} fullWidth>
          さらに読み込む
        </Button>
      )}
    </>
  );
};

export default UserList;