// src/utils/getTimeline.ts

import axios from 'axios';
import { Note } from '../types/types';

const API_URL = 'https://4u2j1o1jed.execute-api.us-east-1.amazonaws.com/default/readlinker-timeline-handler';

export const getTimeline = async (userSub: string, idToken?: string): Promise<Note[] | null> => {
    console.log('getTimeline called for userSub:', userSub);

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }

    try {
        const response = await axios.get<Note[]>(API_URL, {
            headers,
            params: {
                userSub,
            },
        });

        console.log('API response:', response.data);
        return response.data;  // レスポンスのボディはNoteの配列として直接返す
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Failed to fetch timeline:', error.response?.data || error.message);
        } else {
            console.error('Failed to fetch timeline:', error);
        }
        return null;
    }
};
