// utils/getUserSubFromUsername.ts

export const getUserSubFromUsername = async (username: string, idToken: string): Promise<string | null> => {
  try {
    const response = await fetch(`https://p6718tdt10.execute-api.us-east-1.amazonaws.com/default/bublio-cognito-auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify({
        Operation: 'getUserSubByUsername',
        username: username
      })
    });
    const data = await response.json();
    return data.userSub || null;
  } catch (error) {
    console.error('Error getting userSub from username:', error);
    return null;
  }
};



export const getUserSubFromUsernameOpenEndpoint = async (username: string): Promise<string | null> => {
    try {
      const response = await fetch(`https://cp04osziul.execute-api.us-east-1.amazonaws.com/default/readlinker-userprofile-open`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Operation: 'getUserSubByUsername',
          username: username
        })
      });
      const data = await response.json();
      return data.userSub || null;
    } catch (error) {
      console.error('Error getting userSub from username:', error);
      return null;
    }
  };