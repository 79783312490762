
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  getUserSubFromUsernameCF,
  getUserProfileImageByUsernameCF,
  getUserProfileTextByUsernameCF,
  getUserPreferredNameByUsernameCF
} from '../utils/userProfileApi';
import { getIdToken } from '../utils/getIdToken';
import { getUserSub } from '../utils/getUserSub';
import {
  addFollower,
  deleteFollower,
  getUserFollowUsers,
  getUserFollowerUsers,
  checkBlockStatus,
  blockUser,
  unblockUser,
  sendFriendRequest,
  handleFriendRequest,
  checkFriendRequestStatus,
  deleteFriend,
  checkFriendStatus
} from '../utils/userFriendsHandle';
import { getFilteredUserNotes } from '../utils/getFilteredUserNotes';
import { renderNoteContent, loadTwitterScript } from '../components/renderNotes';
import { get_follow_friend_info } from '../utils/followerFriendsInfo';
import { Note, FollowFriendInfo, UserDetail } from '../types/types';
import {
  Container,
  Typography,
  Box,
  Avatar,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from '@mui/material';

import { getUserInfoByUsersubCF, UserData } from '../utils/userProfileApi';
import { formatToJST } from '../utils/formatDate';

const UserPage: React.FC = () => {
  const { userNameOnURL } = useParams<{ userNameOnURL: string }>();
  const [idToken, setIdToken] = useState<string | null>(null);
  const [userProfileImage, setUserProfileImage] = useState<string | null>(null);
  const [userProfileText, setUserProfileText] = useState<string | null>(null);
  const [preferredName, setPreferredName] = useState<string | null>(null);
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [isFollowedBy, setIsFollowedBy] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isBlockedBy, setIsBlockedBy] = useState<boolean>(false);
  const [openUnfollowDialog, setOpenUnfollowDialog] = useState<boolean>(false);
  const [userSub, setUserSub] = useState<string | null>(null);
  const [targetUserSub, setTargetUserSub] = useState<string | null>(null);
  const [notes, setNotes] = useState<Note[] | null>(null);
  const [accountNotFound, setAccountNotFound] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFriend, setIsFriend] = useState<boolean>(false);
  const [friendRequestStatus, setFriendRequestStatus] = useState<'none' | 'pending'>('none');
  const [receivedRequestId, setReceivedRequestId] = useState<string | null>(null);
  const [followFriendsNum, setFollowFriendsNum] = useState<FollowFriendInfo>({
    following_sum: 0,
    follower_sum: 0,
    friends_sum: 0
  });

  const fetchUserProfile = useCallback(async (userName: string) => {
    try {
      const [imageResponse, textResponse, nameResponse] = await Promise.all([
        getUserProfileImageByUsernameCF(userName),
        getUserProfileTextByUsernameCF(userName),
        getUserPreferredNameByUsernameCF(userName)
      ]);

      setUserProfileImage(imageResponse || null);
      setUserProfileText(textResponse || null);
      setPreferredName(nameResponse || null);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }, []);

  const fetchUserNotes = useCallback(async (targetSub: string, token: string | undefined) => {
    try {
      const fetchedNotes = await getFilteredUserNotes(targetSub, token);
      const sortedNotes = fetchedNotes?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) || null;
      setNotes(sortedNotes);
    } catch (error) {
      console.error('Error fetching user notes:', error);
    }
  }, []);

  const checkUserRelations = useCallback(async (sub: string | null, targetSub: string, token: string) => {
    if (sub && sub !== targetSub) {
      try {
        const [blockedByTarget, following, followedBy, friendStatus] = await Promise.all([
          checkBlockStatus(targetSub, sub, token),
          getUserFollowUsers(sub, token),
          getUserFollowerUsers(sub, token),
          checkFriendStatus(sub, targetSub, token)
        ]);

        setIsBlockedBy(blockedByTarget);
        setIsFollowing(following?.some(user => user.followedUserSub === targetSub) || false);
        setIsFollowedBy(followedBy?.some(user => user.followerUserSub === targetSub) || false);
        setIsFriend(friendStatus);

        if (!friendStatus) {
          const [friendStatusSent, friendStatusReceived] = await Promise.all([
            checkFriendRequestStatus(sub, targetSub, token),
            checkFriendRequestStatus(targetSub, sub, token)
          ]);
          
          if (friendStatusSent) {
            setFriendRequestStatus('pending');
          } else if (friendStatusReceived) {
            setFriendRequestStatus('pending');
            setReceivedRequestId(friendStatusReceived.requestId);
          } else {
            setFriendRequestStatus('none');
          }
        }
      } catch (error) {
        console.error('Error checking user relations:', error);
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = getIdToken();
        setIdToken(token);
        const sub = await getUserSub();
        setUserSub(sub);

        if (userNameOnURL) {
          const targetSub = await getUserSubFromUsernameCF(userNameOnURL);

          if (targetSub) {
            setTargetUserSub(targetSub);
            await Promise.all([
              fetchUserProfile(userNameOnURL),
              fetchUserNotes(targetSub, token || undefined),
              checkUserRelations(sub, targetSub, token!),

              // loadTwitterScript()

            ]);
            const followFriendsInfo = await get_follow_friend_info(targetSub, token || undefined);
            if (followFriendsInfo) {
              setFollowFriendsNum(followFriendsInfo);
            }
          } else {
            setAccountNotFound(true);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setAccountNotFound(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userNameOnURL, fetchUserProfile, fetchUserNotes, checkUserRelations]);

  // useEffect(() => {
  //   loadTwitterScript();
  //   console.log(notes);
  // }, [notes]);

  const handleFollowClick = async () => {
    if (isFollowing) {
      setOpenUnfollowDialog(true);
    } else {
      try {
        await addFollower(userSub!, targetUserSub!, idToken!);
        setIsFollowing(true);
        setFollowFriendsNum((prev: FollowFriendInfo) => ({ ...prev, follower_sum: prev.follower_sum + 1 }));
      } catch (error) {
        console.error('Error adding follow:', error);
      }
    }
  };

  const handleUnfollowConfirm = async () => {
    try {
      await deleteFollower(userSub!, targetUserSub!, idToken!);
      setIsFollowing(false);
      setOpenUnfollowDialog(false);
      setFollowFriendsNum((prev: FollowFriendInfo) => ({ ...prev, follower_sum: prev.follower_sum - 1 }));
    } catch (error) {
      console.error('Error deleting follow:', error);
    }
  };

  const handleBlockClick = async () => {
    try {
      if (isBlocked) {
        await unblockUser(userSub!, targetUserSub!, idToken!);
        setIsBlocked(false);
      } else {
        await blockUser(userSub!, targetUserSub!, idToken!);
        setIsBlocked(true);
      }
    } catch (error) {
      console.error('Error blocking/unblocking user:', error);
    }
  };

  const handleSendFriendRequest = async () => {
    try {
      await sendFriendRequest(userSub!, targetUserSub!, idToken!);
      setFriendRequestStatus('pending');
    } catch (error) {
      console.error('Error sending friend request:', error);
    }
  };

  const handleAcceptFriendRequest = async (requestId: string) => {
    try {
      await handleFriendRequest(requestId, 'accept', idToken!);
      setFriendRequestStatus('none');
      setIsFriend(true);
      setFollowFriendsNum((prev: FollowFriendInfo) => ({ ...prev, friends_sum: prev.friends_sum + 1 }));
    } catch (error) {
      console.error('Error accepting friend request:', error);
    }
  };

  const handleRejectFriendRequest = async (requestId: string) => {
    try {
      await handleFriendRequest(requestId, 'reject', idToken!);
      setFriendRequestStatus('none');
    } catch (error) {
      console.error('Error rejecting friend request:', error);
    }
  };

  const handleDeleteFriend = async () => {
    try {
      await deleteFriend(userSub!, targetUserSub!, idToken!);
      setFriendRequestStatus('none');
      setIsFriend(false);
      setFollowFriendsNum((prev: FollowFriendInfo) => ({ ...prev, friends_sum: prev.friends_sum - 1 }));
    } catch (error) {
      console.error('Error deleting friend:', error);
    }
  };

  const [loadingUserInfo, setLoadingUserInfo] = useState(false);
  const [userInfos, setUserInfos] = useState<Record<string, UserData>>({});

  const fetchUserInfos = useCallback(async () => {
    if (notes) {
      setLoadingUserInfo(true);
      const uniqueUserSubs = Array.from(new Set(notes.map(note => note.userSub)));

      
      const userInfoPromises = uniqueUserSubs.map(async (userSub) => {
        const userInfo = await getUserInfoByUsersubCF(userSub);
        return { userSub, userInfo };
      });

      const userInfosArray = await Promise.all(userInfoPromises);

      const userInfosObject = userInfosArray.reduce((acc, item) => {
        if (item && item.userInfo) {
          acc[item.userSub] = item.userInfo;
        }
        return acc;
      }, {} as Record<string, UserData>);

      setUserInfos(userInfosObject);
      setLoadingUserInfo(false);
    }
  }, [notes]);


  useEffect(() => {
    if (notes) {
      fetchUserInfos();
      loadTwitterScript();
    }
  }, [notes, fetchUserInfos]);

  const navigate = useNavigate();

  return (
    <Container>
      {loading ? (
        <CircularProgress />
      ) : accountNotFound ? (
        <Typography variant="h4" component="div">
          アカウントが見つかりませんでした。
        </Typography>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={4}>
          <Avatar
            alt="Profile Image"
            src={userProfileImage || 'default-profile-image-url'}
            sx={{ width: 120, height: 120, mb: 2 }}
          />
          <Typography variant="h4" component="div" gutterBottom>
            {preferredName}
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Typography variant="body1" color="textSecondary" fontSize={20} sx={{ mr: 2 }}>
              @{userNameOnURL}
            </Typography>
            <Button
              component={Link}
              to={`/bookshelf/${userNameOnURL}`}
              variant="contained"
              color="primary"
              sx={{
                textDecoration: 'none',
                backgroundColor: 'white',
                color: 'black',
                p: 1,
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                  textDecoration: 'underline',
                },
                ml: 2, // 追加: @ユーザー名との間隔を広げる
              }}
            >
              本棚
            </Button>
          </Box>
          <Box display="flex" alignItems="center" mt={1} justifyContent="center" width="100%">
            <Button
              component={Link}
              to={`/${userNameOnURL}/following`}
              sx={{
                textDecoration: 'none',
                color: 'inherit',
                p: 0,
                mx: 1, // スペースの距離を調整
                minWidth: 'auto',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Typography component="div" variant="body1">
                {followFriendsNum.following_sum} フォロー中
              </Typography>
            </Button>
            <Button
              component={Link}
              to={`/${userNameOnURL}/follower`}
              sx={{
                textDecoration: 'none',
                color: 'inherit',
                p: 0,
                mx: 1, // スペースの距離を調整
                minWidth: 'auto',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Typography component="div" variant="body1">
                {followFriendsNum.follower_sum} フォロワー
              </Typography>
            </Button>
            <Button
              component={Link}
              to={`/${userNameOnURL}/friendsList`}
              sx={{
                textDecoration: 'none',
                color: 'inherit',
                p: 0,
                mx: 1, // スペースの距離を調整
                minWidth: 'auto',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Typography component="div" variant="body1">
                {followFriendsNum.friends_sum} 友達
              </Typography>
            </Button>
            {userSub === targetUserSub && (
              <Button
                component={Link}
                to="/profile"
                sx={{
                  textDecoration: 'none',
                  color: 'inherit',
                  p: 0,
                  minWidth: 'auto',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                <Typography component="div" variant="body1">
                  プロフィール編集
                </Typography>
              </Button>
            )}
          </Box>
          <Typography variant="body1" color="textSecondary" align="center" sx={{ mt: 2 }}>
            {userProfileText || ''}
          </Typography>
          {isFollowedBy && (
            <Typography variant="body2" color="textPrimary" align="center" sx={{ mt: 1 }}>
              このユーザーはあなたをフォローしています。
            </Typography>
          )}
          {userSub && targetUserSub && userSub !== targetUserSub && (
            <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
              {isFriend ? (
                <Button variant="contained" color="primary" onClick={handleDeleteFriend}>
                  友達
                </Button>
              ) : friendRequestStatus === 'pending' && receivedRequestId ? (
                <>
                  <Button variant="contained" color="success" onClick={() => handleAcceptFriendRequest(receivedRequestId)}>
                    友達リクエストを承認
                  </Button>
                  <Button variant="contained" color="error" onClick={() => handleRejectFriendRequest(receivedRequestId)}>
                    友達リクエストを拒否
                  </Button>
                </>
              ) : friendRequestStatus === 'pending' ? (
                <Button variant="contained" color="warning" disabled>
                  友達リクエスト送信済み
                </Button>
              ) : (
                <Button variant="outlined" onClick={handleSendFriendRequest}>
                  友達リクエストを送信
                </Button>
              )}
              <Button
                variant={isFollowing ? "contained" : "outlined"}
                color="primary"
                onClick={handleFollowClick}
              >
                {isFollowing ? "フォロー中" : "フォロー"}
              </Button>
              <Button
                variant={isBlocked ? "contained" : "outlined"}
                color="secondary"
                onClick={handleBlockClick}
              >
                {isBlocked ? "ブロック解除" : "ブロック"}
              </Button>
            </Box>
          )}
          <Box sx={{ mt: 4, width: '100%' }}>
            {/* <Typography variant="h6" component="div" gutterBottom>
              ノート一覧
            </Typography> */}
            {isBlockedBy ? (
              <Typography variant="body1" color="error" align="center" sx={{ mt: 2 }}>
                このユーザーにブロックされているため、ノートは表示されません。
              </Typography>
            ) : notes && notes.length > 0 ? (
              // notes.map(note => (
              //   <Paper key={note.noteId} elevation={2} sx={{ p: 2, mb: 2 }}>
              //     {renderNoteContent(note)}
              //   </Paper>
              // )
              notes.map((note) => (
                <Paper 
                  key={note.noteId} 
                  elevation={2} 
                  sx={{ padding: 2, marginBottom: 2, cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`/note/${note.noteId}`, '_blank');
                  }}
                >
                  {userInfos[note.userSub] ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                      <Avatar
                        src={userInfos[note.userSub].profileImage}
                        alt="Profile Image"
                        sx={{ cursor: 'pointer', marginRight: 2, width: 40, height: 40 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/${userInfos[note.userSub]?.username}`);
                        }}
                      />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="subtitle1">{userInfos[note.userSub].preferred_name}</Typography>
                        <Typography variant="body2" sx={{ color: 'grey' }}>
                          {formatToJST(note.createdAt)}
                        </Typography>
                        {/* <Typography variant="body2" sx={{ color: 'grey' }}>
                          更新日時: {note.editedAt ? formatToJST(note.editedAt) : '更新なし'}
                        </Typography> */}
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                      <CircularProgress size={20} />
                      <Typography variant="body2" sx={{ marginLeft: 1 }}>ユーザー情報を読み込み中...</Typography>
                    </Box>
                  )}

                  <Typography variant="body1" component="div">
                    {renderNoteContent(note)}
                  </Typography>
                </Paper>
              )
              )
            ) : (
              <Typography variant="body2" color="textSecondary">
                ノートはありません。
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <Dialog open={openUnfollowDialog} onClose={() => setOpenUnfollowDialog(false)}>
        <DialogTitle>フォローを解除しますか？</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {userNameOnURL}のフォローを解除しようとしています。これを実行すると、このアカウントの投稿がタイムラインに表示されなくなります。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUnfollowDialog(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleUnfollowConfirm} color="secondary">
            フォローを解除
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
export default UserPage;