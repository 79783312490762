// Footer.tsx
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{ py: 3, textAlign: 'center', bgcolor: 'background.paper' }}>
      <Typography variant="body2" color="textSecondary">
        © 2023-2024{' '}
        <Link
          href="https://ada-llc.jp"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
          underline="none"
        >
          ADA LLC.
        </Link>{' '}
        <br />
        <small>
          <Link
            href="https://docs.google.com/forms/d/e/1FAIpQLSeRZG3EhuPw0q9hDin80z5UzfswGozcq2HqFsXslh1co6IFHg/viewform?usp=sf_link"
            target="_blank"
            rel="noopener noreferrer"
            color="grey"
            underline="none"
          >
            お問い合わせ
          </Link>
        </small>
      </Typography>
    </Box>
  );
}

export default Footer;
