// fetch_lending_history_bookdetail_hondana_by_GlobalBookID

import axios from 'axios';

const API_URL = 'https://53wglutnub.execute-api.us-east-1.amazonaws.com/default/v1';

export const fetch_lending_history_bookdetail_hondana_by_GlobalBookID = async (globalBookID: string, userSub?: string) => {
    console.log('fetch_lending_history_bookdetail_hondana_by_GlobalBookID: globalBookID:', globalBookID);
    
    const data: any = {
        Operation: 'fetch_lending_history_bookdetail_hondana_by_GlobalBookID',
        GlobalBookID: globalBookID,
    };

    if (userSub) {
        data.userSub = userSub;
    }

    const headers = {
        'Content-Type': 'application/json',
    };

    try {
        const response = await axios.post(API_URL, data, { headers });
        console.log('fetch_lending_history_bookdetail_hondana_by_GlobalBookID response:');;
        return response.data;
    } catch (error) {
        console.error('Failed to fetch data:', error);
        return null;
    }
};
